/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Pressable } from 'react-native';
import { CustomDrawer } from '../components/Drawer';

import { appTitulo, navigationTheme, paperTheme } from '../lib/theme';
import { ComunidadePostScreen } from '../screens/ComunidadesScreen/ComunidadePostScreen';
import { ComunidadeScreen } from '../screens/ComunidadesScreen/ComunidadeScreen';
import { ComunidadesLista } from '../screens/ComunidadesScreen/ComunidadesLista';
import { CredLoversAvaliar } from '../screens/CredLovers/CredLoversAvaliar';
import { CriarContaScreen } from '../screens/CriarConta/CriarContaScreen';
import { CriarContaSucessoScreen } from '../screens/CriarConta/CriarContaSucessoScreen';
import { HomeScreen } from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';
import { MessengerChatScreen } from '../screens/MessengerScreens/MessengerChatScreen';
import { MessengerListaDeContatos } from '../screens/MessengerScreens/MessengerListaDeContatos';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import { PesquisarUsuariosScreen } from '../screens/PesquisarUsuarios/PesquisarUsuariosScreen';
import { PostScreen } from '../screens/PostScreen';
import { ProfileScreen } from '../screens/ProfileScreen';
import { EditarPerfilScreen } from '../screens/ProfileScreen/EditarPerfilScreen';
import { QuemAvaliouScreen } from '../screens/QuemAvaliouScreen/QuemAvaliouScreen';
import { AxiosProvider } from '../stores/AxiosContext';
import { useUserStore } from '../stores/userStore';
import LinkingConfiguration from './LinkingConfiguration';
import { DrawerParamList, DrawerStackParamList, RootStackParamList } from './navigationTypes';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={navigationTheme}
        >
            <AxiosProvider>
                <RootNavigator />
            </AxiosProvider>
        </NavigationContainer>
    );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
    const token = useUserStore(state => state.token);

    const estaLogado = (token != null);

    return (
        <Stack.Navigator
            initialRouteName='LoginScreen'
            screenOptions={{
                headerShown: false,
                statusBarTranslucent: false,
                statusBarColor: paperTheme.colors.darkBackground,
            }}
        >
            {(estaLogado) && (
                <Stack.Screen name="Root" component={MyDrawer} />
            )}

            <Stack.Screen
                name="LoginScreen"
                component={LoginScreen}
                options={{
                    title: `Login - ${appTitulo}`,
                }}
            />

            <Stack.Screen
                name="CriarContaScreen"
                component={CriarContaScreen}
                options={{
                    title: `Criar conta - ${appTitulo}`,
                }}
            />

            <Stack.Screen
                name="CriarContaSucessoScreen"
                component={CriarContaSucessoScreen}
                options={{
                    title: `Criar conta - ${appTitulo}`,
                }}
            />

            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
            <Stack.Group screenOptions={{ presentation: 'modal' }}>
                <Stack.Screen name="Modal" component={ModalScreen} />
            </Stack.Group>
        </Stack.Navigator>
    );
}

const Drawer = createDrawerNavigator<DrawerParamList>();

function MyDrawer() {
    return (
        <Drawer.Navigator
            screenOptions={{ headerShown: false }}
            drawerContent={CustomDrawer}
        >
            <Drawer.Screen
                name="DrawerStackScreen"
                component={DrawerStackNavigator}
            />
        </Drawer.Navigator>
    );
}

const DrawerStack = createNativeStackNavigator<DrawerStackParamList>();

function DrawerStackNavigator() {
    return (
        <DrawerStack.Navigator screenOptions={{ headerShown: false }}>
            <DrawerStack.Screen
                //@ts-ignore 
                name="HomeScreen"
                component={HomeScreen}
                options={{
                    title: `${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                //@ts-ignore 
                name="MyProfileScreen"
                component={ProfileScreen}
                options={{
                    title: `Perfil - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                //@ts-ignore 
                name="ProfileScreen"
                component={ProfileScreen}
                options={{
                    title: `Perfil - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                name="ComunidadesLista"
                component={ComunidadesLista}
                options={{
                    title: `Comunidades - ${appTitulo}`
                }}
            />

            <DrawerStack.Screen
                name="ComunidadeScreen"
                component={ComunidadeScreen}
                options={{
                    title: `Comunidade - ${appTitulo}`
                }}
            />

            <DrawerStack.Screen
                name="ComunidadePostScreen"
                component={ComunidadePostScreen}
                options={{
                    title: `Comunidade - ${appTitulo}`
                }}
            />

            <DrawerStack.Screen
                name="CredLoversAvaliar"
                component={CredLoversAvaliar}
                options={{
                    title: `Connections - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                name="PostScreen"
                component={PostScreen}
                options={{
                    title: `Post - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                name="EditarPerfilScreen"
                component={EditarPerfilScreen}
                options={{
                    title: `Editar perfil - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                name="MessengerListaDeContatos"
                component={MessengerListaDeContatos}
                options={{
                    title: `Messenger - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                name="MessengerChat"
                component={MessengerChatScreen}
                options={{
                    title: `Messenger - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                name="QuemAvaliouScreen"
                component={QuemAvaliouScreen}
                options={{
                    title: `Quem me avaliou? - ${appTitulo}`,
                }}
            />

            <DrawerStack.Screen
                name="PesquisarUsuariosScreen"
                component={PesquisarUsuariosScreen}
                options={{
                    title: `Pesquisar usuários - ${appTitulo}`,
                }}
            />
        </DrawerStack.Navigator>
    );
}