import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity,  } from "react-native";
import { Text, Provider as PaperProvider, Divider, ActivityIndicator } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";
import uuid from 'react-native-uuid';
import { NativeStackScreenProps } from "@react-navigation/native-stack";
//@ts-ignore
import { RefreshControl } from 'react-native-web-refresh-control'
import { Avatar } from "../components/Avatar";
import { NavBar, navbarStyles } from "../components/NavBar";
import { PostCard } from "../components/Post";
import { themeStyles, paperTheme } from "../lib/theme";
import { MenuRodape } from "../components/MenuRodape";
import useKeyboard from "../hooks/useKeyboard";
import { PostTextArea } from "../components/PostTextArea";
import { getAllPosts, Post, publicarPost } from "../lib/services/posts";
import { AxiosContext } from "../stores/AxiosContext";
import { useUserStore } from "../stores/userStore";
import { getUserInfos } from "../lib/services/user";
import { DrawerStackParamList } from "../navigation/navigationTypes";
import { UsuarioInfos } from "../components/UsuarioInfos";
import { CompletarCadastroModal } from "../components/CompletarCadastroModal";

type Props = NativeStackScreenProps<DrawerStackParamList, 'HomeScreen'>;

export function HomeScreen({ navigation }: Props) {
    const [posts, setPosts] = useState<Post[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [mostrarCadastroModal, setMostrarCadastroModal] = useState(false);

    const userStore = useUserStore();
    const isKeyboardVisible = useKeyboard();
    const { authAxios } = useContext(AxiosContext);

    const fetchPosts = async () => {
        setIsLoading(true);
        try {
            const postData = await getAllPosts(authAxios);
            if (postData.Status !== true) {
                console.log('status', postData.Status);
                return;
            }
            setPosts(postData.Posts)
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const openMyProfile = () => {
        navigation.navigate('MyProfileScreen', {});
    };

    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
        try {
            await fetchPosts();
        } catch (err) {
            console.error(err);
        } finally {
            setRefreshing(false);
        }
    }, []);

    const adicionarPost = async (texto: string) => {
        setIsLoading(true);
        try {
            setPosts([]);
            const data = await publicarPost(authAxios, {
                comunidade: 1,
                post: texto.trim(),
            });
            if (data.Status !== true) {
                console.error('Erro ao publicar post', data.Mensagem);
                return;
            }

            const postData = await getAllPosts(authAxios);
            if (postData.Status !== true) {
                console.log('status', postData.Status);
                return;
            }
            setPosts(postData.Posts);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserData = async () => {
        try {
            const infos = await getUserInfos(authAxios);
            userStore.update({ infos });

            const temDescricao = infos.descricao != null;
            const temSuperPoder = infos.superPoder != null;
            const temProcura = infos.procura;
            if (!temDescricao && !temSuperPoder && !temProcura) {
                // mostrar modal pedindo para pessoa completar o cadastro
                setMostrarCadastroModal(true);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const openPostComments = (post: Post) => {
        navigation.navigate('PostScreen', {
            postId: post.id,
        });
    };

    useEffect(() => {
        fetchPosts();
        fetchUserData();
    }, []);

    return (
        <SafeAreaView style={themeStyles.background}>
            <NavBar />

            <View style={themeStyles.contentContainer}>
                <FlashList
                    data={posts}
                    estimatedItemSize={250}
                    contentContainerStyle={styles.container}
                    keyboardShouldPersistTaps='handled'
                    keyExtractor={(item) => item.id.toString()}
                    ListHeaderComponent={(
                        <View style={{ alignItems: 'center', paddingTop: 10 }}>
                            {(userStore.infos != null) && (
                                <UsuarioInfos 
                                    userInfos={userStore.infos}
                                    onAvatarPress={openMyProfile}
                                />
                            )}
                            
                            <PostTextArea
                                nome="Leonardo"
                                onSubmit={adicionarPost}
                            />
                        </View>
                    )}
                    renderItem={({ item }) => (
                        <View style={styles.postWrapper}>
                            <PostCard 
                                post={item} 
                                onCommentPress={() => openPostComments(item)}
                            />
                        </View>
                    )}
                    ItemSeparatorComponent={Divider}
                    ListEmptyComponent={(isLoading) ? (
                        <View style={{ alignItems: 'center' }}>
                            <ActivityIndicator
                                size={50}
                                color={paperTheme.colors.activityIndicator}
                            />
                        </View>
                    ) : (
                        <View style={{ alignItems: 'center' }}>
                            <Text style={styles.emptyListText}>
                                Nenhum post encontrado
                            </Text>
                        </View>
                    )}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                />
            </View>

            {(!isKeyboardVisible) && (
                <MenuRodape esconderBotaoHome />
            )}

            <CompletarCadastroModal 
                visible={mostrarCadastroModal}
                onDismiss={() => setMostrarCadastroModal(false)}
                onSubmit={() => navigation.navigate('EditarPerfilScreen')}
            />
        </SafeAreaView>
    );
}

const avatarSize = 80;
const infoSvgSize = 15;

const styles = StyleSheet.create({
    container: {
        paddingBottom: 100,
    },

    postWrapper: {
        alignItems: 'center',
        // padding: 20,
    },

    emptyListText: {
        fontSize: 22,
    },

    avatar: {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,

        marginRight: 10,
        marginLeft: 5,
    },

    header: {
        //borderBottomColor: paperTheme.colors.primary,
        //borderBottomWidth: 1,
        paddingBottom: 15,
        maxWidth: 400,
        width: '100%',
    },

    headerWrapper: {
        alignItems: 'center',
        width: '100%',
        paddingTop: 10,
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginTop: 15,
    },

    headerLinha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    usuarioNome: {
        fontSize: 22,
        fontWeight: 'bold',

        margin: 0,
        marginRight: 5,
    },

    userInfoLinha: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    userInfoTexto: {
        fontSize: 16,
        margin: 0,
        marginHorizontal: 5,
    },

    userInfoCargo: {
        color: '#868686',
        fontSize: 17,
        fontWeight: 'bold',
        marginLeft: 0,
    },

    usuarioNomeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        marginBottom: 5,
    },
});