import { DrawerScreenProps } from "@react-navigation/drawer";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Pressable, RefreshControl, StyleSheet, useWindowDimensions, View } from "react-native";
import { ActivityIndicator, Button, Divider, IconButton, Snackbar, Text, TouchableRipple } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";
import { LinearGradient } from "expo-linear-gradient";
import { MenuRodape } from "../../components/MenuRodape";
import { NavBar, navbarStyles } from "../../components/NavBar";
import { paperTheme, themeStyles } from "../../lib/theme";
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { PostCard } from "../../components/Post";
import { PostTextArea } from "../../components/PostTextArea";
import useKeyboard from "../../hooks/useKeyboard";
import { RatingModal, Review } from "../../components/RatingModal";
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { getAllPosts, Post, publicarPost } from "../../lib/services/posts";
import { AxiosContext } from "../../stores/AxiosContext";
import { useUserStore } from "../../stores/userStore";
import { UsuarioInfos } from "../../components/UsuarioInfos";
import { LoadingScreen } from "../../components/LoadingScreen";
import { AvaliacaoUsuario, avaliarUsuario, buscarAvaliacaoDeUsuario, getUserInfos, UserInfos } from "../../lib/services/user";
import { showAlert } from "../../lib/utils/platform";
import { Avaliacao } from "../../components/Avaliacao";
import { DenunciaModal } from "../../components/DenunciaModal";

type Props = DrawerScreenProps<DrawerStackParamList, 'ProfileScreen'>;

export function ProfileScreen({ navigation, route }: Props) {
    const [avaliacaoMedia, setAvaliacaoMedia] = useState(0);
    const [avaliacao, setAvaliacao] = useState<AvaliacaoUsuario | null>(null);
    const [perfilInfos, setPerfilInfos] = useState<UserInfos | null>(null);
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [showDenunciaModal, setShowDenunciaModal] = useState(false);
    const [mostrarSnackAvaliacao, setMostrarSnackAvaliacao] = useState(false);
    const [snackAvaliacaoMensagem, setSnackAvaliacaoMensagem] = useState('');
    const [posts, setPosts] = useState<Post[]>([]);
    const [isLoadingPosts, setIsLoadingPosts] = useState(false);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false);
    const [refreshing, setRefreshing] = useState(false);

    const isKeyboardVisible = useKeyboard();
    const { authAxios } = useContext(AxiosContext);
    const userStore = useUserStore();
    const userInfos = useUserStore(state => state.infos);

    const userId = route?.params?.userId;
    const isMyProfile = userId == null || userId === userStore.infos?.id.toString();

    const fetchPosts = async () => {
        setIsLoadingPosts(true);
        try {
            const postData = await getAllPosts(authAxios, userId);
            if (postData.Status !== true) {
                return;
            }
            setPosts(postData.Posts);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingPosts(false);
        }
    };

    const fetchAccountInfos = async () => {
        if (userId == null || userId === userStore.infos?.id?.toString()) {
            // o usuário é o usuário logado
            setPerfilInfos(userStore.infos!);
        } else {
            setIsLoadingProfile(true);
            try {
                const [data, avaliacao] = await Promise.all([
                    getUserInfos(authAxios, userId),
                    buscarAvaliacaoDeUsuario(authAxios, Number.parseInt(userId)),
                ]);

                const confiavel = Number.parseFloat(avaliacao.confiavel);
                const prestativo = Number.parseFloat(avaliacao.prestativo);
                const atraente = Number.parseFloat(avaliacao.atraente);
                const novaAvaliacao: AvaliacaoUsuario = {
                    confiavel,
                    atraente,
                    prestativo,
                };

                setPerfilInfos(data);
                setAvaliacao(novaAvaliacao);
                setAvaliacaoMedia(avaliacao.media);
            } catch (err) {
                console.error(err);
                showAlert('Atenção', 'Usuário não encontrado, tente novamente');
            } finally {
                setIsLoadingProfile(false);
            }
        }
    };

    const onRefresh = useCallback(async () => {
        setRefreshing(true);
        try {
            await fetchPosts();
        } catch (err) {
            console.error(err);
        } finally {
            setRefreshing(false);
        }
    }, []);

    const adicionarPost = async (texto: string) => {
        setIsLoadingPosts(true);
        try {
            setPosts([]);
            const data = await publicarPost(authAxios, {
                comunidade: 1,
                post: texto.trim(),
            });
            if (data.Status !== true) {
                console.error('Erro ao publicar post', data.Mensagem);
                return;
            }

            const postData = await getAllPosts(authAxios);
            if (postData.Status !== true) {
                console.log('status', postData.Status);
                return;
            }
            setPosts(postData.Posts);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingPosts(false);
        }
    };

    const handleRatingModalSubmit = async (review: Review) => {
        const { notaConfiavel, notaPrestativo, notaAtraente } = review;
        setShowRatingModal(false);

        // calcular a média das notas
        const media = (notaConfiavel + notaPrestativo + notaAtraente) / 3;
        setAvaliacaoMedia(media);

        try {
            const data = await avaliarUsuario(authAxios, {
                userId: Number.parseInt(userId),
                atraente: notaAtraente,
                confiavel: notaConfiavel,
                prestativo: notaPrestativo,
            });

            let mensagem;
            if (data.Status) {
                mensagem = 'Avaliação enviada com sucesso';
            } else {
                mensagem = 'Erro ao enviar avaliação, tente novamente';
            }

            setMostrarSnackAvaliacao(true);
            setSnackAvaliacaoMensagem(mensagem);
        } catch (err) {
            console.log(err);
        }
    };

    const openPostComments = (post: Post) => {
        navigation.navigate('PostScreen', {
            postId: post.id,
        });
    };

    const abrirModalDenuncia = () => {
        setShowRatingModal(false);
        setShowDenunciaModal(true);
    };

    useEffect(() => {
        navigation.addListener('focus', () => {
            setPerfilInfos(null);
            fetchPosts();
            fetchAccountInfos();
        });

        // return unsubscribe;
    }, [navigation, route]);

    if (isLoadingProfile) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <SafeAreaView style={themeStyles.background}>
            <NavBar />

            <View style={themeStyles.contentContainer}>
                <FlashList
                    data={posts}
                    estimatedItemSize={170}
                    contentContainerStyle={styles.listContainer}
                    keyboardShouldPersistTaps='handled'
                    keyExtractor={(item) => item.id.toString()}
                    ListHeaderComponent={(
                        <View style={styles.headerWrapper}>
                            <View style={styles.header}>
                                {(perfilInfos != null) && (
                                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                        <UsuarioInfos
                                            userInfos={(isMyProfile) ? userInfos! : perfilInfos}
                                        />

                                        <IconButton
                                            icon="message-text-outline"
                                            iconColor="white"
                                            size={30}
                                            onPress={() => navigation.navigate('MessengerListaDeContatos')}
                                            style={{ right: 10, position: 'absolute' }}
                                        />
                                    </View>
                                )}

                                <View style={{ alignItems: 'center' }}>
                                    <View style={styles.starLine}>
                                        {(isMyProfile) ? (
                                            <>
                                                <Button
                                                    mode="contained"
                                                    buttonColor={paperTheme.colors.surface}
                                                    textColor="white"
                                                    style={{ borderRadius: 5, marginRight: 10 }}
                                                    labelStyle={{ fontSize: 12, marginVertical: 5, marginHorizontal: 25 }}
                                                    contentStyle={{ flexDirection: 'row-reverse', }}
                                                    onPress={() => navigation.navigate('EditarPerfilScreen')}
                                                >
                                                    EDITAR PERFIL
                                                </Button>

                                                <Button
                                                    mode="contained"
                                                    buttonColor={paperTheme.colors.surface}
                                                    textColor="white"
                                                    style={{ borderRadius: 5, }}
                                                    labelStyle={{ fontSize: 12, marginVertical: 5, marginHorizontal: 25 }}
                                                    contentStyle={{ flexDirection: 'row-reverse', }}
                                                    onPress={() => navigation.navigate('QuemAvaliouScreen')}
                                                    icon={() => (
                                                        <SetaBaixoIcon
                                                            width={13}
                                                            height={13}
                                                            fill="white"
                                                        />
                                                    )}
                                                >
                                                    QUEM AVALIOU
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <TouchableRipple
                                                    onPress={() => setShowRatingModal(true)}
                                                    style={styles.starButton}
                                                >
                                                    <Avaliacao
                                                        nota={avaliacaoMedia}
                                                        corIcone="white"
                                                        style={[{ paddingHorizontal: 5, margin: 0 }]}
                                                        tamanho={20}
                                                        readonly
                                                    />
                                                </TouchableRipple>

                                                <Button
                                                    mode="contained"
                                                    buttonColor={paperTheme.colors.surface}
                                                    textColor="white"
                                                    style={{ borderRadius: 5, }}
                                                    labelStyle={{ fontSize: 12, marginVertical: 5, marginHorizontal: 25 }}
                                                    contentStyle={{ flexDirection: 'row-reverse', }}
                                                    onPress={() => navigation.navigate('MessengerChat', {})}
                                                    icon={() => (
                                                        <SetaBaixoIcon
                                                            width={13}
                                                            height={13}
                                                            fill="white"
                                                        />
                                                    )}
                                                >
                                                    MENSAGEM
                                                </Button>
                                            </>
                                        )}
                                    </View>
                                </View>

                                <LinearGradient
                                    colors={['#ff0c5e', '#000000']}
                                    locations={[0.0, 1.0]}
                                    start={[0, 1]}
                                    end={[1, 0]}
                                    style={styles.gradientLine}
                                />
                            </View>

                            <PostTextArea
                                nome="Leonardo"
                                onSubmit={adicionarPost}
                            />
                        </View>
                    )}
                    renderItem={({ item }) => (
                        <View style={styles.postWrapper}>
                            <PostCard
                                post={item}
                                onCommentPress={() => openPostComments(item)}
                            />
                        </View>
                    )}
                    ItemSeparatorComponent={Divider}
                    ListEmptyComponent={(isLoadingPosts) ? (
                        <View style={{ alignItems: 'center' }}>
                            <ActivityIndicator
                                size={50}
                                color={paperTheme.colors.activityIndicator}
                            />
                        </View>
                    ) : (
                        <View style={{ alignItems: 'center' }}>
                            <Text style={styles.emptyListText}>
                                Nenhum post encontrado
                            </Text>
                        </View>
                    )}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                />
            </View>

            {(!isKeyboardVisible) && (
                <MenuRodape />
            )}

            <RatingModal
                avaliacaoInicial={avaliacao}
                visible={showRatingModal}
                onDismiss={() => setShowRatingModal(false)}
                onSubmit={handleRatingModalSubmit}
                onDenunciar={abrirModalDenuncia}
            />

            <DenunciaModal
                visible={showDenunciaModal}
                onDismiss={() => setShowDenunciaModal(false)}
                onSubmit={() => setShowDenunciaModal(false)}
                userId={Number.parseInt(userId)}
            />

            <Snackbar
                visible={mostrarSnackAvaliacao}
                onDismiss={() => {
                    setMostrarSnackAvaliacao(false);
                    setSnackAvaliacaoMensagem('');
                }}
                action={{
                    label: 'Ok',
                    onPress: () => { },
                    labelStyle: { color: paperTheme.colors.primary },
                }}
                style={{ maxWidth: 400, backgroundColor: 'white' }}
                wrapperStyle={{ width: '100%', alignItems: 'center' }}
            >
                {snackAvaliacaoMensagem}
            </Snackbar>
        </SafeAreaView>
    );
}

const infoSvgSize = 15;

const styles = StyleSheet.create({
    scrollBar: {
        height: '100%',
    },

    listContainer: {
        paddingBottom: 100,
    },

    postWrapper: {
        alignItems: 'center',
        // padding: 20,
    },

    content: {
        alignItems: 'center',

        padding: 10,
        paddingTop: navbarStyles.navbar.height + 30,
        paddingHorizontal: 20,
    },

    header: {
        //borderBottomColor: paperTheme.colors.primary,
        //borderBottomWidth: 1,
        paddingBottom: 15,
        maxWidth: 400,
        width: '100%',
    },

    headerWrapper: {
        alignItems: 'center',
        width: '100%',
        paddingTop: 10,
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginTop: 15,
    },

    headerLinha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    userInfo: {},

    usuarioNome: {
        fontSize: 22,
        fontWeight: 'bold',

        margin: 0,
        marginRight: 5,
    },

    userInfoLinha: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    userInfoTexto: {
        fontSize: 16,
        margin: 0,
        marginHorizontal: 5,
    },

    userInfoCargo: {
        color: '#868686',
        fontSize: 17,
        fontWeight: 'bold',
        marginLeft: 0,
    },

    usuarioNomeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        marginBottom: 5,
    },

    posts: {
        alignItems: 'center',
        // width: '100%',
        paddingHorizontal: 10,
        paddingBottom: 100,
    },

    starLine: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        marginBottom: 10,
    },

    emptyListText: {
        fontSize: 22,
    },

    starButton: {
        backgroundColor: paperTheme.colors.surface,
        borderRadius: 5,
        paddingVertical: 2,
        paddingHorizontal: 5,
        marginRight: 10,
    },
});
