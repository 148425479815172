import { Platform, StyleSheet, View } from "react-native";
import { Button, Dialog, Portal, Text } from "react-native-paper";

type Props = {
    visivel: boolean,
    titulo: string,
    texto: string,
    onDismiss?: () => void,
    onOk?: () => void,
}

export function AlertaMatchFim(props: Props) {
    const dialogStyle = (Platform.OS === 'web') ? styles.dialogWeb : null;

    return (
        <Portal>
            <Dialog
                visible={props.visivel}
                onDismiss={props.onDismiss}
                style={dialogStyle}
            >
                <Dialog.Title>
                    {props.titulo}
                </Dialog.Title>
                <Dialog.Content>
                    <Text variant="bodyMedium">
                        {props.texto}
                    </Text>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button onPress={props.onOk ?? props.onDismiss}>
                        Ok
                    </Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    );
}

const styles = StyleSheet.create({
    dialogWeb: {
        width: '90%',
        maxWidth: 600,
        marginHorizontal: 'auto',
    },
});