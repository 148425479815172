import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Snackbar, Text } from "react-native-paper";
import * as DocumentPicker from 'expo-document-picker';
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Avatar } from "../../components/Avatar";
import { ScreenLayout } from "../../components/Layout";
import { SubNavbar } from "../../components/SubNavbar";
import { useUserStore } from "../../stores/userStore";
import { CargoModal } from "./CargoModal";
import { CidadeModal } from "./CidadeModal";
import { EmBuscaModal } from "./EmBuscaModal";
import { SuperPoderModal } from "./SuperPoderModal";
import { paperTheme } from "../../lib/theme";
import { NomeModal } from "./NomeModal";
import { goBack, showAlert } from "../../lib/utils/platform";
import { atualizarDadosDoUsuario, getUserInfos } from "../../lib/services/user";
import { AxiosContext } from "../../stores/AxiosContext";
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { LoadingScreen } from "../../components/LoadingScreen";

type Props = NativeStackScreenProps<DrawerStackParamList, 'EditarPerfilScreen'>;

export function EditarPerfilScreen({ navigation }: Props) {
    const [superPoder, setSuperPoder] = useState('');
    const [coisaEmBusca, setCoisaEmBusca] = useState('');
    const [nome, setNome] = useState('');
    const [nomeSocial, setNomeSocial] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [cargo, setCargo] = useState('');
    const [novoAvatarUrl, setNovoAvatarUrl] = useState<string | null>(null);
    const [mostrarCoisaEmBuscaModal, setMostrarCoisaEmBuscaModal] = useState(false);
    const [mostrarSuperPoderModal, setMostrarSuperPoderModal] = useState(false);
    const [mostrarCidadeModal, setMostrarCidadeModal] = useState(false);
    const [mostrarCargoModal, setMostrarCargoModal] = useState(false);
    const [mostrarNomeModal, setMostrarNomeModal] = useState(false);
    const [mostrarAvatarSucesso, setMostrarAvatarSucesso] = useState(false);
    const [mostrarSnackSucesso, setMostrarSnackSucesso] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [salvarDados, setSalvarDados] = useState(false);

    const userStore = useUserStore();
    const userInfos = useUserStore(state => state.infos);
    const { authAxios } = useContext(AxiosContext);

    const avatar =
        (userInfos != null && userInfos.foto.length > 0)
            ? { uri: novoAvatarUrl ?? userInfos.foto }
            : undefined;

    const nomeExibido = (nomeSocial != null && nomeSocial.length > 0)
        ? nomeSocial
        : nome;

    const handleNomeSubmit = (nome: string, nomeSocial: string) => {
        setNome(nome);
        setNomeSocial(nomeSocial);
        setMostrarNomeModal(false);

        setSalvarDados(true);
    };

    const handleSuperPoderSubmit = (novoSuperPoder: string) => {
        setSuperPoder(novoSuperPoder);
        setMostrarSuperPoderModal(false);

        setSalvarDados(true);
    };

    const handleEmBuscaSubmit = (coisaEmBusca: string) => {
        setCoisaEmBusca(coisaEmBusca);
        setMostrarCoisaEmBuscaModal(false);

        setSalvarDados(true);
    };

    const handleCidadeSubmit = (cidade: string, estado: string) => {
        setCidade(cidade);
        setEstado(estado);
        setMostrarCidadeModal(false);

        setSalvarDados(true);
    };

    const handleCargoSubmit = (empresa: string, cargo: string) => {
        setEmpresa(empresa);
        setCargo(cargo);
        setMostrarCargoModal(false);

        setSalvarDados(true);
    };

    const selecionarArquivoAvatar = async () => {
        try {
            const result = await DocumentPicker.getDocumentAsync({
                type: 'image/*',
            });
            if (result.type === 'cancel') return;

            setNovoAvatarUrl(result.uri);
            setMostrarAvatarSucesso(true);
        } catch (err) {
            console.error(err);
        }
    };

    const enviarTodosDados = async () => {
        if (userInfos == null) return;

        setIsLoading(true);
        try {
            // salvar no servidor
            const data = await atualizarDadosDoUsuario(authAxios, {
                nome,
                cidade,
                estado,
                empresa,
                nome_social: nomeSocial,
                descricao: '',
                procura: coisaEmBusca,
                superPoder,
            });
            if (data.Status === false) {
                throw new Error('Erro ao atualizar usuário');
            }

            // salvar localmente
            userStore.update({
                infos: {
                    ...userInfos,
                    nome,
                    cidade,
                    estado,
                    empresa,
                    nome_social: nomeSocial,
                    descricao: '',
                    procura: coisaEmBusca,
                    superPoder,
                },
            });

            // mostrar snackbar falando que deu certo
            setMostrarSnackSucesso(true);
        } catch (err) {
            console.error(err);
            showAlert('Erro', 'Não foi possível atualizar os dados da conta, tente novamente.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const data = await getUserInfos(authAxios);
            if (data.Status === false) {
                throw new Error('Status inválido');
            }

            setNome(data.nome ?? '');
            setNomeSocial(data.nome_social ?? '');
            setEmpresa(data.empresa ?? '');
            setCidade(data.cidade ?? '');
            setEstado(data.estado ?? '');
            setCoisaEmBusca(data.procura ?? '');
            setSuperPoder(data.superPoder ?? '');
        } catch (err) {
            console.error(err);
            showAlert('Erro', 'Não foi possível encontrar os dados do usuário, tente novamente');
            goBack(navigation);
        } finally {
            setIsLoading(false);
        }
    };

    const getEmpresaTexto = () => {
        const temEmpresa = empresa != null && empresa.trim().length > 1;
        const temCargo = cargo != null && cargo.trim().length > 1;
        if (temEmpresa && temCargo) {
            return `${empresa.trim()} - ${cargo.trim()}`
        } else if (temEmpresa && !temCargo) {
            return empresa.trim();
        } else if (!temEmpresa && temCargo) {
            return cargo.trim();
        } else {
            return '';
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!salvarDados) return;

        // enviar os dados que foram alterados
        enviarTodosDados()
            .then(() => setMostrarSnackSucesso(true))
            .catch((err) => {
                console.error(err);
                showAlert('Erro', 'Não foi possível enviar os dados alterados, tente novamente');
            })
            .finally(() => setSalvarDados(false));
    }, [salvarDados]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <ScreenLayout>
                <SubNavbar
                    title="Editar perfil"
                />

                <View style={styles.content}>
                    {/* 
                    <View style={{ marginBottom: 30, alignItems: 'center' }}>
                        <Avatar
                            imagem={avatar}
                            avatarSize={120}
                        />
                        <Button
                            mode="outlined"
                            textColor="white"
                            icon="pencil"
                            onPress={selecionarArquivoAvatar}
                            style={{ marginTop: 10 }}
                        >
                            ALTERAR AVATAR
                        </Button>
                    </View> 
                    */}

                    <View style={{ marginBottom: 40, alignItems: 'center' }}>
                        <Text style={styles.label}>
                            Nome: {nomeExibido ?? ''}
                        </Text>

                        <Button
                            mode="outlined"
                            textColor="white"
                            icon="pencil"
                            onPress={() => setMostrarNomeModal(true)}
                            style={{ marginTop: 10 }}
                        >
                            ALTERAR NOME
                        </Button>
                    </View>


                    <View style={{ marginBottom: 40, alignItems: 'center' }}>
                        <Text style={styles.label}>
                            Meu superpoder:
                        </Text>
                        {(superPoder.trim().length > 0) && (
                            <Text
                                style={styles.data}
                                selectable
                            >
                                {superPoder}
                            </Text>
                        )}

                        <Button
                            mode="outlined"
                            textColor="white"
                            icon="pencil"
                            onPress={() => setMostrarSuperPoderModal(true)}
                            style={{ marginTop: 10 }}
                        >
                            ALTERAR SUPERPODER
                        </Button>
                    </View>

                    <View style={{ marginBottom: 40, alignItems: 'center' }}>
                        <Text style={styles.label}>
                            O que eu estou buscando:
                        </Text>
                        {(coisaEmBusca.trim().length > 0) && (
                            <Text
                                style={styles.data}
                                selectable
                            >
                                Estou em busca de {coisaEmBusca}
                            </Text>
                        )}

                        <Button
                            mode="outlined"
                            textColor="white"
                            icon="pencil"
                            onPress={() => setMostrarCoisaEmBuscaModal(true)}
                            style={{ marginTop: 10 }}
                        >
                            ALTERAR BUSCA
                        </Button>
                    </View>

                    <View style={{ marginBottom: 40, alignItems: 'center' }}>
                        <Text style={styles.label}>
                            Minha localização:
                        </Text>
                        {(cidade.trim().length > 0 || estado.trim().length > 0) && (
                            <Text
                                style={styles.data}
                                selectable
                            >
                                {cidade ?? ''} - {estado ?? ''}
                            </Text>
                        )}

                        <Button
                            mode="outlined"
                            textColor="white"
                            icon="pencil"
                            onPress={() => setMostrarCidadeModal(true)}
                            style={{ marginTop: 10 }}
                        >
                            ALTERAR LOCALIZAÇÃO
                        </Button>
                    </View>

                    <View style={{ marginBottom: 40, alignItems: 'center' }}>
                        <Text style={styles.label}>
                            Onde eu trabalho:
                        </Text>
                        {(empresa.trim().length > 0 || cargo.trim().length > 0) && (
                            <Text
                                style={styles.data}
                                selectable
                            >
                                {getEmpresaTexto()}
                            </Text>
                        )}

                        <Button
                            mode="outlined"
                            textColor="white"
                            icon="pencil"
                            onPress={() => setMostrarCargoModal(true)}
                            style={{ marginTop: 10 }}
                        >
                            ALTERAR TRABALHO
                        </Button>
                    </View>
                </View>
            </ScreenLayout>

            <SuperPoderModal
                visible={mostrarSuperPoderModal}
                initialState={{ superPoder }}
                onDismiss={() => setMostrarSuperPoderModal(false)}
                onSubmit={handleSuperPoderSubmit}
            />

            <EmBuscaModal
                visible={mostrarCoisaEmBuscaModal}
                initialState={{ coisaEmBusca }}
                onDismiss={() => setMostrarCoisaEmBuscaModal(false)}
                onSubmit={handleEmBuscaSubmit}
            />

            <CidadeModal
                visible={mostrarCidadeModal}
                initialState={{ cidade, estado }}
                onDismiss={() => setMostrarCidadeModal(false)}
                onSubmit={handleCidadeSubmit}
            />

            <CargoModal
                visible={mostrarCargoModal}
                initialState={{ empresa, cargo }}
                onDismiss={() => setMostrarCargoModal(false)}
                onSubmit={handleCargoSubmit}
            />

            <NomeModal
                visible={mostrarNomeModal}
                initialState={{ nome, nomeSocial }}
                onDismiss={() => setMostrarNomeModal(false)}
                onSubmit={handleNomeSubmit}
            />

            <Snackbar
                visible={mostrarAvatarSucesso}
                onDismiss={() => setMostrarAvatarSucesso(false)}
                action={{
                    label: 'Ok',
                    onPress: () => { },
                    labelStyle: { color: paperTheme.colors.primary }
                }}
                style={{ maxWidth: 400, backgroundColor: 'white' }}
                wrapperStyle={{ width: '100%', alignItems: 'center' }}
            >
                Avatar enviado com sucesso
            </Snackbar>

            <Snackbar
                visible={mostrarSnackSucesso}
                onDismiss={() => setMostrarSnackSucesso(false)}
                action={{
                    label: 'Ok',
                    onPress: () => { },
                    labelStyle: { color: paperTheme.colors.primary }
                }}
                style={{ maxWidth: 400, backgroundColor: 'white' }}
                wrapperStyle={{ width: '100%', alignItems: 'center' }}
            >
                Dados alterados com sucesso
            </Snackbar>
        </>
    );
}

const styles = StyleSheet.create({
    content: {
        alignItems: 'center',
    },

    label: {
        fontSize: 19,
        fontWeight: '700',
    },

    data: {
        marginTop: 10,
        fontSize: 17,

        paddingHorizontal: 10,
        maxWidth: 380,
    },
});