export const CONTATOS_MOCKADOS = [
    {
        usuario: 'Isaías Barbosa',
        ultimaMensagem: 'Tudo jóia',
        avatar: 'https://i.imgur.com/7qhH0Ui.png',
        foiLido: false,
    },
    {
        usuario: 'João Kleber',
        ultimaMensagem: 'O que temos que ter sempre em mente é...',
        avatar: 'https://i.imgur.com/gg33WcW.jpg',
        foiLido: true,
    },
];