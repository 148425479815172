import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, RefreshControl, TouchableOpacity } from "react-native";
import { Text, Provider as PaperProvider, Divider, ActivityIndicator, IconButton } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";
import uuid from 'react-native-uuid';
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import VerificadoIcon from '../assets/icons/verificado.svg';
import PredioIcon from '../assets/icons/predio.svg';
import HeartIcon from '../assets/icons/heart.svg';
import RaioIcon from '../assets/icons/raio.svg';
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { useUserStore } from "../../stores/userStore";
import { AxiosContext } from "../../stores/AxiosContext";
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { NavBar, navbarStyles } from "../../components/NavBar";
import { MenuRodape } from "../../components/MenuRodape";
import { PostCard } from "../../components/Post";
import { PostTextArea } from "../../components/PostTextArea";
import { getPost, getPostComments, Post, publicarComentarioDoPost } from "../../lib/services/posts";
import useKeyboard from "../../hooks/useKeyboard";
import { themeStyles } from "../../lib/theme";
import { ComunidadePostCard } from "./ComunidadePost";
import {
    buscarComentariosDoPostComunidade,
    buscarPostDaComunidade,
    ComunidadePost,
    publicarComentarioDoPostComunidade
} from "../../lib/services/comunidades";
import { goBack } from "../../lib/utils/platform";

type Props = NativeStackScreenProps<DrawerStackParamList, 'ComunidadePostScreen'>;

export function ComunidadePostScreen({ navigation, route }: Props) {
    const { postId, comunidadeId } = route.params;

    const [isLoading, setIsLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [postPai, setPostPai] = useState<ComunidadePost | null>(null);
    const [comentarios, setComentarios] = useState<ComunidadePost[]>([]);
    const [mostrarTextArea, setMostrarTextArea] = useState(false);

    const userStore = useUserStore();
    const isKeyboardVisible = useKeyboard();
    const { authAxios } = useContext(AxiosContext);

    const fetchPosts = async () => {
        setIsLoading(true);
        try {
            const [postPaiData, comentariosData] = await Promise.all([
                buscarPostDaComunidade(authAxios, comunidadeId, postId),
                buscarComentariosDoPostComunidade(authAxios, postId),
            ]);

            setPostPai(postPaiData.Posts[0]);
            setComentarios(comentariosData.Posts);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
        try {
            setComentarios([]);
            await fetchPosts();
        } catch (err) {
            console.error(err);
        } finally {
            setRefreshing(false);
        }
    }, []);

    const adicionarPost = async (texto: string) => {
        setIsLoading(true);
        try {
            setComentarios([]);
            await publicarComentarioDoPostComunidade(authAxios, postId, texto.trim());
            await fetchPosts();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setPostPai(null);
        setComentarios([]);
        fetchPosts();
    }, [route]);

    return (
        <SafeAreaView style={themeStyles.background}>
            <NavBar />

            <View style={themeStyles.contentContainer}>
                <FlashList
                    data={comentarios}
                    estimatedItemSize={170}
                    contentContainerStyle={styles.container}
                    keyboardShouldPersistTaps='handled'
                    keyExtractor={(item) => item.id.toString()}
                    ListHeaderComponent={(
                        <>
                            <View style={styles.headerNavbar}>
                                <IconButton
                                    onPress={() => goBack(navigation)}
                                    style={{ transform: [{ rotate: '90deg' }] }}
                                    icon={() => (
                                        <SetaBaixoIcon
                                            width={25}
                                            height={25}
                                            fill="white"
                                        />
                                    )}
                                />

                                <Text style={styles.headerTitle}>
                                    Comunidade
                                </Text>

                                <View style={{ width: 40 }} />
                            </View>

                            <View style={{ alignItems: 'center', paddingTop: 10 }}>

                                {(postPai != null) && (
                                    <View style={styles.postWrapper}>
                                        <ComunidadePostCard
                                            post={postPai}
                                            comunidadeId={comunidadeId}
                                            onCommentPress={() => setMostrarTextArea(!mostrarTextArea)}
                                        />
                                    </View>
                                )}

                                {(!isLoading && postPai != null && mostrarTextArea) && (
                                    <PostTextArea
                                        nome={userStore.infos?.nome ?? ''}
                                        onSubmit={adicionarPost}
                                        style={styles.textArea}
                                    />
                                )}

                                {(isLoading) && (
                                    <View style={styles.loadingArea}>
                                        <ActivityIndicator
                                            color="white"
                                            size={50}
                                        />
                                    </View>
                                )}
                            </View>
                        </>
                    )}
                    renderItem={({ item }) => (
                        <View style={styles.postWrapper}>
                            <ComunidadePostCard
                                post={item}
                                comunidadeId={comunidadeId}
                                hideCommentButton
                                hideShareButton
                                hideLikeButton
                            />
                        </View>
                    )}
                    // ItemSeparatorComponent={Divider}
                    ListEmptyComponent={(isLoading) ? (
                        <View style={{ alignItems: 'center' }}>
                        </View>
                    ) : (
                        <View style={{ alignItems: 'center' }}>
                            <Text style={styles.emptyListText}>
                                Nenhum comentário encontrado
                            </Text>
                        </View>
                    )}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                />
            </View>

            {(!isKeyboardVisible) && (
                <MenuRodape esconderBotaoHome />
            )}
        </SafeAreaView>
    );
}

const avatarSize = 80;
const infoSvgSize = 15;

const styles = StyleSheet.create({
    headerNavbar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: '100%',
        paddingVertical: 10,
    },

    headerTitle: {
        fontSize: 23,
    },

    container: {
        paddingBottom: 130,
    },

    postWrapper: {
        // alignItems: 'center',
        // padding: 20,
        marginVertical: 10,
    },

    emptyListText: {
        fontSize: 22,
    },

    avatar: {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,

        marginRight: 10,
        marginLeft: 5,
    },

    header: {
        //borderBottomColor: paperTheme.colors.primary,
        //borderBottomWidth: 1,
        paddingBottom: 15,
        maxWidth: 400,
        width: '100%',
    },

    headerWrapper: {
        alignItems: 'center',
        width: '100%',
        paddingTop: 10,
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginTop: 15,
    },

    headerLinha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    usuarioNome: {
        fontSize: 22,
        fontWeight: 'bold',

        margin: 0,
        marginRight: 5,
        marginBottom: 5,
    },

    userInfoLinha: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    userInfoTexto: {
        fontSize: 16,
        margin: 0,
        marginHorizontal: 5,
    },

    userInfoCargo: {
        color: '#868686',
        fontSize: 17,
        fontWeight: 'bold',
        marginLeft: 0,
    },

    usuarioNomeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    loadingArea: {
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 120,
    },

    textArea: {
        marginVertical: 20,
    },
});