import React from 'react';
import { DrawerContentComponentProps, DrawerContentScrollView, DrawerItemList, DrawerScreenProps } from '@react-navigation/drawer';
import { Image, TouchableOpacity, StatusBar, StyleSheet, View, ImageURISource, Alert, Linking, SafeAreaView } from 'react-native';
import { IconButton, Text } from 'react-native-paper';
import { themeStyles } from '../lib/theme';
import HomeIcon from '../assets/icons/home.svg';
import { showAlert } from '../lib/utils/platform';
import { useUserStore } from '../stores/userStore';
import { useQueryClient } from '@tanstack/react-query';

type Props = DrawerContentComponentProps;

const svgSize = 20;

const menuItems = [
    {
        title: 'Home',
        page: 'HomeScreen',
        icon: () => (
            <HomeIcon 
                width={svgSize}
                height={svgSize}
                fill="white"
            />
        ),
    },
    {
        title: 'Minha Conta',
        page: 'MyProfileScreen',
        icon: 'account',
    },
    {
        title: 'Editar dados',
        page: 'EditarPerfilScreen',
        icon: 'account-edit',
    },
    {
        title: 'Sair',
        page: '<sair>',
        icon: 'exit-to-app'
    },
] as const;
type MenuItem = typeof menuItems[number];

export function CustomDrawer({ navigation }: Props) {
    const resetUserStore = useUserStore(state => state.reset);
    const queryClient = useQueryClient();

    const closeMenu = () => {
        navigation.closeDrawer();
    };

    const onItemPress = (item: MenuItem) => {
        if (item.page == null) {
            showAlert('', 'Essa função ainda não está disponível.');
            return;
        }

        if (item.page === '<sair>') {
            // limpar todos os caches
            queryClient.invalidateQueries();
            // deslogar usuario
            resetUserStore();
            navigation.navigate('LoginScreen');
        } else if (typeof item.page === 'string') {
            navigation.navigate(item.page);
        }
    };

    return (
        <SafeAreaView style={[styles.fundo, themeStyles.background]}>
            <View style={styles.closeButtonWrapper}>
                <IconButton
                    icon="close-thick"
                    iconColor="white"
                    size={40}
                    onPress={closeMenu}
                />
            </View>

            <DrawerContentScrollView style={styles.container}>
                {menuItems.map(item => (
                    <TouchableOpacity
                        key={item.title}
                        onPress={() => onItemPress(item)}
                        style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 15 }}
                    >
                        <IconButton
                            icon={item.icon}
                            iconColor="white"
                            style={styles.image}
                            size={27}
                        />

                        <Text style={[styles.text]}>
                            {item.title}
                        </Text>
                    </TouchableOpacity>
                ))}
            </DrawerContentScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
    },

    fundo: {
        flex: 1,
        height: '100%',
    },

    closeButtonWrapper: {
        justifyContent: 'center',
        alignItems: 'flex-start',

        height: 60,
        marginTop: 10,
    },

    text: {
        fontSize: 20,
        fontWeight: '500',
    },

    image: {
        tintColor: 'white',

        margin: 0,
        padding: 0,
        marginHorizontal: 20,
    },
});