import React from "react";
import { ImageURISource, View, StyleSheet, Image, ViewStyle, ImageStyle, Pressable } from "react-native";
import { IconButton } from "react-native-paper";
import { paperTheme } from "../lib/theme";

export type Props = {
    imagem?: ImageURISource,
    avatarSize?: number,
    onPress?: () => void,
    style?: ImageStyle,
};

export function Avatar(props: Props) {
    const avatarSize = props.avatarSize ?? defaultAvatarSize;
    const imageSizeStyle: ImageStyle = {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,
    };
    const imageStyles = [styles.img, props.style, imageSizeStyle];

    const temAvatar = props.imagem?.uri != null && props.imagem.uri.trim().length > 0;

    return (
        <Pressable
            android_ripple={{ color: 'default', borderless: true }}
            onPress={props.onPress}
            disabled={(props.onPress == null)}
        >
            {(temAvatar) ? (
                <Image
                    source={props.imagem}
                    style={imageStyles}
                />
            ) : (
                <IconButton 
                    icon="account"
                    iconColor="white"
                    size={avatarSize * 0.80}
                    style={imageStyles}
                />
            )}
        </Pressable>
    );
}

const defaultAvatarSize = 55;

const styles = StyleSheet.create({
    img: {
        backgroundColor: paperTheme.colors.primary,
        borderColor: 'white',
        borderWidth: 2,
        margin: 0,
    },
});