import React, { ForwardedRef } from "react";
import { Control, FieldValues, Path, Controller } from "react-hook-form";
import { TextInput } from "react-native-paper";
import { TextInput as RNTextInput } from 'react-native';
import altForwardRef from './altForwardRef';

type TextInputProps = React.ComponentProps<typeof TextInput>;

type FormProps<TFieldValues extends FieldValues> = {
    fieldName: Path<TFieldValues>,
    control?: Control<TFieldValues> | undefined,
}

function FormTextInput<TFieldValues extends FieldValues>(
    props: TextInputProps & FormProps<TFieldValues>,
    ref: ForwardedRef<RNTextInput>,
) {
    return (
        <Controller
            control={props.control}
            name={(props as FormProps<TFieldValues>).fieldName as any}
            render={({ field: { onChange, onBlur, value } }) => {
                return (
                    <TextInput
                        ref={ref}
                        {...props}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                    />
                )
            }}
        />
    );
};

export default altForwardRef(FormTextInput);
