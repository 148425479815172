import React, { PropsWithChildren } from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { Surface } from "react-native-paper";
import { themeStyles } from "../../lib/theme";

type Props = {
    onPress?: () => void,
    style?: ViewStyle,
};

export function ComunidadeCard(props: PropsWithChildren<Props>) {
    return (
        <Surface 
            style={[themeStyles.surface, styles.comunidade, props.style]}
            elevation={2}
        >
            {(props.onPress != null) ? (
                <Pressable
                    android_ripple={{ color: 'default', borderless: true }}
                    style={styles.pressable}
                    onPress={props.onPress}
                    disabled={props.onPress == null}
                >
                    {props.children}
                </Pressable>
            ) : (
                <View style={styles.pressable}>
                    {props.children}
                </View>
            )}
        </Surface>
    );
}

const styles = StyleSheet.create({
    comunidade: {
        borderRadius: 8,
        width: '90%',
        maxWidth: 340,
        height: 85,

        margin: 10,
    },
    
    pressable: {
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 5,
        width: '100%',
    },
});