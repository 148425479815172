import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { Text } from "react-native-paper";
import { useUserStore } from "../stores/userStore";
import { Avatar } from "./Avatar";
import VerificadoIcon from '../assets/icons/verificado.svg';
import PredioIcon from '../assets/icons/predio.svg';
import HeartIcon from '../assets/icons/heart.svg';
import RaioIcon from '../assets/icons/raio.svg';
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { SuperPoderModal } from "../screens/ProfileScreen/SuperPoderModal";
import { UserInfos } from "../lib/services/user";

export type Props = {
    userInfos: UserInfos,
    onAvatarPress?: () => void,
    onSuperPoderPress?: () => void,
};

export function UsuarioInfos(props: Props) {
    const { width, height } = useWindowDimensions();

    const { userInfos } = props;

    let avatar = undefined;
    if (userInfos?.foto != null && userInfos.foto.length > 0) {
        avatar = { uri: userInfos.foto };
    }

    const nomeSocial = userInfos.nome_social;
    const nomeExibido =
        (nomeSocial != null && nomeSocial.length > 0)
            ? nomeSocial
            : userInfos.nome;

    const avatarSize = (width > 400) ? 80 : 70;
    const avatarStyle = {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,

        marginRight: 10,
        marginLeft: 5,
    };

    return (
        <>
            <View style={styles.header}>
                <View style={styles.headerLinha}>
                    <Avatar
                        imagem={avatar}
                        avatarSize={avatarSize}
                        style={avatarStyle}
                        onPress={props.onAvatarPress}
                    />

                    <View>
                        <View>
                            <View style={styles.usuarioNomeWrapper}>
                                <Text
                                    style={styles.usuarioNome}
                                    onPress={props.onAvatarPress}
                                >
                                    {nomeExibido ?? ''}
                                </Text>

                                {/* 
                                <VerificadoIcon
                                    width={20}
                                    height={20}
                                    fill="#49acf3"
                                /> 
                                */}
                            </View>

                            <View style={{ minHeight: 50, minWidth: 240 }}>
                                {(props.userInfos.empresa != null && props.userInfos.empresa.length > 0) && (
                                    <View style={styles.userInfoLinha}>
                                        <PredioIcon
                                            width={infoSvgSize}
                                            height={infoSvgSize}
                                            fill="white"
                                        />

                                        <Text style={styles.userInfoTexto}>
                                            {userInfos.empresa}
                                        </Text>

                                        {/* 
                                        <Text style={[styles.userInfoTexto, styles.userInfoCargo]}>
                                            Founder
                                        </Text> 
                                        */}
                                    </View>
                                )}

                                {(userInfos.procura != null && userInfos.procura.length > 0) && (
                                    <View style={styles.userInfoLinha}>
                                        <HeartIcon
                                            width={infoSvgSize}
                                            height={infoSvgSize}
                                            fill="white"
                                        />

                                        <Text style={styles.userInfoTexto}>
                                            Em busca de {userInfos.procura}
                                        </Text>
                                    </View>
                                )}

                                {(userInfos.superPoder != null && userInfos.superPoder.trim().length > 0) && (
                                    <>
                                        <View style={styles.userInfoLinha}>
                                            <RaioIcon
                                                width={infoSvgSize}
                                                height={infoSvgSize}
                                                fill="white"
                                            />

                                            <Text style={styles.userInfoTexto}>
                                                Meu super poder:
                                            </Text>
                                        </View>

                                        <Text style={[styles.userInfoTexto, { marginLeft: 20 }]}>
                                            {userInfos.superPoder}
                                        </Text>
                                    </>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </>
    );
}

const infoSvgSize = 15;

const styles = StyleSheet.create({
    header: {
        //borderBottomColor: paperTheme.colors.primary,
        //borderBottomWidth: 1,
        paddingLeft: 10,
        paddingBottom: 15,
        maxWidth: 400,
        width: '70%',
    },

    headerWrapper: {
        alignItems: 'center',
        width: '100%',
        paddingTop: 10,
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginTop: 15,
    },

    headerLinha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    usuarioNome: {
        fontSize: 22,
        fontWeight: 'bold',

        margin: 0,
        marginRight: 20,
        maxWidth: 220,
    },

    userInfoLinha: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    userInfoTexto: {
        fontSize: 16,
        margin: 0,
        marginHorizontal: 5,
        maxWidth: 240,
    },

    userInfoCargo: {
        color: '#868686',
        fontSize: 17,
        fontWeight: 'bold',
        marginLeft: 0,
    },

    usuarioNomeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        marginBottom: 5,
    },

    avatar: {
        // width: avatarSize,
        // height: avatarSize,
        // borderRadius: avatarSize / 2,

        marginRight: 10,
        marginLeft: 5,
    },
});