import { z } from 'zod';
import { StyleSheet, View, TextInput, Platform, Alert } from 'react-native';
import { Button, HelperText, IconButton, Text } from "react-native-paper";
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { ScreenLayout } from "../../components/Layout";
import { goBack, showAlert } from '../../lib/utils/platform';
import { RootStackParamList } from '../../navigation/navigationTypes';
import FormTextInput from '../../components/form/FormTextInput';
import { formStyles } from '../../lib/theme';
import { useContext, useRef, useState } from 'react';
import { LoadingScreen } from '../../components/LoadingScreen';
import { criarContaUsuario } from '../../lib/services/user';
import { AxiosContext } from '../../stores/AxiosContext';

type Props = NativeStackScreenProps<RootStackParamList, 'CriarContaScreen'>;

/** regex que aceita CPFs com ou sem ponto e hifen */
const cpfRegex = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;

const schema = z.object({
    nome: z.string()
        .min(1, 'Campo obrigatório'),

    email: z.string()
        .min(1, 'Campo obrigatório')
        .email('Email inválido'),

    cpf: z.string()
        .min(1, 'Campo obrigatório')
        .regex(cpfRegex, 'CPF inválido'),

    senha: z.string()
        .min(5, 'Senha muito curta'),

    confirmarSenha: z.string()
        .min(5, 'Senha muito curta'),
})
    .refine((data) => data.senha === data.confirmarSenha, {
        message: "As senhas devem ser iguais",
        path: ["confirmarSenha"]
    });

type FormValues = z.infer<typeof schema>;

export function CriarContaScreen({ navigation }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const cpfTextInput = useRef<TextInput>(null);
    const emailTextInput = useRef<TextInput>(null);
    const senhaTextInput = useRef<TextInput>(null);
    const confirmarSenhaTextInput = useRef<TextInput>(null);

    const { authAxios } = useContext(AxiosContext);
    const { control, handleSubmit, formState: { errors } } = useForm<FormValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            cpf: '',
            email: '',
            nome: '',
            senha: '',
            confirmarSenha: '',
        },
    });

    const onSubmit = async (values: FormValues) => {
        setIsLoading(true);
        try {
            const data = await criarContaUsuario(authAxios, {
                nome: values.nome,
                cpf: values.cpf,
                email: values.email,
                senha: values.senha,
            });

            if (data.Status !== true) {
                throw new Error('Não conseguimos criar sua conta, tente novamente.');
            }

            // ir para tela de sucesso
            navigation.navigate('CriarContaSucessoScreen');
        } catch (err) {
            console.error(err);
            showAlert('Erro', 'Não conseguimos criar sua conta, tente novamente.');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <LoadingScreen hideNavBar />
        );
    }

    return (
        <ScreenLayout
            contentContainerStyle={styles.container}
            esconderNavBar
            esconderRodaPe
        >
            <View style={{ position: 'absolute', left: 0, top: 10, zIndex: 40 }}>
                <IconButton
                    iconColor="white"
                    size={navbarSvgSize}
                    style={styles.goBack}
                    onPress={() => navigation.navigate('LoginScreen')}
                    icon={() => (
                        <SetaBaixoIcon
                            width={navbarSvgSize}
                            height={navbarSvgSize}
                            fill="white"
                        />
                    )}
                />
            </View>

            <Text style={styles.pageTitle}>
                Insira os dados da conta:
            </Text>

            <View style={formStyles.fieldAndHelperTextWrapper}>
                <FormTextInput
                    control={control}
                    mode="outlined"
                    fieldName="nome"
                    label="Nome completo"
                    returnKeyType="next"
                    onSubmitEditing={() => cpfTextInput.current?.focus()}
                    blurOnSubmit={false}
                    style={formStyles.textInputOutlined}
                />

                <HelperText
                    type="error"
                    visible={errors.nome != null}
                >
                    {errors.nome?.message}
                </HelperText>
            </View>


            <View style={formStyles.fieldAndHelperTextWrapper}>
                <FormTextInput
                    ref={cpfTextInput}
                    control={control}
                    mode="outlined"
                    fieldName="cpf"
                    label="CPF"
                    returnKeyType="next"
                    onSubmitEditing={() => emailTextInput.current?.focus()}
                    blurOnSubmit={false}
                    style={formStyles.textInputOutlined}
                />

                <HelperText
                    type="error"
                    visible={errors.cpf != null}
                >
                    {errors.cpf?.message}
                </HelperText>
            </View>

            <View style={formStyles.fieldAndHelperTextWrapper}>
                <FormTextInput
                    ref={emailTextInput}
                    control={control}
                    mode="outlined"
                    fieldName="email"
                    label="E-mail"
                    returnKeyType="next"
                    onSubmitEditing={() => senhaTextInput.current?.focus()}
                    blurOnSubmit={false}
                    style={formStyles.textInputOutlined}
                />

                <HelperText
                    type="error"
                    visible={errors.email != null}
                >
                    {errors.email?.message}
                </HelperText>
            </View>

            <View style={formStyles.fieldAndHelperTextWrapper}>
                <FormTextInput
                    ref={senhaTextInput}
                    control={control}
                    mode="outlined"
                    fieldName="senha"
                    label="Senha"
                    returnKeyType="next"
                    onSubmitEditing={() => confirmarSenhaTextInput.current?.focus()}
                    blurOnSubmit={false}
                    secureTextEntry
                    style={formStyles.textInputOutlined}
                />

                <HelperText
                    type="error"
                    visible={errors.senha != null}
                >
                    {errors.senha?.message}
                </HelperText>
            </View>

            <View style={formStyles.fieldAndHelperTextWrapper}>
                <FormTextInput
                    ref={confirmarSenhaTextInput}
                    control={control}
                    mode="outlined"
                    fieldName="confirmarSenha"
                    label="Confirmar senha"
                    secureTextEntry
                    onSubmitEditing={handleSubmit(onSubmit)}
                    style={formStyles.textInputOutlined}
                />

                <HelperText
                    type="error"
                    visible={errors.confirmarSenha != null}
                >
                    {errors.confirmarSenha?.message}
                </HelperText>
            </View>

            <Button
                mode="contained"
                textColor="white"
                onPress={handleSubmit(onSubmit)}
            >
                Criar conta
            </Button>
        </ScreenLayout>
    );
}

const navbarSvgSize = 25;

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingTop: 60,
    },

    goBack: {
        transform: [{ rotate: '90deg' }],
        left: 0,
    },

    pageTitle: {
        fontSize: 22,

        marginBottom: 30,
    },
});