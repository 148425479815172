import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { UserInfos } from '../lib/services/user';

export type AppState = {
    showShareModal: boolean,

    update: (newState: Partial<AppState>) => void,
};

export const useAppStore = create<AppState>()(
    devtools<AppState>(
        (set) => ({
            showShareModal: false,

            update: (newState: Partial<AppState>) => set({ ...newState }),
        }),
    ),
);
