import React, { useContext, useEffect, useState } from 'react';
import { ImageURISource, View, StyleSheet, Share, Platform } from 'react-native';
import { Button, Divider, IconButton, Menu, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import * as Sharing from 'expo-sharing';
import { Avatar } from './Avatar';
import { paperTheme } from '../lib/theme';
import { usePostStore } from '../stores/postStore';
import { checarCurtidaPost, curtirPost, Post, registrarCompartilhamentoPost } from '../lib/services/posts';
import HeartIcon from '../assets/icons/heart.svg';
import HeartOutlineIcon from '../assets/icons/heart-outline.svg';
import CommentIcon from '../assets/icons/comment.svg';
import ShareIcon from '../assets/icons/share.svg';
import { AxiosContext, baseURL, websiteUrl } from '../stores/AxiosContext';
import { useUserStore } from '../stores/userStore';
import { showConfirm, suportaCompartilhamento } from '../lib/utils/platform';
import { ShareModal } from './ShareModal';
import { useAppStore } from '../stores/appStore';
import { excluirPost } from '../lib/services/user';

export type Props = {
    post: Post,
    hideCommentButton?: boolean,
    hideShareButton?: boolean,
    hideLikeButton?: boolean,
    onCommentPress?: () => void,
};

export function PostCard(props: Props) {
    const [temLike, setTemLike] = useState(props.post.curtido?.toString() === '1');
    const [likes, setLikes] = useState(props.post.curtidas);
    const [isLoadingLike, setIsLoadingLike] = useState(false);
    const [mostrarMenu, setMostrarMenu] = useState(false);

    const navigation = useNavigation();
    const appStore = useAppStore();
    const userInfos = useUserStore(state => state.infos);
    const { authAxios } = useContext(AxiosContext);

    let avatarImagem = undefined;
    if (props.post.foto != null && props.post.foto.length > 0) {
        avatarImagem = { uri: props.post.foto };
    }

    const isProprioPost = props.post.user_id === userInfos?.id.toString(); 

    const likeButtonStyle = (isLoadingLike)
        ? styles.disabledIconButton
        : undefined;

    const fetchLike = async () => {
        setIsLoadingLike(true);
        try {
            const data = await checarCurtidaPost(authAxios, props.post.id);
            setTemLike(data.Curtiu);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingLike(false);
        }
    };

    const handleAvatarPress = () => {
        const userId = userInfos?.id;
        const postUserId = props.post.user_id;

        if (postUserId == null) {
            return;
        } else if (userId?.toString() === postUserId.toString()) {
            // usuário do post é o mesmo usuário logado
            navigation.navigate('MyProfileScreen' as any, {});
        } else {
            navigation.navigate('ProfileScreen' as any, {
                userId: postUserId,
            });
        }
    };

    const handleHeartPress = async () => {
        const novoTemLike = !temLike;
        setTemLike(novoTemLike);

        if (novoTemLike) {
            setLikes(props.post.curtidas + 1);
        } else {
            setLikes(props.post.curtidas);
        }

        setIsLoadingLike(true);
        try {
            await curtirPost(authAxios, props.post.id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingLike(false);
        }
    };

    const handleSharePress = async () => {
        try {
            const url = `${websiteUrl}Post/${props.post.id}`;

            try {
                await Share.share({
                    url,
                });
            } catch (err) {
                if (!(err instanceof Error)) return;

                if (Platform.OS === 'web' && err.message.includes('Share is not supported')) {
                    // plataforma não suporta compartilhamento nativo, usar fallback
                    appStore.update({ showShareModal: true });
                }
            }
            await registrarCompartilhamentoPost(authAxios, props.post.id);
        } catch (err) {
            console.error(err);
        }
    };

    const handleExcluirPost = async () => {
        try {
            const confirmacao = await showConfirm(
                'Atenção',
                'Tem certeza que você quer excluir esse post?',
            );

            setMostrarMenu(false);
            if (confirmacao) {
                await excluirPost(authAxios, props.post.id);
            }

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchLike();
    }, []);

    return (
        <>
            <View style={styles.container}>
                <View style={styles.avatarWrapper}>
                    <Avatar
                        imagem={avatarImagem}
                        onPress={handleAvatarPress}
                    />
                </View>

                <View>
                    <Text
                        style={styles.username}
                        onPress={handleAvatarPress}
                    >
                        {props.post.usuario}
                    </Text>
                    <Text
                        selectable
                        style={styles.conteudoTexto}
                    >
                        {props.post.post}
                    </Text>

                    <View style={styles.buttons}>
                        {(!props.hideLikeButton) && (
                            <View style={styles.button}>
                                <IconButton
                                    iconColor="white"
                                    size={iconButtonSize}
                                    onPress={handleHeartPress}
                                    disabled={isLoadingLike}
                                    style={likeButtonStyle}
                                    icon={() =>
                                        (temLike) ? (
                                            <HeartIcon
                                                width={svgSize}
                                                height={svgSize}
                                                fill={paperTheme.colors.primary}
                                            />
                                        ) : (
                                            <HeartOutlineIcon
                                                width={svgSize}
                                                height={svgSize}
                                                fill="white"
                                            />
                                        )
                                    }
                                />

                                <Text style={styles.buttonText}>
                                    {likes}
                                </Text>
                            </View>
                        )}

                        {(!props.hideCommentButton) && (
                            <View style={styles.button}>
                                <IconButton
                                    iconColor="white"
                                    size={iconButtonSize}
                                    onPress={props.onCommentPress}
                                    icon={() => (
                                        <CommentIcon
                                            width={comentSvgSize}
                                            height={comentSvgSize}
                                            fill="white"
                                        />
                                    )}
                                />

                                <Text style={styles.buttonText}>
                                    {props.post.comentarios}
                                </Text>
                            </View>
                        )}

                        {(!props.hideShareButton) && (
                            <View style={styles.button}>
                                <IconButton
                                    iconColor="white"
                                    size={iconButtonSize}
                                    onPress={handleSharePress}
                                    icon={() => (
                                        <ShareIcon
                                            width={svgSize}
                                            height={svgSize}
                                            fill="white"
                                        />
                                    )}
                                />

                                <Text style={styles.buttonText}>
                                    {props.post.compartilhamento}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>


                <Menu
                    visible={mostrarMenu}
                    onDismiss={() => setMostrarMenu(false)}
                    anchor={
                        <IconButton
                            icon="dots-vertical"
                            onPress={() => setMostrarMenu(true)}
                            style={{ bottom: 12 }}
                        />
                    }
                >
                    <Menu.Item 
                        onPress={handleExcluirPost} 
                        title="Excluir post"
                        disabled={!isProprioPost} 
                    />
                </Menu>
            </View>
        </>
    );
}

const iconButtonSize = 30;
const svgSize = 28;
const comentSvgSize = svgSize + 5;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingVertical: 10,
    },

    username: {
        fontSize: 20,
        fontWeight: '700',
    },

    conteudoTexto: {
        width: 220,
        fontSize: 18,
        margin: 10,
        marginRight: 0,
        marginLeft: 0,
    },

    avatarWrapper: {
        marginLeft: 10,
        marginRight: 10,
    },

    buttons: {
        flexDirection: 'row',
        left: -10,
    },

    button: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        //marginHorizontal: 2,
    },

    disabledIconButton: {
        opacity: 0.6,
    },

    buttonText: {
        fontSize: 18,
        margin: 0,
    },
});