import { AxiosInstance } from "axios";
import { z } from "zod";

export const PostSchema = z.object({
    id: z.number(),
    usuario: z.string(),
    user_id: z.string().optional(),
    foto: z.string(),
    url: z.string(),
    post: z.string(),
    curtidas: z.number(),
    curtido: z.union([z.string(), z.number()]).optional(),
    comentarios: z.number(),
    compartilhamento: z.number(),
});

export type Post = z.infer<typeof PostSchema>;

export const PostResponseSchema = z.object({
    Status: z.boolean(),
    Posts: z.array(PostSchema),
});

export async function getAllPosts(axios: AxiosInstance, userId?: string) {
    const url = 
        (userId != null)
            ? `/api/postuser/${userId}`
            : `/api/allpost`;

    const res = await axios.get(url);
    const data = PostResponseSchema.parse(res.data);
    return data;
}

export async function getAllUserPosts(axios: AxiosInstance, userId: number) {
    const res = await axios.get('/api/postuser/' + userId);
    const data = PostResponseSchema.parse(res.data);
    return data;
}

export async function getPost(axios: AxiosInstance, postId: number) {
    const res = await axios.get('/api/post/' + postId);
    const data = PostResponseSchema.parse(res.data);
    return data;
}

export async function getPostComments(axios: AxiosInstance, postId: number) {
    const res = await axios.get('/api/comentario/post/' + postId);
    const data = PostResponseSchema.parse(res.data);
    return data;
}

export async function curtirPost(axios: AxiosInstance, postId: number) {
    const res = await axios.post(`/api/curtir/post/${postId}`);
    return res.data;
}

type PublicarPostArgs = {
    comunidade: number,
    post: string,
};

export const PublicarPostSchema = z.object({
    Status: z.boolean(),
    Mensagem: z.string(),
    Post: z.string()
})

export async function publicarPost(axios: AxiosInstance, args: PublicarPostArgs) {
    const res = await axios.post('/api/post', args);
    const data = PublicarPostSchema.parse(res.data);
    return data;
}

export const PublishPostCommentResponseSchema = z.object({
    Mensagem: z.string(),
    Post: z.string(),
    Status: z.boolean(),
});

export async function publicarComentarioDoPost(axios: AxiosInstance, postId: number, texto: string) {
    const res = await axios.post('/api/comentario/post/' + postId, {
        post: texto,
    });
    const data = PublishPostCommentResponseSchema.parse(res.data);
    return data;
};

export async function registrarCompartilhamentoPost(axios: AxiosInstance, postId: number) {
    const res = await axios.post(`/api/compartilhar/post/${postId}`);
    return res.data;
}

const ChecarCurtidaPostSchema = z.object({ 
    Status: z.boolean(), 
    Curtiu: z.boolean(), 
});

/** Checa se um post foi curtido pelo usuário logado */
export async function checarCurtidaPost(axios: AxiosInstance, postId: number) {
    const res = await axios.post(`/api/curtido/post/${postId}`);
    const data = ChecarCurtidaPostSchema.parse(res.data);
    return data;
}