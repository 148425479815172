import React, { useContext, useEffect, useMemo, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";
import { StyleSheet, View } from "react-native";
import { Button, Divider, IconButton, Text } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { MenuRodape } from "../../components/MenuRodape";
import { NavBar } from "../../components/NavBar";
import { themeStyles } from "../../lib/theme";
import { ComunidadeCard } from "./ComunidadeCard";
import EscudoIcon from '../../assets/icons/escudo.svg';
import ChapeuMilitarIcon from '../../assets/icons/chapeu-militar.svg';
import HeartIcon from '../../assets/icons/heart.svg';
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { CampoBusca } from "./CampoBusca";
import { ComunidadesParamList } from "./Navigator";
import { Comunidade, COMUNIDADES } from "./comunidadesMockadas";
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { ComunidadePostCard } from "./ComunidadePost";
import { PostCard } from "../../components/Post";
import { PostTextArea } from "../../components/PostTextArea";
import { useUserStore } from "../../stores/userStore";
import { AxiosContext } from "../../stores/AxiosContext";
import { ComunidadePost, listarPostsDaComunidade, publicarPostDaComunidade } from "../../lib/services/comunidades";
import { goBack, showAlert } from "../../lib/utils/platform";
import { LoadingScreen } from "../../components/LoadingScreen";

type Props = NativeStackScreenProps<DrawerStackParamList, 'ComunidadeScreen'>;

export function ComunidadeScreen({ navigation, route }: Props) {
    const { comunidadeId } = route.params;
    
    const [busca, setBusca] = useState('');
    const [posts, setPosts] = useState<ComunidadePost[]>([]);
    const [mostrarTextArea, setMostrarTextArea] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const usuarioNome = useUserStore(state => state.infos?.nome);
    const { authAxios } = useContext(AxiosContext);

    const postsFiltrados = useMemo(() => {
        if (posts.length === 0) return;

        const buscaMinusculo = busca.toLowerCase();
        return posts.filter(p => {
            return p.post
                .toLowerCase()
                .includes(buscaMinusculo)
        });
    }, [posts, busca]);

    const openPostScreen = (postId: number) => {
        navigation.navigate('ComunidadePostScreen', {
            comunidadeId,
            postId,
        });
    };

    const handlePostarPress = () => {
        setMostrarTextArea(!mostrarTextArea);
    };

    const buscarPosts = async () => {
        setIsLoading(true);
        try {
            const data = await listarPostsDaComunidade(authAxios, comunidadeId);
            setPosts(data.Posts);
        } catch (err) {
            console.error(err);
            showAlert('Erro', 'Não foi possível carregar os post, tente novamente mais tarde.');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePostSubmit = async (texto: string) => {
        setIsLoading(true);
        try {
            setPosts([]);
            await publicarPostDaComunidade(authAxios, comunidadeId, texto);
            await buscarPosts();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        buscarPosts();
    }, [route]);

    if (isLoading) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <SafeAreaView style={themeStyles.background}>
            <NavBar />

            <View style={themeStyles.contentContainer}>
                <FlashList
                    data={postsFiltrados}
                    estimatedItemSize={100}
                    contentContainerStyle={themeStyles.listContentContainer}
                    keyboardShouldPersistTaps='handled'
                    ListHeaderComponent={(
                        <View style={styles.listHeader}>
                            <View style={styles.headerNavbar}>
                                <IconButton
                                    onPress={() => goBack(navigation)}
                                    style={{ transform: [{ rotate: '90deg' }] }}
                                    icon={() => (
                                        <SetaBaixoIcon
                                            width={25}
                                            height={25}
                                            fill="white"
                                        />
                                    )}
                                />

                                <Text style={styles.headerTitle}>
                                    Comunidade
                                </Text>

                                <View style={{ width: 40 }} />
                            </View>
                            
                            <View style={styles.comunidadeButtons}>
                                <Button
                                    mode="contained"
                                    textColor="white"
                                    icon="plus"
                                    contentStyle={{ flexDirection: 'row-reverse' }}
                                    onPress={handlePostarPress}
                                >
                                    POSTAR
                                </Button>

                                <CampoBusca 
                                    value={busca}
                                    onChangeText={setBusca}
                                />
                            </View>

                            {(mostrarTextArea) && (
                                <PostTextArea 
                                    nome={usuarioNome ?? ''}
                                    onSubmit={handlePostSubmit}
                                />
                            )}
                        </View>
                    )}
                    renderItem={({ item }) => (
                        <ComunidadePostCard
                            post={item}
                            comunidadeId={comunidadeId}
                            onPress={() => openPostScreen(item.id)}
                        />
                    )}
                    ListEmptyComponent={(
                        <View style={{ alignItems: 'center' }}>
                            {(!isLoading) && (
                                <Text style={styles.emptyListText}>
                                    Nenhum post encontrado
                                </Text>
                            )}
                        </View>
                    )}
                />
            </View>

            <MenuRodape />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    listHeader: {        
        alignItems: 'center',
        marginBottom: 20,
    },

    headerNavbar: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center' ,
        
        width: '100%',
        paddingVertical: 10,
    },

    headerTitle: {
        fontSize: 23,
    },

    comunidadeButtons: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        width: '90%',
        maxWidth: 400,
    },

    comunidadeWrapper: {
        alignItems: 'center',
        // padding: 20,
    },

    comunidadeConteudo: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        height: '100%',
        paddingLeft: 20,
    },

    comunidadeTexto: {
        marginHorizontal: 20,
        fontSize: 18,
    },

    emptyListText: {
        fontSize: 22,
        marginTop: 40,
    },
});
