import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TextInput as RNTextInput } from 'react-native';
import { Button, Modal, TextInput, Text } from "react-native-paper";
import { paperTheme, themeStyles } from "../../lib/theme";

type Props = {
    visible: boolean,
    initialState: { empresa: string, cargo: string },
    onDismiss?: () => void,
    onSubmit?: (empresa: string, cargo: string) => void,
};

export function CargoModal(props: Props) {
    const [cargo, setCargo] = useState('');
    const [empresa, setEmpresa] = useState('');
    
    const segundoTextInput = useRef<RNTextInput>(null);

    const handleSubmit = () => {
        if (props.onSubmit == null) return;

        props.onSubmit(empresa, cargo);
    };

    useEffect(() => {
        setEmpresa(props.initialState.empresa);
        setCargo(props.initialState.cargo);
    }, [props.initialState]);

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <TextInput
                label="Onde você trabalha?"
                value={empresa}
                onChangeText={setEmpresa}
                onSubmitEditing={() => segundoTextInput.current?.focus()}
                returnKeyType="next"
                style={styles.textInput}
                multiline
                blurOnSubmit
            />

            <TextInput
                ref={segundoTextInput}
                label="Qual o seu cargo?"
                value={cargo}
                onChangeText={setCargo}
                onSubmitEditing={handleSubmit}
                style={styles.textInput}
                multiline
                blurOnSubmit
            />

            <Button
                mode="contained"
                textColor="white"
                onPress={handleSubmit}
                
            >
                Salvar
            </Button>
        </Modal>
    );
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',

        height: 220,
        width: '95%',
        maxWidth: 400,
        borderRadius: 10,
        paddingHorizontal: 10,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInput: {
        width: '90%',
        backgroundColor: paperTheme.colors.background,
    },
});