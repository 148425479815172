import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FlashList } from "@shopify/flash-list";
import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Platform, SafeAreaView, StyleSheet, TextInput, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Button, Snackbar, Text } from 'react-native-paper';
import { IconButton } from "react-native-paper";
import { Rating } from "react-native-ratings-movilizame";
import * as Clipboard from 'expo-clipboard';
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { Avatar } from "../../components/Avatar";
import { paperTheme, themeStyles } from "../../lib/theme";
import { goBack, showAlert } from "../../lib/utils/platform";
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { Mensagem } from "./Mensagem";
import { MensagemDados, MENSAGENS_MOCKADAS } from "./mensagensMockadas";
import { useFocusEffect } from "@react-navigation/native";
import { ChatIndicadorDeAtividade } from "./ChatIndicadorDeAtividade";
import { Avaliacao } from "../../components/Avaliacao";
import { isEnvDev } from "../../lib/env";

type Props = NativeStackScreenProps<DrawerStackParamList, 'MessengerChat'>;

// const mensagensIniciais = isEnvDev() ?  MENSAGENS_MOCKADAS : [];
const mensagensIniciais: MensagemDados[] = [];

export function MessengerChatScreen({ navigation, route }: Props) {
    const [novaMensagem, setNovaMensagem] = useState('');
    const [mensagens, setMensagens] = useState(mensagensIniciais);
    const [mostrarSnackSucesso, setMostrarSnackSucesso] = useState(false);
    const [estaDigitando, setEstaDigitando] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const listRef = useRef<FlashList<any>>(null);

    const voltarParaBaixo = () => {
        // voltar pra ultima mensagem
        listRef.current?.scrollToIndex({
            animated: false,
            index: 0,
            viewOffset: 100,
        });
    };

    const enviarMensagem = () => {
        const msg = novaMensagem.trim();
        if (msg.length === 0) return;
        if (msg.length > 240) {
            showAlert(
                'Atenção',
                `A mensagem não deve ultrapassar o limite de ${LIMITE_CARACTERES} caracteres.`,
            );
            return;
        }

        const mensagem: MensagemDados = {
            autor: 'eu',
            mensagemTipo: 'texto',
            conteudo: msg,
        };

        setMensagens([mensagem, ...mensagens]);
        setNovaMensagem('');

        setEstaDigitando(false);
        if (timer != null) {
            clearTimeout(timer);
        }

        // voltar pra ultima mensagem
        voltarParaBaixo();
    };

    const handleMensagemPress = async (mensagem: MensagemDados) => {
        try {
            // copiar mensagem
            await Clipboard.setStringAsync(mensagem.conteudo);
            setMostrarSnackSucesso(true);
        } catch (err) {
            console.error(err);
        }
    };

    // detectar se o usuário está digitando ou parou de digitar
    const handleNovaMensagemChange = (value: string) => {
        setNovaMensagem(value)

        if (timer != null) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            setEstaDigitando(false);
        }, 1500);

        setTimer(newTimer);
        setEstaDigitando(true);
    }

    // gambiarra
    // por padrão, a direção do scroll fica invertida no web, esse useEffect arruma isso
    useEffect(() => {
        if (Platform.OS !== 'web') {
            return;
        }

        const scrollNode: any = listRef.current?.getScrollableNode();
        if (!scrollNode) {
            return;
        }

        const listener = scrollNode.addEventListener('wheel', (e: any) => {
            scrollNode.scrollTop -= e.deltaY * 3;
            e.preventDefault();
        });

        return () => scrollNode.removeEventListener('wheel', listener);
    }, []);

    // adicionar mensagem passada como argumento pra rota
    useFocusEffect(
        useCallback(() => {
            const novaMensagem = route?.params?.novaMensagem;
            const mensagemVazia = novaMensagem?.trim().length === 0;
            if (novaMensagem == null || mensagemVazia) return;

            const mensagem: MensagemDados = {
                autor: 'eu',
                mensagemTipo: 'texto',
                conteudo: novaMensagem,
            };

            setMensagens([mensagem, ...mensagens]);
        }, [route])
    );

    return (
        <SafeAreaView style={styles.container}>
            <View style={{ position: 'absolute', zIndex: 40 }}>
                <IconButton
                    iconColor="white"
                    size={navbarSvgSize}
                    style={styles.goBack}
                    onPress={() => goBack(navigation)}
                    icon={() => (
                        <SetaBaixoIcon
                            width={navbarSvgSize}
                            height={navbarSvgSize}
                            fill="white"
                        />
                    )}
                />
            </View>

            <View style={styles.navbarWrapper}>
                <View style={styles.navbar}>
                    <View style={styles.chatHeader}>
                        <Avatar
                            imagem={{ uri: '' }}
                            avatarSize={100}
                        />

                        <Text style={styles.pessoaNome}>
                            {isEnvDev() ? 'João Kleber' : ''}
                        </Text>

                        <Avaliacao
                            nota={5}
                            corIcone={paperTheme.colors.primary}
                            style={[{ paddingHorizontal: 10, margin: 0 }]}
                            tamanho={30}
                            readonly
                        />

                        <LinearGradient
                            colors={['#ff0c5e', 'transparent']}
                            locations={[0.0, 1.0]}
                            start={[0, 1]}
                            end={[1, 0]}
                            style={styles.gradientLine}
                        />
                    </View>
                </View>
            </View>

            <FlashList
                ref={listRef}
                data={mensagens}
                keyExtractor={(_item, index) => index.toString()}
                estimatedItemSize={70}
                renderItem={({ item }) => (
                    <TouchableOpacity onLongPress={() => handleMensagemPress(item)}>
                        <View style={styles.messageWrapper}>
                            <Mensagem
                                autor={item.autor}
                                conteudo={item.conteudo}
                                avatar={avatar}
                            />
                        </View>
                    </TouchableOpacity>
                )}
                ListHeaderComponent={(
                    <>
                        <ChatIndicadorDeAtividade mostrar={estaDigitando} />
                    </>
                )}
                ListHeaderComponentStyle={{ alignItems: 'center' }}
                contentContainerStyle={{ paddingBottom: 30, paddingTop: 80 }}
                inverted // lista começa de baixo pra cima
            />

            {(mensagens.length === 0 && !estaDigitando) && (
                <View style={{ alignItems: 'center', marginBottom: 100 }}>
                    <Text style={{ fontSize: 18 }}>
                        Nenhuma mensagem por enquanto.
                    </Text>
                </View>
            )}

            <View style={styles.inputAreaWrapper}>
                <View style={styles.inputArea}>
                    <TextInput
                        value={novaMensagem}
                        onChangeText={handleNovaMensagemChange}
                        onSubmitEditing={enviarMensagem}
                        placeholder="Escreva uma mensagem"
                        cursorColor={paperTheme.colors.primary}
                        blurOnSubmit={false}
                        style={styles.textInput}
                    />

                    <Button onPress={enviarMensagem}>
                        <Text style={styles.enviarBtnText}>
                            Enviar
                        </Text>
                    </Button>
                </View>
            </View>

            <Snackbar
                visible={mostrarSnackSucesso}
                onDismiss={() => setMostrarSnackSucesso(false)}
                action={{
                    label: 'Ok',
                    onPress: () => { },
                    labelStyle: { color: paperTheme.colors.primary }
                }}
                style={{ maxWidth: 400, marginBottom: 80, backgroundColor: 'white' }}
                wrapperStyle={{ width: '100%', alignItems: 'center' }}
            >
                Link copiado com sucesso
            </Snackbar>
        </SafeAreaView>
    );
}

const navbarSvgSize = 25;
const avatar = 'https://i.imgur.com/gg33WcW.jpg';

const LIMITE_CARACTERES = 400;

const styles = StyleSheet.create({
    goBack: {
        transform: [{ rotate: '90deg' }],
        left: 10,
        top: 10,
    },

    container: {
        backgroundColor: paperTheme.colors.background,
        flex: 1,
    },

    navbarWrapper: {
        flexDirection: 'column',

        // backgroundColor: paperTheme.colors.darkBackground,
        // borderBottomColor: '#ff0058',
        // borderBottomWidth: 2,

        //position: 'absolute',
        zIndex: 30,
        width: '100%',
    },

    navbar: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',

        // height: 60,
    },

    screenTitle: {
        fontSize: 20,

        marginLeft: 10,
    },

    chatHeader: {
        alignItems: 'center',

        // paddingTop: 10,
        // top: 150,
        marginTop: 40,
        width: '100%',
    },

    pessoaNome: {
        fontSize: 28,
        fontWeight: '700',

        marginTop: 10,
    },

    gradientLine: {
        width: '90%',
        height: 2,
        marginTop: 20,
    },

    messageWrapper: {
        alignItems: 'center',
    },

    inputArea: {
        flexDirection: 'row',
        alignItems: 'center',

        height: 60,
        width: '90%',
        maxWidth: 500,
        marginBottom: 10,

        backgroundColor: '#030303',
        borderRadius: 30,
        paddingRight: 20,
    },

    inputAreaWrapper: {
        alignItems: 'center',
        position: 'absolute',

        bottom: 0,
        width: '100%',
    },

    textInput: {
        flex: 1,
        height: '100%',

        fontSize: 16,
        color: 'white',
        paddingLeft: 20,
    },

    enviarBtnText: {
        fontWeight: '500',
        fontSize: 17,
        color: 'white',
    },
});