import { z } from "zod"
import axios, { AxiosInstance } from "axios";
import { baseURL } from "../../stores/AxiosContext";

export const LoginResponseSchema = z.object({
    Status: z.boolean(),
    token: z.string(),
});

export async function requestLogin(email: string, password: string) {
    const res = await axios.post(
        baseURL + 'api/login',
        {
            email,
            password,
        }
    );

    const data = LoginResponseSchema.parse(res.data);
    return data;
}

export const UserInfosSchema = z.object({
    id: z.number(),
    nome: z.string(),
    nome_social: z.string().nullable(),
    empresa: z.string().nullable(),
    cidade: z.string().nullable(),
    estado: z.string().nullable(),
    descricao: z.string().nullable(),
    procura: z.string().nullable(),
    superPoder: z.string().nullable(),
    foto: z.string().nullable(),
    Status: z.boolean().optional(),
});

export type UserInfos = z.infer<typeof UserInfosSchema>;

export async function getUserInfos(axios: AxiosInstance, userId?: string) {
    const url = (userId == null)
        ? '/api/usuario'
        : `/api/usuario/${userId}`;

    const res = await axios.get(url);
    const data = UserInfosSchema.parse(res.data);
    return data;
}

export type AvaliarUsuarioArgs = {
    userId: number,
    confiavel: number,
    prestativo: number,
    atraente: number,
};

export const AvaliarUsuarioResponstaSchema = z.object({
    Status: z.boolean(),
    Mensagem: z.string(),
    Avaliacao: z.object({
        user: z.number(),
        user_avaliador: z.string(),
        confiavel: z.number(),
        prestativo: z.number(),
        atraente: z.number(),
    }),
});

export async function avaliarUsuario(axios: AxiosInstance, args: AvaliarUsuarioArgs) {
    const res = await axios.post('/api/avaliar/usuario', args);
    const data = AvaliarUsuarioResponstaSchema.parse(res.data);
    return data;
}

export const AvaliacaoRespostaSchema = z.object({
    confiavel: z.string().nullable(),
    prestativo: z.string().nullable(),
    atraente: z.string().nullable(),
    media: z.number(),
    Status: z.boolean()
});

export type AvaliacaoUsuario = {
    confiavel: number,
    prestativo: number,
    atraente: number,
};

export async function buscarAvaliacaoDeUsuario(
    axios: AxiosInstance,
    userId?: number,
    abortController?: AbortController,
) {
    const url = (userId != null)
        ? `/api/avaliacao/usuario/${userId}`
        : `/api/minhaavaliacao`;

    const res = await axios.get(url, { signal: abortController?.signal });
    const data = AvaliacaoRespostaSchema.parse(res.data);
    return data;
}

export interface AtualizarDadosDoUsuarioArgs {
    nome: string
    nome_social: string
    empresa: string
    cidade: string
    estado: string
    descricao: string
    procura: string
    superPoder: string
}

export async function atualizarDadosDoUsuario(axios: AxiosInstance, args: AtualizarDadosDoUsuarioArgs) {
    const res = await axios.post('/api/update/usuario', args);
    return res.data;
}

type DenunciarUsuarioArgs = {
    id: number,
    motivo: string,
}

export async function denunciarUsuario(axios: AxiosInstance, args: DenunciarUsuarioArgs) {
    const res = await axios.post('/api/denuncia/usuario', args);
    return res.data;
}

export async function excluirPost(axios: AxiosInstance, id: number) {
    const res = await axios.post(`/api/remover/post/${id}`);
    return res.data;
}

const PesquisarUsuarioSchema = z.array(UserInfosSchema);

export async function pesquisarUsuario(axios: AxiosInstance, query: string) {
    const res = await axios.post('/api/busca/usuario', {
        procurar: query,
    });

    const data = PesquisarUsuarioSchema.parse(res.data);
    return data;
}

export const CriarContaResSchema = z.object({
    name: z.string(),
    cpf: z.string(),
    email: z.string(),
    password: z.string(),
    Status: z.boolean()
});

export type CriarContaArgs = {
    nome: string
    cpf: string
    email: string
    senha: string
}

export async function criarContaUsuario(axios: AxiosInstance, args: CriarContaArgs) {
    const res = await axios.post('/api/registra/usuario', args);
    const data = CriarContaResSchema.parse(res.data);
    return data;
}

export const AvaliacaoUsuarioComIdSchema = z.object({
    user_avaliador: z.number(),
    confiavel: z.number().nullable(),
    prestativo: z.number().nullable(),
    atraente: z.number().nullable(),
});

export type AvaliacaoUsuarioComId = z.infer<typeof AvaliacaoUsuarioComIdSchema>;

export const QuemMeAvaliouResSchema = z.object({
    Status: z.boolean(),
    Mensagem: z.string(),
    Avaliacao: z.array(AvaliacaoUsuarioComIdSchema),
});

export async function listarQuemAvaliou(axios: AxiosInstance) {
    const res = await axios.get('/api/quemavaliou');
    return QuemMeAvaliouResSchema.parse(res.data);
}