import React, { useContext, useEffect, useState } from 'react';
import { Image, Platform, Share, StyleSheet, View } from 'react-native';
import { IconButton, Menu, Text } from 'react-native-paper';
import { ComunidadeCard } from './ComunidadeCard';
import HeartIcon from '../../assets/icons/heart.svg';
import HeartOutlineIcon from '../../assets/icons/heart-outline.svg';
import CommentIcon from '../../assets/icons/comment.svg';
import ShareIcon from '../../assets/icons/share.svg';
import { paperTheme } from '../../lib/theme';
import { checarCurtidaPostComunidade, compartilharPostDaComunidade, ComunidadePost, curtirPostDaComunidade, excluirPostComunidade } from '../../lib/services/comunidades';
import { Avatar } from '../../components/Avatar';
import { AxiosContext, websiteUrl } from '../../stores/AxiosContext';
import { useUserStore } from '../../stores/userStore';
import { useNavigation } from '@react-navigation/native';
import { showConfirm, suportaCompartilhamento } from '../../lib/utils/platform';
import { useAppStore } from '../../stores/appStore';

type Props = {
    post: ComunidadePost,
    comunidadeId: number,
    hideShareButton?: boolean,
    hideCommentButton?: boolean,
    hideLikeButton?: boolean,
    onPress?: () => void,
    onCommentPress?: () => void,
};

export function ComunidadePostCard(props: Props) {
    const [temLike, setTemLike] = useState(props.post.curtido?.toString() === '1');
    const [likes, setLikes] = useState(props.post.curtidas);
    const [comentarios, setComentarios] = useState(props.post.comentarios);
    const [compartilhamento, setCompartilhamento] = useState(props.post.compartilhamento);
    const [isLoadingLike, setIsLoadingLike] = useState(false);
    const [mostrarMenu, setMostrarMenu] = useState(false);

    const { authAxios } = useContext(AxiosContext);
    const userInfos = useUserStore(state => state.infos);
    const navigation = useNavigation();
    const appStore = useAppStore();

    let avatar = undefined;
    if (props.post.foto != null && props.post.foto.length > 0) {
        avatar = {
            uri: props.post.foto,
        };
    }

    const isProprioPost = props.post.user_id === userInfos?.id.toString();

    const likeButtonStyle = (isLoadingLike)
        ? styles.disabledIconButton
        : undefined;

    const fetchLike = async () => {
        setIsLoadingLike(true);
        try {
            const data = await checarCurtidaPostComunidade(authAxios, props.post.id);
            setTemLike(data.Curtiu);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingLike(false);
        }
    };

    const handleHeartPress = async () => {
        const novoTemLike = !temLike;
        setTemLike(novoTemLike);

        if (novoTemLike) {
            setLikes(props.post.curtidas + 1);
        } else {
            setLikes(props.post.curtidas);
        }

        setIsLoadingLike(true);
        try {
            await curtirPostDaComunidade(authAxios, props.post.id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingLike(false);
        }
    };

    const handleSharePress = async () => {
        try {
            const postId = props.post.id;
            const postUrl = `${websiteUrl}Comunidade/${postId}/Post/${postId}`;

            try {
                await Share.share({
                    title: `Compartilhar post de ${props.post.usuario ?? ''}`,
                    url: postUrl,
                });
            } catch (err: unknown) {
                if (!(err instanceof Error)) return;

                if (Platform.OS === 'web' && err.message.includes('Share is not supported')) {
                    // plataforma não suporta compartilhamento nativo, usar fallback
                    appStore.update({ showShareModal: true });
                }
            }

            await compartilharPostDaComunidade(authAxios, postId);
        } catch (err) {
            console.error(err);
        }
    };

    const handleAvatarPress = () => {
        const userId = userInfos?.id;
        const postUserId = props.post.user_id;

        if (postUserId == null) {
            console.log({ userId, postUserId });
            return;
        } else if (userId?.toString() === postUserId) {
            console.log({ userId, postUserId });
            // usuário do post é o mesmo usuário logado
            navigation.navigate('MyProfileScreen' as any, {});
        } else {
            console.log({ userId, postUserId });
            navigation.navigate('ProfileScreen' as any, {
                userId: postUserId,
            });
        }
    };

    const handleExcluirPost = async () => {
        try {
            const confirmacao = await showConfirm(
                'Atenção',
                'Tem certeza que você quer excluir esse post?',
            );

            setMostrarMenu(false);
            if (confirmacao) {
                await excluirPostComunidade(authAxios, props.post.id);
            }

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchLike();
    }, []);

    return (
        <View style={styles.comunidadeWrapper}>
            <ComunidadeCard
                style={styles.comunidade}
                onPress={props.onPress}
            >
                <View style={styles.comunidadeConteudo}>
                    <View>
                        <Avatar
                            imagem={avatar}
                            avatarSize={60}
                            style={{ marginTop: 10 }}
                            onPress={handleAvatarPress}
                        />
                    </View>

                    <View style={{ marginHorizontal: 10, width: '80%', }}>
                        <View style={styles.userNameLine}>
                            <Text
                                style={styles.usuarioNomeTexto}
                                onPress={handleAvatarPress}
                            >
                                {props.post.usuario}
                            </Text>

                            <Menu
                                visible={mostrarMenu}
                                onDismiss={() => setMostrarMenu(false)}
                                anchor={
                                    <IconButton
                                        icon="dots-vertical"
                                        onPress={() => setMostrarMenu(true)}
                                    />
                                }
                            >
                                <Menu.Item
                                    onPress={handleExcluirPost}
                                    title="Excluir post"
                                    disabled={!isProprioPost}
                                />
                            </Menu>
                        </View>

                        <Text
                            style={styles.comunidadeTexto}
                            android_hyphenationFrequency="full"
                            selectable
                        >
                            {props.post.post}
                        </Text>
                    </View>
                </View>
            </ComunidadeCard>

            <View style={styles.buttons}>
                {(!props.hideLikeButton) && (
                    <View style={styles.button}>
                        <IconButton
                            iconColor="white"
                            size={iconButtonSize}
                            onPress={handleHeartPress}
                            disabled={isLoadingLike}
                            style={likeButtonStyle}
                            icon={() => {
                                if (temLike)
                                    return (
                                        <HeartIcon
                                            width={svgSize}
                                            height={svgSize}
                                            fill={paperTheme.colors.primary}
                                        />
                                    )
                                else
                                    return (
                                        <HeartOutlineIcon
                                            width={svgSize}
                                            height={svgSize}
                                            fill="white"
                                        />
                                    )
                            }}
                        />

                        <Text style={styles.buttonText}>
                            {likes}
                        </Text>
                    </View>
                )}

                {(!props.hideCommentButton) && (
                    <View style={[styles.button]}>
                        <IconButton
                            iconColor="white"
                            size={iconButtonSize}
                            onPress={props.onCommentPress ?? props.onPress}
                            icon={() => (
                                <CommentIcon
                                    width={comentSvgSize}
                                    height={comentSvgSize}
                                    fill="white"
                                />
                            )}
                        />

                        <Text style={styles.buttonText}>
                            {comentarios}
                        </Text>
                    </View>
                )}

                {(!props.hideShareButton) && (
                    <View style={styles.button}>
                        <IconButton
                            iconColor="white"
                            size={iconButtonSize}
                            onPress={handleSharePress}
                            icon={() => (
                                <ShareIcon
                                    width={svgSize}
                                    height={svgSize}
                                    fill="white"
                                />
                            )}
                        />

                        <Text style={styles.buttonText}>
                            {compartilhamento}
                        </Text>
                    </View>
                )}
            </View>
        </View>
    );
}

const iconButtonSize = 30;
const svgSize = 28;
const comentSvgSize = svgSize + 5;

const styles = StyleSheet.create({
    comunidade: {
        alignItems: 'flex-start',
        justifyContent: 'center',

        height: undefined,
        width: 340,
        minHeight: 85,
        marginBottom: 3,
        paddingBottom: 7,
    },

    comunidadeWrapper: {
        alignItems: 'center',
        // padding: 20,
    },

    comunidadeConteudo: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',

        height: '100%',
        paddingHorizontal: 15,
    },

    comunidadeTexto: {
        fontSize: 18,
    },

    usuarioNomeTexto: {
        fontSize: 22,
    },

    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',

        width: '90%',
        maxWidth: 300,
        left: -10,
    },

    button: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        //marginHorizontal: 2,
    },

    buttonText: {
        fontSize: 18,
        margin: 0,
    },

    disabledIconButton: {
        opacity: 0.6,
    },

    userNameLine: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        width: '100%',
    },
});