import { useContext, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Modal, Text, TextInput } from "react-native-paper";
import { denunciarUsuario } from "../lib/services/user";
import { paperTheme, themeStyles } from "../lib/theme";
import { showAlert } from "../lib/utils/platform";
import { AxiosContext } from "../stores/AxiosContext";

type Props = {
    visible: boolean,
    // usuario que será denunciado
    userId?: number,
    onDismiss?: () => void,
    onSubmit?: () => void,
}

export function DenunciaModal(props: Props) {
    const [texto, setTexto] = useState('');

    const { authAxios } = useContext(AxiosContext);

    const enviarDenuncia = async () => {
        if (props.userId == null) return;

        try {
            await denunciarUsuario(authAxios, {
                id: props.userId,
                motivo: texto,
            });
        } catch (err) {
            console.error(err);
        }
    };

    const handleDismiss = () => {
        setTexto('');
        if (props.onDismiss != null) {
            props.onDismiss();
        }
    };

    const handleSubmit = () => {
        if (texto.length < 5) {
            showAlert('Atenção', 'O motivo da denúncia deve ter pelo menos 5 caracteres');
            return;
        }

        enviarDenuncia();
        setTexto('');
        
        if (props.onSubmit != null) {
            props.onSubmit();
        }
    };

    return (
        <Modal
            visible={props.visible}
            onDismiss={handleDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <View style={styles.conteudo}>
                <Text style={styles.texto}>
                    Qual o motivo da denúncia?
                </Text>

                <TextInput
                    value={texto}
                    onChangeText={setTexto}
                    style={styles.textInput}
                    multiline
                />
            </View>


            <View style={{ alignItems: 'center', marginTop: 20 }}>
                <Button
                    mode="contained"
                    textColor="white"
                    buttonColor={paperTheme.colors.background}
                    onPress={handleSubmit}
                >
                    Denunciar
                </Button>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',

        height: 200,
        width: '90%',
        maxWidth: 350,
        borderRadius: 10,
    },

    conteudo: {
        alignItems: 'center',

        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInput: {
        width: '90%',
        marginTop: 20,
    },
});