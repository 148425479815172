import React, { PropsWithChildren } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { IconButton, Surface } from 'react-native-paper';
import { Shadow } from 'react-native-shadow-2';
import { useNavigation } from '@react-navigation/native';
import { paperTheme } from '../../lib/theme';
import RaioConnectarIcon from '../../assets/icons/raio_connectar.svg';
import HeartIcon from '../../assets/icons/heart.svg';
import GostarIcon from '../../assets/icons/gostar.svg';
import DesgostarIcon from '../../assets/icons/desgostar.svg';
import ComunidadeIcon from '../../assets/icons/comunidade.svg';
import ReturnIcon from '../../assets/icons/retorna.svg';

export type Props = {
    onGoBack?: () => void,
    onLike?: () => void,
    onDislike?: () => void,
    onLove?: () => void,
};

export function CredLoversRodape(props: Props) {
    return (
        <View style={styles.container}>
            <View style={styles.barra}>
                <IconButton
                    containerColor="#ffef1b"
                    iconColor={paperTheme.colors.background}
                    size={40}
                    style={styles.button}
                    onPress={props.onGoBack}
                    icon={() => (
                        <ReturnIcon
                            width={svgSize}
                            height={svgSize}
                            fill={paperTheme.colors.background}
                        />
                    )}
                />

                <IconButton
                    containerColor={paperTheme.colors.surface}
                    iconColor="white"
                    size={50}
                    style={styles.bigButton}
                    onPress={props.onDislike}
                    icon={() => (
                        <DesgostarIcon
                            width={bigSvgSize}
                            height={bigSvgSize}
                            fill="white"
                        />
                    )}
                />

                <IconButton
                    containerColor={paperTheme.colors.surface}
                    iconColor="white"
                    size={50}
                    style={styles.bigButton}
                    onPress={props.onLike}
                    icon={() => (
                        <GostarIcon
                            width={bigSvgSize}
                            height={bigSvgSize}
                            fill="white"
                        />
                    )}
                />

                <IconButton
                    containerColor={paperTheme.colors.primary}
                    size={iconButtonSize}
                    style={styles.button}
                    onPress={props.onLove}
                    icon={() => (
                        <RaioConnectarIcon
                            width={svgSize}
                            height={svgSize}
                            fill={paperTheme.colors.background}
                        />
                    )}
                />
            </View>
        </View>
    );
}

function ButtonShadow(props: PropsWithChildren) {
    return (
        <Shadow
            startColor={'rgba(24, 24, 24, 0.7)'}
            endColor={'rgba(39, 39, 39, 0.1)'}
        >
            {props.children}
        </Shadow>
    );
}

const bigSvgSize = 40;
const svgSize = 32;
const iconButtonSize = 40;

const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignItems: 'center',
    },

    barra: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',

        position: 'absolute',
        bottom: 0,
        width: '100%',
        maxWidth: 600,
        paddingBottom: (Platform.OS === 'web') ? 40 : 20,
    },

    bigButton: {
        margin: 0,
        padding: 0,
        height: 80,
        width: 80,
        borderRadius: 100,
    },

    button: {
        height: 60,
        width: 60,
        margin: 0,
        borderRadius: 100,
    },
});