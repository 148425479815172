import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { UserInfos } from '../lib/services/user';

export type UserState = {
    token?: string,
    infos?: UserInfos,

    update: (newState: Partial<UserState>) => void,
    setInfos: (infos: UserInfos) => void,
    setToken: (newToken: string) => void,
    reset: () => void,
};

const initialState = {
    token: undefined,
    infos: undefined,
};

export const useUserStore = create<UserState>()(
    devtools(
        persist<UserState>(
            (set) => ({
                ...initialState,

                update: (newState: Partial<UserState>) => set({ ...newState }),
                setInfos: (infos) => set({ infos: infos }),
                setToken: (newToken: string) => set({ token: newToken }),
                reset: () => set({ ...initialState }),
            }),
            {
                name: 'posts-storage',
                getStorage: () =>
                    (Platform.OS === 'web')
                        ? localStorage
                        : AsyncStorage,
            },
        ),
    ),
);
