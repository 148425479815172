import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TextInput as RNTextInput } from 'react-native';
import { Button, Modal, TextInput, Text } from "react-native-paper";
import { paperTheme, themeStyles } from "../../lib/theme";

type Props = {
    visible: boolean,
    initialState: { cidade: string, estado: string }, 
    onDismiss?: () => void,
    onSubmit?: (cidade: string, estado: string) => void,
};

export function CidadeModal(props: Props) {
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    const segundoTextInput = useRef<RNTextInput>(null);

    const handleSubmit = () => {
        if (props.onSubmit == null) return;

        props.onSubmit(cidade, estado);
    };

    useEffect(() => {
        setCidade(props.initialState.cidade);
        setEstado(props.initialState.estado);
    }, [props.initialState]);

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <TextInput
                label="Em qual cidade você mora?"
                value={cidade}
                onChangeText={setCidade}
                onSubmitEditing={() => segundoTextInput.current?.focus()}
                returnKeyType="next"
                style={styles.textInput}
            />

            <TextInput
                ref={segundoTextInput}
                label="Em qual estado você mora?"
                value={estado}
                onChangeText={setEstado}
                onSubmitEditing={handleSubmit}
                style={styles.textInput}
            />

            <Button
                mode="contained"
                textColor="white"
                onPress={handleSubmit}
            >
                Salvar
            </Button>
        </Modal>
    );
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',

        height: 220,
        width: '95%',
        maxWidth: 400,
        borderRadius: 10,
        paddingHorizontal: 10,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInput: {
        width: '90%',
        backgroundColor: paperTheme.colors.background,
    },
});