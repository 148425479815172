import { View, StyleSheet } from "react-native";
import { Button, Modal, Text, TextInput } from "react-native-paper";
import { paperTheme, themeStyles } from "../lib/theme";

export type Props = {
    visible: boolean,
    onDismiss?: () => void,
    onSubmit?: () => void,
}

export function CompletarCadastroModal(props: Props) {
    const completarDados = () => {
        if (props.onSubmit != null) {
            props.onSubmit();
        }
    };

    const cancelar = () => {
        if (props.onDismiss != null) {
            props.onDismiss();
        }
    };

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <View style={styles.conteudo}>
                <Text style={styles.texto}>
                    Deseja completar seu cadastro?
                </Text>
            </View>


            <View style={styles.buttonLine}>
                <Button
                    mode="contained"
                    textColor="white"
                    buttonColor={paperTheme.colors.background}
                    onPress={cancelar}
                >
                    Mais tarde
                </Button>

                <Button
                    mode="contained"
                    textColor="white"
                    buttonColor={paperTheme.colors.primary}
                    onPress={completarDados}
                >
                    Sim
                </Button>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',

        height: 200,
        width: '90%',
        maxWidth: 350,
        borderRadius: 10,
    },

    conteudo: {
        alignItems: 'center',

        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInput: {
        width: '90%',
        marginTop: 20,
    },

    buttonLine: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        
        marginTop: 20,
        width: '60%', 
    },
});