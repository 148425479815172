import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, RefreshControl, TouchableOpacity } from "react-native";
import { Text, Provider as PaperProvider, Divider, ActivityIndicator } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";
import uuid from 'react-native-uuid';
import { Avatar } from "../components/Avatar";
import { NavBar, navbarStyles } from "../components/NavBar";
import { PostCard } from "../components/Post";
import { themeStyles, paperTheme } from "../lib/theme";
import { MenuRodape } from "../components/MenuRodape";
import useKeyboard from "../hooks/useKeyboard";
import { PostTextArea } from "../components/PostTextArea";
import { usePostStore } from "../stores/postStore";
import { pessoa1 } from "../stores/postsMockados";
import VerificadoIcon from '../assets/icons/verificado.svg';
import PredioIcon from '../assets/icons/predio.svg';
import HeartIcon from '../assets/icons/heart.svg';
import RaioIcon from '../assets/icons/raio.svg';
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { getAllPosts, getPost, getPostComments, Post, publicarComentarioDoPost, publicarPost } from "../lib/services/posts";
import { AxiosContext } from "../stores/AxiosContext";
import { useUserStore } from "../stores/userStore";
import { getUserInfos } from "../lib/services/user";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { DrawerStackParamList } from "../navigation/navigationTypes";
import { LoadingScreen } from "../components/LoadingScreen";
import { SubNavbar } from "../components/SubNavbar";

type Props = NativeStackScreenProps<DrawerStackParamList, 'PostScreen'>;

export function PostScreen({ navigation, route }: Props) {
    const { postId } = route.params;

    const [isLoading, setIsLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [postPai, setPostPai] = useState<Post | null>(null);
    const [comentarios, setComentarios] = useState<Post[]>([]);
    const [mostrarTextArea, setMostrarTextArea] = useState(false);

    const userStore = useUserStore();
    const isKeyboardVisible = useKeyboard();
    const { authAxios } = useContext(AxiosContext);

    const fetchPosts = async () => {
        setIsLoading(true);
        try {
            const [postPaiData, postCommentsData] = await Promise.all([
                getPost(authAxios, postId),
                getPostComments(authAxios, postId),
            ]);

            if (postPaiData.Status !== true) {
                console.log('status', postPaiData.Status);
                return;
            }
            if (postCommentsData.Status !== true) {
                console.log('status', postCommentsData.Status);
                return;
            }

            setPostPai(postPaiData.Posts[0]);
            setComentarios(postCommentsData.Posts);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
        try {
            await fetchPosts();
        } catch (err) {
            console.error(err);
        } finally {
            setRefreshing(false);
        }
    }, []);

    const adicionarPost = async (texto: string) => {
        setIsLoading(true);
        try {
            await publicarComentarioDoPost(authAxios, postId, texto.trim());
            await fetchPosts();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setPostPai(null);
        setComentarios([]);
        fetchPosts();
    }, [route]);

    if (isLoading) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <SafeAreaView style={themeStyles.background}>
            <NavBar />

            <View style={themeStyles.contentContainer}>
                <FlashList
                    data={comentarios}
                    estimatedItemSize={170}
                    contentContainerStyle={styles.container}
                    keyboardShouldPersistTaps='handled'
                    keyExtractor={(item) => item.id.toString()}
                    ListHeaderComponent={(
                        <View style={{ alignItems: 'center' }}>
                            <SubNavbar
                                title="Post"
                            />

                            {(postPai != null) && (
                                <View style={styles.postWrapper}>
                                    <PostCard
                                        post={postPai}
                                        onCommentPress={() => setMostrarTextArea(!mostrarTextArea)}
                                    />
                                </View>
                            )}

                            {(!isLoading && postPai != null && mostrarTextArea) && (
                                <PostTextArea
                                    nome={userStore.infos?.nome ?? ''}
                                    onSubmit={adicionarPost}
                                    style={styles.textArea}
                                />
                            )}

                            {(isLoading) && (
                                <View style={styles.loadingArea}>
                                    <ActivityIndicator
                                        color="white"
                                        size={50}
                                    />
                                </View>
                            )}
                        </View>
                    )}
                    renderItem={({ item }) => (
                        <View style={styles.postWrapper}>
                            <PostCard
                                post={item}
                                hideCommentButton
                                hideShareButton
                                hideLikeButton
                            />
                        </View>
                    )}
                    ItemSeparatorComponent={Divider}
                    ListEmptyComponent={(isLoading) ? (
                        <View style={{ alignItems: 'center' }}>
                        </View>
                    ) : (
                        <View style={{ alignItems: 'center' }}>
                            <Text style={styles.emptyListText}>
                                Nenhum comentário encontrado
                            </Text>
                        </View>
                    )}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                />
            </View>

            {(!isKeyboardVisible) && (
                <MenuRodape esconderBotaoHome />
            )}
        </SafeAreaView>
    );
}

const avatarSize = 80;
const infoSvgSize = 15;

const styles = StyleSheet.create({
    container: {
        paddingBottom: 130,
    },

    postWrapper: {
        alignItems: 'center',
        paddingVertical: 10,
    },

    emptyListText: {
        fontSize: 22,
    },

    avatar: {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,

        marginRight: 10,
        marginLeft: 5,
    },

    header: {
        //borderBottomColor: paperTheme.colors.primary,
        //borderBottomWidth: 1,
        paddingBottom: 15,
        maxWidth: 400,
        width: '100%',
    },

    headerWrapper: {
        alignItems: 'center',
        width: '100%',
        paddingTop: 10,
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginTop: 15,
    },

    headerLinha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    usuarioNome: {
        fontSize: 22,
        fontWeight: 'bold',

        margin: 0,
        marginRight: 5,
        marginBottom: 5,
    },

    userInfoLinha: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    userInfoTexto: {
        fontSize: 16,
        margin: 0,
        marginHorizontal: 5,
    },

    userInfoCargo: {
        color: '#868686',
        fontSize: 17,
        fontWeight: 'bold',
        marginLeft: 0,
    },

    usuarioNomeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    loadingArea: {
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 120,
    },

    textArea: {
        marginVertical: 20,
    },
});