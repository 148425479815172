import { MD3DarkTheme } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { DarkTheme as NavigationDarkTheme } from '@react-navigation/native';

export const appTitulo = 'Seo Crédito';

/** Definir cores do tema da aplicação */
export const paperTheme = {
    ...MD3DarkTheme,
    colors: {
        ...MD3DarkTheme.colors,
        primary: '#ff0c5e',
        secondary: 'white',
        background: '#272727',
        darkBackground: '#111111',
        surface: '#4c4c4c',
        activityIndicator: 'white',
    },
};

export const navigationTheme = {
    ...NavigationDarkTheme,
    colors: {
        ...NavigationDarkTheme.colors,
        primary: paperTheme.colors.primary,
    },
};

export const themeStyles = StyleSheet.create({
    background: {
        flex: 1,
        backgroundColor: paperTheme.colors.background,
    },

    surface: {
        backgroundColor: paperTheme.colors.surface,
    },

    surfaceImage: {
        tintColor: 'white',
    },

    contentContainer: {
        flex: 1,
        marginTop: 60,
    },

    contentContainerSemNavbar: {
        flex: 1,
        marginTop: 0,
    },

    listContentContainer: {
        // paddingTop: 60, // altura da navbar
        paddingBottom: 170, // altura da barra de baixo
    },

    whiteBackground: {
        backgroundColor: 'white',
    },

    primaryColorSurface: {
        backgroundColor: paperTheme.colors.primary,
    },

    secondaryColorSurface: {
        backgroundColor: paperTheme.colors.secondary,
    },
});

export const formStyles = StyleSheet.create({
    fieldAndHelperTextWrapper: {
        marginBottom: 10,
        alignItems: 'center',
        width: '100%',
    },

    textInput: {
        width: '70%',
        maxWidth: 300,
        backgroundColor: 'transparent',
    },

    textInputOutlined: {
        width: '70%',
        maxWidth: 300,
    },
});