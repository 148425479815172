import { LinearGradient } from 'expo-linear-gradient';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, ScrollView, Platform, Image, useWindowDimensions, ImageBackground, KeyboardAvoidingView } from 'react-native';
import { IconButton, Text, TextInput, DefaultTheme } from 'react-native-paper';
import Animated, { FadeIn, FadeOut, runOnJS, useAnimatedStyle, useSharedValue, withSequence, withSpring, withTiming } from 'react-native-reanimated';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import Svg, { Path } from 'react-native-svg';
import { useFonts } from 'expo-font';
import { Map } from 'immutable';
import PredioIcon from '../../assets/icons/predio.svg';
import HeartIcon from '../../assets/icons/heart.svg';
import RaioIcon from '../../assets/icons/raio.svg';
import PinIcon from '../../assets/icons/pin.svg';
import NextIcon from '../../assets/icons/next.svg';
import { Avatar } from '../../components/Avatar';
import { ScreenLayout } from '../../components/Layout';
import { paperTheme } from '../../lib/theme';
import { CredLoversRodape } from './CredLoversRodape';
import { CredLoversParamList } from './CredLoversNavigator';
import { goBack } from '../../lib/utils/platform';
import { LoadingScreen } from '../../components/LoadingScreen';
import useKeyboard from '../../hooks/useKeyboard';
import { CRED_LOVERS_MOCKADOS } from './credLoversMockados';
import { SubNavbar } from '../../components/SubNavbar';
import { DrawerStackParamList } from '../../navigation/navigationTypes';
import { Avaliacao } from '../../components/Avaliacao';
import { ConnectionsCandidato, connectionsFazerAvaliacao, connectionsListarCandidatos } from '../../lib/services/match';
import { AxiosContext } from '../../stores/AxiosContext';
import { buscarAvaliacaoDeUsuario } from '../../lib/services/user';
import { AlertaMatchFim } from './AlertaMatchFim';

type Props = NativeStackScreenProps<DrawerStackParamList, 'CredLoversAvaliar'>;

const AnimatedImageBackground = Animated.createAnimatedComponent(ImageBackground);
const AnimatedView = Animated.createAnimatedComponent(View);

const fadeAnimationDuration = 1000;

const textInputTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: 'black',
    },
};

export function CredLoversAvaliar({ navigation }: Props) {
    const [credLovers, setCredLovers] = useState<ConnectionsCandidato[]>([]);
    const [credLoverAtual, setCredLoverAtual] = useState(0);
    const [mostrarImagemMatch, setMostrarImagemMatch] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [carregandoCandidatos, setCarregandoCandidatos] = useState(false);
    const [carregandoAvaliacao, setCarregandoAvaliacao] = useState(false);
    const [avaliacoesCache, setAvaliacoesCache] = useState(Map<number, number>()); // cachê de avaliações dos candidatos
    const [mostrarAlertaFim, setMostrarAlertaFim] = useState(false);

    const { authAxios } = useContext(AxiosContext);
    const { height, width } = useWindowDimensions();
    const isKeyboardVisible = useKeyboard();
    const [fontsLoaded] = useFonts({
        'Montserrat-ExtraBoldItalic': require('../../assets/fonts/Montserrat-ExtraBoldItalic.ttf'),
    });

    const matchBackgroundOpacity = useSharedValue(0);
    const matchBackgroundStyle = useAnimatedStyle(() => ({
        opacity: matchBackgroundOpacity.value,
    }));

    const shakeViewRotation = useSharedValue('0deg');
    const shakeViewX = useSharedValue(0);
    const shakeViewStyle = useAnimatedStyle(() => ({
        transform: [
            { rotateZ: shakeViewRotation.value },
            { translateX: shakeViewX.value },
        ],
    }));

    const credLover = (credLovers.length > 0) ? credLovers[credLoverAtual] : null;
    const credLoverAvaliacao = (credLover != null) ? avaliacoesCache.get(credLover.id) : null;
    const temCidade = credLover?.cidade != null && credLover.cidade.trim().length > 0;
    const temEstado = credLover?.estado != null && credLover.estado.trim().length > 0;
    const temCargo = credLover?.cargo != null && credLover.cargo.trim().length > 0;
    const temEmpresa = credLover?.empresa != null && credLover.empresa.trim().length > 0;
    const temProcura = credLover?.procura != null && credLover.procura.trim().length > 0;
    const temSuperPoder = credLover?.superPoder != null && credLover.superPoder.trim().length > 0;

    const handleGoBackPress = () => {
        goBack(navigation);
    };

    const openMatchModal = () => {
        setMostrarImagemMatch(true);
        matchBackgroundOpacity.value = withTiming(1, {
            duration: fadeAnimationDuration,
        });
    };

    const closeMatchModal = () => {
        matchBackgroundOpacity.value = withTiming(0, {
            duration: fadeAnimationDuration,
        });

        setTimeout(() => {
            setMostrarImagemMatch(false);
            avancarCredLover();
        }, fadeAnimationDuration);
    };

    const avancarCredLover = () => {
        if ((credLoverAtual + 1) >= credLovers.length) {
            // não tem proximo item
            setMostrarAlertaFim(true);
            return;
        }

        const animationDuration = (Platform.OS === 'web')
            ? 0.3 * 1000    // no web
            : 0.1 * 1000;   // no mobile

        const callback = () => {
            setCredLoverAtual(credLoverAtual + 1);

            shakeViewX.value = withSequence(
                // mover perfil do credlover pra direita
                withTiming(width, { duration: 1 }),
                // mover perfil do credlover pro meio da tela
                withSpring(0),
            );
        };

        // mover perfil do credlover pra esquerda
        shakeViewX.value = withTiming(-width, { duration: animationDuration }, () => {
            // rodar na thread principal
            runOnJS(callback)();
        });
    };

    const voltarCredLover = () => {
        if ((credLoverAtual - 1) < 0) return;

        const animationDuration = (Platform.OS === 'web')
            ? 0.3 * 1000    // no web
            : 0.1 * 1000;   // no mobile

        const callback = () => {
            setCredLoverAtual(credLoverAtual - 1);

            shakeViewX.value = withSequence(
                // mover perfil do credlover pra esquerda
                withTiming(-width, { duration: 1 }),
                // mover perfil do credlover pro meio da tela
                withSpring(0),
            );
        };

        // mover perfil do credlover pra direita
        shakeViewX.value = withTiming(width, { duration: animationDuration }, () => {
            // rodar na thread principal
            runOnJS(callback)();
        });
    };

    const handleLikePress = () => {
        if (credLover == null) return;

        connectionsFazerAvaliacao(authAxios, {
            match: true,
            userId: credLover?.id,
        }).catch(err => {
            console.error(err);
        });

        // TODO: reativar isso quando der pra ver na API se o candidato deu like
        // if (credLover.deuLike) {
        //     // usuários deram match
        //     openMatchModal();
        // } else {
        //     avancarCredLover();
        // }

        avancarCredLover();
    };

    const handleDislikePress = () => {
        if (credLover == null) return;

        connectionsFazerAvaliacao(authAxios, {
            match: false,
            userId: credLover?.id,
        }).catch(err => {
            console.error(err);
        });

        avancarCredLover();
    };
    
    const chacoalharTela = () => {
        const animationDuration = 0.1 * 1000;
        shakeViewRotation.value = withSequence(
            withTiming('15deg', { duration: animationDuration }),
            withTiming('-15deg', { duration: animationDuration }),
            withTiming('15deg', { duration: animationDuration }),
            withTiming('-15deg', { duration: animationDuration }),
            withTiming('0deg', { duration: animationDuration }, () => {
                // rodar na thread principal
                runOnJS(handleLikePress)();
            }),
        );
    };

    const enviarMensagem = (texto: string) => {
        setMensagem('');

        navigation.navigate('MessengerChat', {
            novaMensagem: texto,
        });
    };

    const buscarCandidatos = async () => {
        try {
            setCarregandoCandidatos(true);
            const data = await connectionsListarCandidatos(authAxios);
            setCredLovers(data.Itens);
        } catch (err) {
            console.error(err);
        } finally {
            setCarregandoCandidatos(false);
        }
    };

    useEffect(() => {
        buscarCandidatos();
    }, []);

    useEffect(() => {
        (async () => {
            if (credLover == null) return;

            if (!avaliacoesCache.has(credLover.id)) {
                setCarregandoAvaliacao(true);
                try {
                    const data = await buscarAvaliacaoDeUsuario(authAxios, credLover.id);
                    const novoCache = avaliacoesCache.set(credLover.id, data.media);
                    setAvaliacoesCache(novoCache);
                } catch (err) {
                    console.error(err);
                } finally {
                    setCarregandoAvaliacao(false);
                }
            }
        })();
    }, [credLoverAtual, credLovers]);

    const rodape = () => (
        <>
            {(!mostrarImagemMatch) && (
                <CredLoversRodape
                    onGoBack={voltarCredLover}
                    onDislike={handleDislikePress}
                    onLike={handleLikePress}
                    onLove={chacoalharTela}
                />
            )}
        </>
    );

    if (!fontsLoaded || carregandoCandidatos) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <>
            {(mostrarImagemMatch) && (
                <AnimatedImageBackground
                    source={require('../../assets/images/connection/FUNDO.png')}
                    style={[
                        styles.matchBackground,
                        matchBackgroundStyle,
                        { width, height },
                    ]}
                >
                    <View style={{ alignItems: 'flex-end' }}>
                        <IconButton
                            icon="close"
                            iconColor="white"
                            size={50}
                            onPress={closeMatchModal}
                        />
                    </View>

                    <KeyboardAvoidingView
                        style={{ justifyContent: (isKeyboardVisible) ? 'center' : 'flex-end', height: '70%', }}
                        behavior={Platform.OS === "ios" ? "padding" : "height"}
                    >
                        <View style={styles.connectionTextWrapper}>
                            <Text style={styles.connectionText}>
                                É UMA
                            </Text>

                            <Text style={styles.connectionText}>
                                CONEXÃO!
                            </Text>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 40 }}>
                            <Image
                                source={require('../../assets/images/connection/MAO_ESQUERDA.png')}
                                style={{ width: 150, height: 192.86, zIndex: 4, }}
                                resizeMode="stretch"
                            />

                            <View style={{
                                position: 'absolute',
                                width: '100%',
                                alignItems: 'center',
                                top: -20,
                            }}>
                                <Image
                                    source={require('../../assets/images/connection/NEON_DO_MEIO.png')}
                                    style={{
                                        zIndex: 3,
                                        width: 180,
                                        height: 180,
                                        position: 'absolute',
                                        margin: 'auto',
                                    }}
                                    resizeMode="stretch"
                                />
                            </View>

                            <Image
                                source={require('../../assets/images/connection/MAO_DIREITA.png')}
                                style={{ width: 150, height: 192.86, zIndex: 4, }}
                                resizeMode="stretch"
                            />
                        </View>

                        <View style={{ alignItems: 'center' }}>
                            <TextInput
                                label="Enviar mensagem..."
                                value={mensagem}
                                onChangeText={setMensagem}
                                style={styles.matchTextInput}
                                theme={textInputTheme}
                                onSubmitEditing={() => enviarMensagem(mensagem)}
                                right={
                                    <TextInput.Icon
                                        iconColor={paperTheme.colors.primary}
                                        style={{ padding: 0, margin: 0, }}
                                        theme={textInputTheme}
                                        rippleColor="rgba(122, 117, 116, 0.5)"
                                        onPress={() => enviarMensagem(mensagem)}
                                        icon={() => (
                                            <NextIcon
                                                width={30}
                                                height={30}
                                                fill="black"
                                            />
                                        )}
                                    />
                                }
                            />
                        </View>
                    </KeyboardAvoidingView>
                </AnimatedImageBackground>
            )}

            <ScreenLayout RodapeCustomizado={rodape}>
                <SubNavbar
                    title='Criando conexões únicas'
                    textStyle={styles.screenTitle}
                />

                <AnimatedView style={[styles.content, shakeViewStyle]}>
                    <View>
                        <Avatar
                            imagem={{ uri: credLover?.foto ?? '' }}
                            avatarSize={200}
                        />

                        {/* <TouchableOpacity style={styles.notificacao}>
                            <Text style={styles.notificacaoTexto}>
                                1
                            </Text>

                            <Triangulo />
                        </TouchableOpacity> */}
                    </View>

                    <Text style={styles.nomeTexto}>
                        {credLover?.nome}
                    </Text>

                    <View style={styles.starLine}>
                        <Text style={{ fontSize: 20 }}>
                            CredLover
                        </Text>

                        <Avaliacao
                            nota={credLoverAvaliacao ?? 0}
                            corIcone={paperTheme.colors.primary}
                            style={[{
                                opacity: (credLoverAvaliacao == null) ? 0 : 1,
                                paddingHorizontal: 10,
                                margin: 0,
                            }]}
                            tamanho={30}
                            readonly
                        />
                    </View>

                    <View style={styles.usuarioInfosArea}>
                        <LinearGradient
                            colors={['#ff0c5e', '#000000']}
                            locations={[0.0, 1.0]}
                            start={[0, 1]}
                            end={[1, 0]}
                            style={styles.gradientLine}
                        />

                        <View style={styles.userInfoLinhas}>
                            {(temCidade || temEstado) && (
                                <View style={styles.userInfoLinha}>
                                    <PinIcon
                                        width={infoSvgSize}
                                        height={infoSvgSize}
                                        fill="white"
                                        style={styles.infoSvg}
                                    />

                                    <Text style={styles.userInfoTexto}>
                                        {formatarCidadeEstado(credLover.cidade, credLover.estado)}
                                    </Text>
                                </View>
                            )}

                            {(temCargo || temEmpresa) && (
                                <View style={styles.userInfoLinha}>
                                    <PredioIcon
                                        width={infoSvgSize}
                                        height={infoSvgSize}
                                        fill="white"
                                        style={styles.infoSvg}
                                    />

                                    <Text style={styles.userInfoTexto}>
                                        {formatarCidadeEstado(credLover.cargo, credLover.empresa)}
                                    </Text>
                                </View>
                            )}

                            {(temProcura) && (
                                <View style={styles.userInfoLinha}>
                                    <HeartIcon
                                        width={infoSvgSize}
                                        height={infoSvgSize}
                                        fill="white"
                                        style={styles.infoSvg}
                                    />

                                    <Text style={styles.userInfoTexto}>
                                        Em busca de {credLover.procura}
                                    </Text>
                                </View>
                            )}

                            {(temSuperPoder) && (
                                <View style={styles.userInfoLinha}>
                                    <RaioIcon
                                        width={(Platform.OS === 'web') ? 35 : infoSvgSize}
                                        height={infoSvgSize}
                                        fill="white"
                                        style={styles.infoSvg}
                                    />

                                    <Text style={styles.userInfoTexto}>
                                        Meu super poder: {credLover?.superPoder}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </AnimatedView>
            </ScreenLayout>

            <AlertaMatchFim 
                visivel={mostrarAlertaFim}
                titulo="Isso é tudo por enquanto."
                texto="Volte novamente mais tarde!"
                onDismiss={() => {
                    setMostrarAlertaFim(false);
                    navigation.navigate('HomeScreen');
                }}
                onOk={() => {
                    setMostrarAlertaFim(false);
                    navigation.navigate('HomeScreen');
                }}
            />
        </>
    );
}

function formatarCidadeEstado(cidade: string | null, estado: string | null): string {
    const temCidade = cidade != null && cidade.trim().length > 0;
    const temEstado = estado != null && estado.trim().length > 0;

    if (temCidade && !temEstado) {
        return cidade;
    } else if (!temCidade && temEstado) {
        return estado;
    } else if (temCidade && temEstado) {
        return `${cidade} - ${estado}`;
    } else {
        return '';
    }
}

function Triangulo() {
    return (
        <Svg
            style={styles.triangulo}
            viewBox="0 0 24 24"
        >
            <Path fill={paperTheme.colors.primary} d="M1 21h22L12 2" />
        </Svg>
    )
}

const avatarSize = 80;
const infoSvgSize = 18;

const styles = StyleSheet.create({
    content: {
        alignItems: 'center',
        paddingBottom: 70,
    },

    screenTitle: {
        fontSize: 25,
        fontWeight: '700',
        //marginBottom: 20,
        //marginTop: 10,
    },

    nomeTexto: {
        fontSize: 25,
        fontWeight: '700',
        marginVertical: 10,
    },

    avatarWrapper: {
        flexDirection: 'row',
        justifyContent: 'center'
    },

    notificacao: {
        position: 'absolute',
        top: 0,
        right: -65,

        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: paperTheme.colors.primary,
        padding: 5,
        minWidth: 60,
        height: 40,
        borderRadius: 8,
    },

    notificacaoTexto: {
        fontSize: 20,
    },

    triangulo: {
        width: 20,
        height: 15,
        transform: [{ rotate: '180deg' }],
        position: 'absolute',
        top: 38,
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginVertical: 10,
    },

    usuarioInfosArea: {
        width: '90%',
        maxWidth: 400,
    },

    userInfoLinhas: {
        marginHorizontal: '18%',
    },

    userInfoLinha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 300,
        marginVertical: 5,
    },

    userInfoTexto: {
        fontSize: 16,
        margin: 0,
        marginHorizontal: 5,
        textAlign: 'left',
    },

    userInfoCargo: {
        color: '#868686',
        fontSize: 17,
        fontWeight: 'bold',
        marginLeft: 0,
    },

    starLine: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        maxWidth: 300,

        marginBottom: 10,
    },

    infoSvg: {
        marginTop: 2,
    },

    matchBackground: {
        position: 'absolute',
        resizeMode: 'stretch',
        zIndex: 1000,
        backgroundColor: '#010004',
    },

    connectionText: {
        fontFamily: 'Montserrat-ExtraBoldItalic',
        fontSize: 40,
        textAlign: 'center',

        textShadowColor: 'black',
        textShadowRadius: 10,
        //textShadowOffset: { height: 10, width: 10 },
    },

    matchTextInput: {
        width: '90%',
        maxWidth: 400,

        marginTop: 20,
    },

    connectionTextWrapper: {
        zIndex: 30,
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        width: '100%',
    },
});

const nome = 'Reginaldo Santos';