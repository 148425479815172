import { useEffect, useState } from "react";
import { StyleSheet, View, ViewStyle, } from "react-native";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import { IconButton } from "react-native-paper";
import { useSharedValue } from "react-native-reanimated";

type Props = {
    nota: number,
    tamanho: number,
    corIcone: string,
    style?: ViewStyle | ViewStyle[],
    readonly?: boolean,
    onNotaInicialChange?: (nota: number) => void,
}

const itens = [1, 2, 3, 4, 5];

/** Mostra uma linha com 5 estrelas representando uma nota de 0 a 5 */
export function Avaliacao(props: Props) {
    const iconeTamanhoStyles: ViewStyle = {
        width: props.tamanho + 5,
        height: props.tamanho + 5,
    };

    const getIcon = (index: number) => {
        if (props.nota == null || Number.isNaN(props.nota)) {
            return 'star-outline';
        }

        const notaItem = props.nota - index;
        if (notaItem >= 1) {
            return 'star';
        } else if (notaItem <= 0) {
            return 'star-outline';
        } else {
            return 'star-half-full';
        }
    };

    const handleIconPress = (item: number) => {
        if (props.readonly) return;

        if (props.onNotaInicialChange != null) {
            props.onNotaInicialChange(item);
        }
    };

    return (
        <View style={[styles.container, props.style]}>
            {(itens.map((item, i) => {
                const handlePress = (!props.readonly)
                    ? () => handleIconPress(item)
                    : undefined;

                return (
                    <IconButton
                        key={i}
                        icon={getIcon(i)}
                        size={props.tamanho}
                        iconColor={props.corIcone}
                        style={[styles.icon, iconeTamanhoStyles]}
                        onPress={handlePress}
                    />
                );
            }))}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
    },

    icon: {
        margin: 0,
        padding: 0,
    },
});