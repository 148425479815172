import React, { useState } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { paperTheme } from "../../lib/theme";
import LupaIcon from '../../assets/icons/lupa.svg';

type Props = {
    value: string,
    onChangeText: (newValue: string) => void,
};

export function CampoBusca(props: Props) {
    return (
        <View style={styles.container}>
            <TextInput 
                value={props.value} 
                onChangeText={props.onChangeText} 
                style={styles.textInput} 
                cursorColor={paperTheme.colors.primary} 
                selectionColor={paperTheme.colors.primary} 
            />

            <LupaIcon 
                width={iconSize}
                height={iconSize}
                fill="white"
                style={styles.icone}
            />
        </View>
    );
}

const iconSize = 20;

const styles = StyleSheet.create({
    container: {
        backgroundColor: paperTheme.colors.surface,
        borderRadius: 8,
        paddingRight: 20,
        height: 40,
    },
    
    textInput: {
        flex: 1,
        width: 150,
        padding: 10,

        color: 'white',
    },

    icone: {
        position: 'absolute',
        right: 10,
        top: 10,
    },
});