import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TextInput as RNTextInput, View } from 'react-native';
import { Button, Modal, TextInput, Text, HelperText } from "react-native-paper";
import { paperTheme, themeStyles } from "../../lib/theme";

type Props = {
    visible: boolean,
    initialState: { nome: string, nomeSocial: string },
    onDismiss?: () => void,
    onSubmit?: (nome: string, nomeSocial: string) => void,
};

export function NomeModal(props: Props) {
    const [nome, setNome] = useState('');
    const [nomeSocial, setNomeSocial] = useState('');
    const [mostrarErroNome, setMostrarErroNome] = useState(false);

    const segundoTextInput = useRef<RNTextInput>(null);

    const handleSubmit = () => {
        if (props.onSubmit == null) return;

        if (nome.length === 0) {
            setMostrarErroNome(true);
            return;
        }

        props.onSubmit(nome, nomeSocial);
    };

    const handleNomeChange = (novoNome: string) => {
        if (novoNome.length === 0) {
            setMostrarErroNome(true);
            setNome(novoNome);
            return;
        }

        setMostrarErroNome(false);
        setNome(novoNome);
    };

    useEffect(() => {
        setNome(props.initialState.nome);
        setNomeSocial(props.initialState.nomeSocial);
    }, [props.initialState]);

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <View style={styles.textInputWrapper}>
                <TextInput
                    label="Como você se chama?"
                    value={nome}
                    onChangeText={handleNomeChange}
                    onSubmitEditing={() => segundoTextInput.current?.focus()}
                    style={styles.textInput}
                />
                {(mostrarErroNome) && (
                    <HelperText type="error" visible={mostrarErroNome} >
                        Esse campo é obrigatório
                    </HelperText>
                )}
            </View>

            <View style={styles.textInputWrapper}>
                <TextInput
                    ref={segundoTextInput}
                    label="Qual o seu nome social? (opcional)"
                    value={nomeSocial}
                    onChangeText={setNomeSocial}
                    onSubmitEditing={handleSubmit}
                    style={styles.textInput}
                />
            </View>

            <Button
                mode="contained"
                textColor="white"
                onPress={handleSubmit}
            >
                Salvar
            </Button>
        </Modal>
    );
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',

        height: 240,
        width: '95%',
        maxWidth: 400,
        borderRadius: 10,
        paddingHorizontal: 10,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInputWrapper: {
        width: '90%',
    },

    textInput: {
        width: '100%',
        backgroundColor: paperTheme.colors.background,
    },
});