import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FlashList } from "@shopify/flash-list";
import { useQuery } from "@tanstack/react-query";
import { useContext, useRef, useState } from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { Divider, Text } from "react-native-paper";
import { ScreenLayout } from "../../components/Layout";
import { LoadingScreen } from "../../components/LoadingScreen";
import { SubNavbar } from "../../components/SubNavbar";
import { isEnvDev } from "../../lib/env";
import { listarQuemAvaliou } from "../../lib/services/user";
import { showAlert } from "../../lib/utils/platform";
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { AxiosContext } from "../../stores/AxiosContext";
import Avaliador from "./Avaliador";
import { AVALIADORES_MOCKADOS } from "./avaliadoresMockados";

type Props = NativeStackScreenProps<DrawerStackParamList, 'QuemAvaliouScreen'>;

export function QuemAvaliouScreen({ navigation }: Props) {
    const { authAxios } = useContext(AxiosContext);
    const avaliadoresQuery = useQuery({
        queryKey: ['quemMeAvaliou'],
        queryFn: () => listarQuemAvaliou(authAxios),
        onError: (err) => {
            console.error(err);
            showAlert('Erro', 'Não foi possível encontrar quem te avaliou, tente novamente');
            navigation.goBack();
        },
    })

    if (avaliadoresQuery.isLoading || avaliadoresQuery.error) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <ScreenLayout contentContainerStyle={styles.container}>
            <SubNavbar
                title="Quem me avaliou?"
            />

            <View style={{ height: '100%' }}>
                <FlashList
                    data={avaliadoresQuery?.data?.Avaliacao}
                    keyExtractor={(_item, index) => index.toString()}
                    estimatedItemSize={95}
                    renderItem={({ item }) => (
                        <Avaliador
                            avaliadorUserId={item.user_avaliador.toString()}
                            notaAtraente={item.atraente ?? 0}
                            notaConfiavel={item.confiavel ?? 0}
                            notaPrestativo={item.prestativo ?? 0}
                        />
                    )}
                    ListEmptyComponent={(
                        <View style={{ marginTop: 20, alignItems: 'center' }}>
                            <Text style={{ fontSize: 18 }}>
                                Você ainda não recebeu nenhuma avaliação, volte mais tarde.
                            </Text>
                        </View>
                    )}
                />
            </View>
        </ScreenLayout>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        paddingBottom: 0,
    }
})