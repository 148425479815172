import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { Text, TouchableRipple } from 'react-native-paper';
import Svg, { Circle } from 'react-native-svg';
import { Avatar } from '../../components/Avatar';
import { paperTheme } from '../../lib/theme';

export type Props = {
    avatar: string,
    usuario: string,
    ultimaMensagem: string,
    foiLido: boolean,
    onPress?: () => void,
};

export function ConversaCard(props: Props) {
    const { width } = useWindowDimensions();

    const avatar = (props.avatar.length > 0)
        ? { uri: props.avatar }
        : undefined;

    const mensagemStyle = (width < 700)
        ? [styles.mensagem, styles.mensagemMobile]
        : [styles.mensagem];

    const infosStyle = (width < 700)
        ? [styles.infos, styles.infosMobile]
        : [styles.infos];

    return (
        <TouchableRipple
            onPress={props.onPress}
        >
            <View style={styles.container}>
                <Avatar
                    imagem={avatar}
                    avatarSize={70}
                />

                <View style={infosStyle}>
                    <Text style={styles.usuarioNome}>
                        {props.usuario}
                    </Text>

                    <View style={styles.mensagemLinha}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text
                                style={mensagemStyle}
                                ellipsizeMode="tail"
                                numberOfLines={1}
                            >
                                {props.ultimaMensagem}
                            </Text>

                            <Text style={styles.hora}>
                                ● há 5 min
                            </Text>
                        </View>

                        {(!props.foiLido) && (
                            <Svg height="15" width="15">
                                <Circle cx="50%" cy="50%" r="40%" fill={paperTheme.colors.primary} />
                            </Svg>
                        )}
                    </View>

                    <LinearGradient
                        colors={['#ff0c5e', 'transparent']}
                        locations={[0.0, 1.0]}
                        start={[0, 1]}
                        end={[1, 0]}
                        style={styles.gradientLine}
                    />
                </View>
            </View>
        </TouchableRipple>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',

        height: 100,
        paddingVertical: 15,
        paddingHorizontal: 20,
    },

    infos: {
        marginLeft: 10,
        width: '90%',
    },

    infosMobile: {
        width: '75%',
    },

    usuarioNome: {
        fontSize: 20,
    },

    mensagemLinha: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        marginTop: 15,
        width: '100%',
    },

    mensagem: {
        fontSize: 16,
    },

    mensagemMobile: {
        maxWidth: '70%',
    },

    hora: {
        fontSize: 16,
        color: '#a1a1a1',
        marginLeft: 5,
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginTop: 5,
    },
});