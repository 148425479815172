import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { paperTheme, themeStyles } from '../lib/theme';
import MenuIcon from '../assets/icons/menu.svg';
import LupaIcon from '../assets/icons/lupa.svg';

export function NavBar() {
    const navigation = useNavigation();

    const openMenu = () => {
        if (navigation.hasOwnProperty('openDrawer')) {
            //@ts-ignore
            navigation.openDrawer();
        }
    };

    const openUserSearch = () => {
        navigation.navigate('PesquisarUsuariosScreen' as any);
    };

    return (
        <View style={navbarStyles.container}>
            <View style={navbarStyles.navbar}>
                <IconButton 
                    iconColor="white"
                    size={iconSize}
                    onPress={openMenu}
                    icon={() => (
                        <MenuIcon
                            width={svgSize}
                            height={svgSize}
                            fill="white"
                        />
                    )}
                />

                <IconButton 
                    iconColor="white"
                    size={iconSize}
                    onPress={openUserSearch}
                    icon={() => (
                        <LupaIcon
                            width={svgSize}
                            height={svgSize}
                            fill="white"
                        />
                    )}
                />
            </View>
        </View>
    );
}

const svgSize = 25;

export const navbarStyles = StyleSheet.create({
    container: {
        flexDirection: 'column',

        backgroundColor: paperTheme.colors.darkBackground,
        borderBottomColor: '#ff0058',
        borderBottomWidth: 2,

        position: 'absolute',
        top: 0,
        zIndex: 30,
        width: '100%',
    },

    navbar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        height: 60,
    },
});

const iconSize = 30;