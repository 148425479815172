import { useNavigation } from "@react-navigation/native";
import React from "react";
import { View, StyleSheet, TextStyle } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { goBack } from "../lib/utils/platform";
import SetaBaixoIcon from '../assets/icons/seta-baixo.svg';

type Props = {
    title: string,
    textStyle?: TextStyle,
}

export function SubNavbar(props: Props) {
    const navigation = useNavigation();

    return (
        <View style={styles.headerNavbar}>
            <IconButton
                onPress={() => goBack(navigation)}
                style={styles.goBackIcon}
                icon={() => (
                    <SetaBaixoIcon
                        width={25}
                        height={25}
                        fill="white"
                    />
                )}
            />

            <Text style={[styles.headerTitle, props.textStyle]}>
                {props.title}
            </Text>

            <View style={{ width: 40 }} />
        </View>
    );
}

const styles = StyleSheet.create({
    headerNavbar: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center' ,
        
        width: '100%',
        paddingVertical: 10,
    },

    headerTitle: {
        fontSize: 23,
    },

    goBackIcon: {
        transform: [{ rotate: '90deg' }],
    },
});