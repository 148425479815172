import React from "react";
import { StyleSheet } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { paperTheme } from "../lib/theme";
import { NavBar } from "./NavBar";

type Props = {
    hideNavBar?: boolean,
};

export function LoadingScreen(props: Props) {
    return (
        <SafeAreaView style={styles.container}>
            {(!props.hideNavBar) && (
                <NavBar />
            )}
            
            <ActivityIndicator 
                size={50}
                color="white"
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: paperTheme.colors.background,
    },
});