import { Pressable, StyleSheet, View } from "react-native";
import { Surface, Text } from "react-native-paper";
import { Avatar } from "../../components/Avatar";
import { UserInfos } from "../../lib/services/user";
import { paperTheme } from "../../lib/theme";

type Props = {
    usuario: UserInfos,
    onPress?: () => void,
}

export function PesquisarUsuariosItem({ usuario, onPress }: Props) {
    const usuarioNome = (usuario.nome_social != null && usuario.nome_social.length > 0)
        ? usuario.nome_social
        : usuario.nome;

    return (
        <Surface style={styles.usuario}>
            <Pressable onPress={onPress} style={styles.pressable}>
                <Avatar 
                    imagem={{ uri:  usuario.foto ?? '' }}
                    avatarSize={65}
                />

                <Text style={styles.usuarioNome}>
                    {usuarioNome.trim()}
                </Text>
            </Pressable>
        </Surface>
    );
}

const styles = StyleSheet.create({
    usuario: {
        backgroundColor: paperTheme.colors.surface,
        
        width: 300,
        marginVertical: 6,
        borderRadius: 8,
    },
    
    pressable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',

        paddingVertical: 10,
        paddingHorizontal: 10,
    },

    usuarioNome: {
        fontSize: 18,
        fontWeight: '600',

        marginLeft: 10,
    },
});