import { Platform } from "react-native";

export function isEnvDev() {
    if (__DEV__) {
        return true;
    }

    if (Platform.OS === 'web') {
        // checar se a URL contem 'git-dev'
        if (window.location.hostname.includes('git-dev')) {
            return true;
        }
    }

    return false;
}