import React, { PropsWithChildren } from 'react';
import { ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import useKeyboard from '../hooks/useKeyboard';
import { themeStyles } from '../lib/theme';
import { MenuRodape } from './MenuRodape';
import { NavBar } from './NavBar';

type Props = {
    RodapeCustomizado?: () => JSX.Element, 
    contentContainerStyle?: ViewStyle,
    esconderNavBar?: boolean,
    esconderRodaPe?: boolean,
};

export function ScreenLayout(props: PropsWithChildren<Props>) {
    const isKeyboardVisible = useKeyboard();

    const RodaPe = props.RodapeCustomizado ?? MenuRodape;

    const contentContainer = (props.esconderNavBar)
        ? themeStyles.contentContainerSemNavbar
        : themeStyles.contentContainer;

    return (
        <SafeAreaView style={themeStyles.background}>
            {(!props.esconderNavBar) && (
                <NavBar />
            )}
            
            <View style={contentContainer}>
                <ScrollView 
                    contentContainerStyle={[themeStyles.listContentContainer, props.contentContainerStyle]}
                >
                    {props.children}
                </ScrollView>
            </View>

            {(!isKeyboardVisible && !props.esconderRodaPe) && (
                <RodaPe />
            )}
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    
});