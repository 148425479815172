import { AxiosInstance } from "axios";
import { z } from "zod"

export const ConnectionsCandidatoSchema = z.object({
    id: z.number(),
    nome: z.string(),
    nome_social: z.string().nullable(),
    empresa: z.string().nullable(),
    cidade: z.string().nullable(),
    estado: z.string().nullable(),
    descricao: z.string().nullable(),
    procura: z.string().nullable(),
    superPoder: z.string().nullable(),
    cargo: z.string().nullable(),
    foto: z.string().nullable(),
});

export type ConnectionsCandidato = z.infer<typeof ConnectionsCandidatoSchema>;

export const ConnectionsListarCandidatosSchema = z.object({
    Status: z.boolean(),
    Itens: z.array(ConnectionsCandidatoSchema),
});

export async function connectionsListarCandidatos(axios: AxiosInstance) {
    const res = await axios.get('/match/all');
    const data = ConnectionsListarCandidatosSchema.parse(res.data);
    return data;
}

export const ConnectionsFazerMatchSchema = z.object({
    Status: z.boolean(),
    Match: z.boolean(),
    Mensagem: z.string(),
});

export type FazerMatchParams = {
    userId: number,
    match: boolean,
}

export async function connectionsFazerAvaliacao(axios: AxiosInstance, params: FazerMatchParams) {
    const res = await axios.post('/api/match', params);
    const data = ConnectionsFazerMatchSchema.parse(res.data);
    return data;
}