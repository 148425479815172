import React, { useContext, useEffect, useMemo, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";
import { StyleSheet, View } from "react-native";
import { Button, Divider, IconButton, Text } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { MenuRodape } from "../../components/MenuRodape";
import { NavBar } from "../../components/NavBar";
import { themeStyles } from "../../lib/theme";
import { ComunidadeCard } from "./ComunidadeCard";
import { CampoBusca } from "./CampoBusca";
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { CriarComunidadeModal } from "./CriarComunidadeModal";
import { Comunidade, criarComunidade, listarComunidades } from "../../lib/services/comunidades";
import { AxiosContext } from "../../stores/AxiosContext";
import { LoadingScreen } from "../../components/LoadingScreen";
import { goBack, showAlert } from "../../lib/utils/platform";

type Props = NativeStackScreenProps<DrawerStackParamList, 'ComunidadesLista'>;

export function ComunidadesLista({ navigation }: Props) {
    const [busca, setBusca] = useState('');
    const [comunidades, setComunidades] = useState<Comunidade[]>([]);
    const [mostrarModalCriarComunidade, setMostrarModalCriarComunidade] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { authAxios } = useContext(AxiosContext);

    const comunidadesFiltradas = useMemo(() => {
        const buscaMinusculo = busca.toLowerCase();

        return comunidades.filter(c => {
            return c.nome
                .toLowerCase()
                .includes(buscaMinusculo)
        });
    }, [comunidades, busca]);

    const abrirComunidade = (id: number) => {
        navigation.navigate('ComunidadeScreen', {
            comunidadeId: id,
        });
    };

    const abrirModalCriar = () => {
        setMostrarModalCriarComunidade(true);
    };

    const fecharModalCriar = () => {
        setMostrarModalCriarComunidade(false);
    };

    const buscarComunidades = async () => {
        setIsLoading(true);
        try {
            const data = await listarComunidades(authAxios);
            setComunidades(data.Comunidade);
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleModalCriarSubmit = async (comunidadeNome: string) => {
        setMostrarModalCriarComunidade(false);
        
        setIsLoading(true);
        try {
            setComunidades([]);
            await criarComunidade(authAxios, comunidadeNome);
            await buscarComunidades();
        } catch (err) {
            console.error(err);
            showAlert('Erro', 'Não foi possível criar a comunidade, tente novamente.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        buscarComunidades();
    }, []);

    if (isLoading) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <SafeAreaView style={themeStyles.background}>
            <NavBar />

            <View style={themeStyles.contentContainer}>
                <FlashList
                    data={comunidadesFiltradas}
                    estimatedItemSize={100}
                    contentContainerStyle={themeStyles.listContentContainer}
                    keyboardShouldPersistTaps='handled'
                    ListHeaderComponent={(
                        <View style={styles.listHeader}>
                            <View style={styles.headerNavbar}>
                                <IconButton
                                    onPress={() => goBack(navigation)}
                                    style={{ transform: [{ rotate: '90deg' }] }}
                                    icon={() => (
                                        <SetaBaixoIcon
                                            width={25}
                                            height={25}
                                            fill="white"
                                        />
                                    )}
                                />

                                <Text style={styles.headerTitle}>
                                    Comunidades
                                </Text>

                                <View style={{ width: 40 }} />
                            </View>

                            <View style={styles.comunidadeButtons}>
                                <Button
                                    mode="contained"
                                    textColor="white"
                                    icon="plus"
                                    contentStyle={{ flexDirection: 'row-reverse' }}
                                    onPress={abrirModalCriar}
                                >
                                    CRIAR
                                </Button>

                                <CampoBusca
                                    value={busca}
                                    onChangeText={setBusca}
                                />
                            </View>
                        </View>
                    )}
                    renderItem={({ item }) => (
                        <View style={styles.comunidadeWrapper}>
                            <ComunidadeCard onPress={() => abrirComunidade(item.id)}>
                                <View style={styles.comunidadeConteudo}>
                                    <Text style={styles.comunidadeTexto}>
                                        {item.nome}
                                    </Text>
                                </View>
                            </ComunidadeCard>
                        </View>
                    )}
                    ListEmptyComponent={(
                        <View style={{ alignItems: 'center' }}>
                            {(!isLoading) && (
                                <Text style={styles.emptyListText}>
                                    Nenhuma comunidade encontrada
                                </Text>
                            )}
                        </View>
                    )}
                />
            </View>

            <MenuRodape />

            <CriarComunidadeModal 
                visible={mostrarModalCriarComunidade}
                onDismiss={fecharModalCriar}
                onSubmit={handleModalCriarSubmit}
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    listHeader: {
        alignItems: 'center',
        marginBottom: 20,
    },

    headerNavbar: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center' ,
        
        width: '100%',
        paddingVertical: 10,
    },

    headerTitle: {
        fontSize: 23,
    },

    comunidadeButtons: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        width: '90%',
        maxWidth: 400,
    },

    comunidadeWrapper: {
        alignItems: 'center',
        // padding: 20,
    },

    comunidadeConteudo: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        height: '100%',
        paddingLeft: 20,
    },

    comunidadeTexto: {
        marginLeft: 20,
        maxWidth: 200,
        fontSize: 18,
    },

    emptyListText: {
        fontSize: 22,
        marginTop: 40,
    },
});