import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, ScrollView, TextInput as RNTextInput } from 'react-native';
import { Modal, TextInput, Button, RadioButton } from 'react-native-paper';
import { paperTheme, themeStyles } from '../../lib/theme';

type Props = {
    visible: boolean,
    initialState: { coisaEmBusca: string },
    onDismiss?: () => void,
    onSubmit?: (coisaEmBusca: string) => void,
};

const buscasPadroes = [
    'um sócio',
    'um CredCat',
    'uma CredCat',
    'um funcionário',
    'networking',
    'fornecedores',
];

export function EmBuscaModal(props: Props) {
    const [coisaEmBusca, setCoisaEmBusca] = useState('');
    const [coisaCustomizada, setCoisaCustomizada] = useState('');

    const coisaTextInput = useRef<RNTextInput>(null);

    const handleSubmit = () => {
        if (props.onSubmit == null) return;

        const coisa = (coisaEmBusca === 'outro') ? coisaCustomizada : coisaEmBusca;
        props.onSubmit(coisa);
    };

    useEffect(() => {
        if (!props.visible) return;
        
        if (coisaEmBusca === 'outro') {
            coisaTextInput.current?.focus();
        }
    }, [coisaEmBusca]);

    useEffect(() => {
        const { coisaEmBusca } = props.initialState;

        if (buscasPadroes.includes(coisaEmBusca) || coisaEmBusca === '') {
            setCoisaEmBusca(props.initialState.coisaEmBusca);
            setCoisaCustomizada('');
        } else {
            setCoisaEmBusca('outro');
            setCoisaCustomizada(coisaEmBusca);
        }
    }, [props.initialState]);

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <ScrollView>
                <RadioButton.Group
                    onValueChange={setCoisaEmBusca}
                    value={coisaEmBusca}
                >
                    {buscasPadroes.map((busca, i) => (
                        <RadioButton.Item
                            key={i}
                            label={`Estou em busca de ${busca}`}
                            value={busca}
                        />
                    ))}

                    <RadioButton.Item
                        label="Outro"
                        value="outro"
                    />
                </RadioButton.Group>
            </ScrollView>

            {(coisaEmBusca === 'outro') && (
                <TextInput
                    ref={coisaTextInput}
                    label="O que você está procurando?"
                    placeholder=""
                    value={coisaCustomizada}
                    onChangeText={setCoisaCustomizada}
                    onSubmitEditing={handleSubmit}
                    style={styles.textInput}
                />
            )}

            <Button
                mode="contained"
                textColor="white"
                onPress={handleSubmit}
                style={{ marginTop: 10 }}
            >
                Salvar
            </Button>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',

        maxHeight: 440,
        width: '90%',
        maxWidth: 400,
        borderRadius: 10,
        paddingVertical: 10,
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInput: {
        width: '90%',
        backgroundColor: paperTheme.colors.background,
        marginTop: 10,
    },
});