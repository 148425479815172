import React from 'react';
import { Provider as PaperProvider } from 'react-native-paper';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'  
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { paperTheme } from './lib/theme';
import { Platform } from 'react-native';
import { AxiosProvider } from './stores/AxiosContext';
import { ShareModal } from './components/ShareModal';

const queryClient = new QueryClient()

export default function App() {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <SafeAreaProvider>
                <QueryClientProvider client={queryClient}>
                    <PaperProvider theme={paperTheme}>
                        <Navigation colorScheme={colorScheme} />
                        <StatusBar style='light' />

                        <ShareModal />
                    </PaperProvider>
                </QueryClientProvider>
            </SafeAreaProvider>
        );
    }
}


function injectWebCss() {
    // Only on web
    if (Platform.OS !== 'web') return;

    // Inject style
    const style = document.createElement('style');
    style.textContent = `
        textarea, select, input, button { outline: none!important; }

        div {
            scroll-behavior: smooth;
        } 
    `;
    return document.head.append(style);
}

// 👉 And this in the App.js file
injectWebCss()

