import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import Markdown from 'react-native-marked';
import { Avatar } from "../../components/Avatar";
import { Autor } from "./mensagensMockadas";

export type Props = {
    autor: Autor,
    conteudo: string,
    avatar?: string,
}

export function Mensagem(props: Props) {
    const containerStyle = (props.autor === 'eu')
        ? [styles.container, styles.containerMsgMinha]
        : [styles.container, styles.containerMsgOutra];

    const mensagemTamanhoStyle = (markdownContemImagem(props.conteudo)) 
        ? styles.mensagemComImagem
        : styles.mensagemSemImagem;

    const mensagemStyle = (props.autor === 'eu')
        ? [styles.mensagem, mensagemTamanhoStyle, styles.mensagemMinha]
        : [styles.mensagem, mensagemTamanhoStyle, styles.mensagemOutro];

    const avatar = (props.avatar != null)
        ? { uri: props.avatar }
        : undefined;

    return (
        <View style={containerStyle}>
            {(props.autor === 'outro' && props.avatar != null) && (
                <Avatar
                    imagem={avatar}
                    avatarSize={30}
                    style={styles.avatar}
                />
            )}

            <View style={mensagemStyle}>
                <Text style={styles.mensagemTexto}>
                    {props.conteudo}
                </Text>
            </View>
        </View>
    );
}

function markdownContemImagem(markdown: string) {
    const markdownImagemRegex = /!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)/;
    return markdownImagemRegex.test(markdown);
}

const styles = StyleSheet.create({
    avatar: {
        marginRight: 10,
    },

    container: {
        alignItems: 'center',

        width: '90%',
        maxWidth: 500,
        marginVertical: 10,
    },

    containerMsgMinha: {
        flexDirection: 'row-reverse',
    },

    containerMsgOutra: {
        flexDirection: 'row',
    },

    mensagem: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,

        maxWidth: 300,
    },
    
    mensagemSemImagem: {
        minWidth: 30,
    },

    mensagemComImagem: {
        minWidth: 200,
    },

    mensagemMinha: {
        borderTopRightRadius: 0,
    },

    mensagemOutro: {
        borderBottomLeftRadius: 0,
    },

    mensagemTexto: {
        color: 'black',
        fontSize: 16,
    },
});