import { useQuery } from "@tanstack/react-query";
import React, { memo, useContext, useMemo } from "react";
import { ImageURISource, Pressable, StyleSheet, View } from "react-native";
import { Surface, Text } from "react-native-paper";
import { Skeleton } from 'react-native-skeletons';
import LinearGradient from 'expo-linear-gradient';
import { Avaliacao } from "../../components/Avaliacao";
import { Avatar } from "../../components/Avatar";
import { getUserInfos } from "../../lib/services/user";
import { paperTheme } from "../../lib/theme";
import { AxiosContext } from "../../stores/AxiosContext";
import { useNavigation } from "@react-navigation/native";
import { DrawerStackParamList } from "../../navigation/navigationTypes";

type Props = {
    avaliadorUserId: string,
    notaConfiavel: number,
    notaPrestativo: number,
    notaAtraente: number,
}

function Avaliador(props: Props) {
    const navigation = useNavigation();
    const { authAxios } = useContext(AxiosContext);
    const query = useQuery({
        queryKey: ['quemMeAvaliou', 'item', props.avaliadorUserId],
        queryFn: () => getUserInfos(authAxios, props.avaliadorUserId),
        onError: (err) => console.error(err),
    });

    const notaMedia = useMemo(() => {
        const soma = props.notaAtraente + props.notaConfiavel + props.notaPrestativo;
        return soma / 3;
    }, [props.avaliadorUserId]);

    const nomeExibido = query.data?.nome_social ?? query.data?.nome ?? '';

    const handleCardPress = () => {
        navigation.navigate('ProfileScreen' as any, {
            userId: props.avaliadorUserId,
        });
    };

    if (query.isLoading) {
        return (
            <View style={[styles.wrapper]}>
                <Skeleton
                    height={81}
                    width={300}
                    style={{ marginVertical: 10 }}
                />
            </View>
        );
    }

    return (
        <View style={styles.wrapper}>
            <Surface style={styles.container}>
                <Pressable
                    onPress={handleCardPress}
                    style={styles.pressable}
                    android_ripple={{ color: 'default', borderless: true }}
                >
                    <Avatar
                        imagem={{ uri: query.data?.foto ?? '' }}
                        avatarSize={65}
                    />

                    <View style={{ marginLeft: 15 }}>
                        <Text style={styles.nome}>
                            {nomeExibido}
                        </Text>

                        <Avaliacao
                            corIcone={paperTheme.colors.primary}
                            tamanho={25}
                            nota={notaMedia}
                            style={styles.avaliacao}
                            readonly
                        />
                    </View>
                </Pressable>
            </Surface>
        </View>
    );
}

export default memo(Avaliador);

const styles = StyleSheet.create({
    wrapper: {
        alignItems: 'center',
        width: '100%',
    },

    container: {
        width: '80%',
        padding: 8,
        maxWidth: 300,
        marginVertical: 10,
        borderRadius: 10,
        backgroundColor: paperTheme.colors.surface,
    },

    pressable: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },

    nome: {
        fontSize: 20,
        fontWeight: '500',
        minHeight: 25,
    },

    avaliacao: {
        justifyContent: 'flex-start',
        marginTop: 5,
    },
});