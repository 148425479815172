import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Button, Modal, Snackbar, Text, TextInput } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import Clipboard from '@react-native-clipboard/clipboard';
import { paperTheme, themeStyles } from '../lib/theme';
import { AvaliacaoUsuario } from '../lib/services/user';
import { useUserStore } from '../stores/userStore';
import { showAlert } from '../lib/utils/platform';
import { useAppStore } from '../stores/appStore';

type Props = {};

export function ShareModal(props: Props) {
    const [mostrarSnackSucesso, setMostrarSnackSucesso] = useState(false);

    const appStore = useAppStore();

    // esse componente deve ser usado apenas na web
    const urlAtual = (Platform.OS === 'web')
        ? window.location.href
        : '';

    const onDismiss = () => {
        appStore.update({ showShareModal: false });
    };

    const copyLink = () => {
        try {
            setMostrarSnackSucesso(false);
            Clipboard.setString(urlAtual);
            
            appStore.update({ showShareModal: false });
        } catch (err) {
            console.error(err);
            showAlert('Erro', 'Não foi possível copiar o texto');
        }
    };

    return (
        <>
            <Modal
                visible={appStore.showShareModal}
                onDismiss={onDismiss}
                contentContainerStyle={[styles.container, themeStyles.surface]}
                style={{ alignItems: 'center', justifyContent: 'center', }}
            >
                <TextInput
                    label="Link"
                    value={urlAtual}
                    editable={false}
                />

                <Button
                    mode="outlined"
                    icon="content-copy"
                    textColor="white"
                    onPress={copyLink}
                    style={{ marginTop: 30 }}
                >
                    COPIAR LINK
                </Button>
            </Modal>

            <Snackbar
                visible={mostrarSnackSucesso}
                onDismiss={() => setMostrarSnackSucesso(false)}
                action={{
                    label: 'Ok',
                    onPress: () => { },
                    labelStyle: { color: paperTheme.colors.primary }
                }}
                style={{ maxWidth: 400, backgroundColor: 'white' }}
                wrapperStyle={{ width: '100%', alignItems: 'center' }}
            >
                Link copiado com sucesso
            </Snackbar>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',

        height: 200,
        width: '90%',
        maxWidth: 350,
        borderRadius: 10,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },
});