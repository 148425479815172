import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import Animated, { cancelAnimation, useAnimatedStyle, useSharedValue, withDelay, withRepeat, withSequence, withSpring, withTiming } from "react-native-reanimated";

const AnimatedView = Animated.createAnimatedComponent(View);

export type Props = {
    mostrar?: boolean;
};

export function ChatIndicadorDeAtividade(props: Props) {
    const containerStyle = (props.mostrar)
        ? styles.mostrar
        : styles.ocultar;

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.linha}>
                <Bola numero={1} mostrar={props.mostrar} />
                <Bola numero={2} mostrar={props.mostrar} />
                <Bola numero={3} mostrar={props.mostrar} />
            </View>
        </View>
    );
}

type BolaProps = {
    numero: number,
    mostrar?: boolean,
};

function Bola(props: BolaProps) {
    const translateY = useSharedValue(0);

    const delay = props.numero * 500;

    const animStyles = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: translateY.value,
            },
        ],
    }));

    useEffect(() => {
        if (props.mostrar) {
            translateY.value = withRepeat(
                withSequence(
                    withDelay(delay, withSpring(-10)),
                    withDelay(delay, withSpring(0)),
                ),
                -1, // repetir infinitamente
            );
        } else {
            // resetar a animação toda vez que ocultar o indicador de atividade
            translateY.value = withTiming(0, { duration: 0 });
        }
    }, [props.mostrar]);

    return (
        <AnimatedView
            style={[styles.bola, animStyles]}
        />
    );
}

const bolaTamanho = 5;

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexDirection: 'row-reverse',

        width: '90%',
        maxWidth: 500,
        marginTop: 20,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    bola: {
        backgroundColor: 'white',
        width: bolaTamanho,
        height: bolaTamanho,
        borderRadius: bolaTamanho / 2,

        marginRight: 5,
    },

    mostrar: {
        opacity: 1.0,
    },

    ocultar: {
        opacity: 0.0,
    },
});