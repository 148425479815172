import React, { useState } from "react";
import { StyleSheet } from 'react-native';
import { Button, Modal, TextInput, } from "react-native-paper";
import { paperTheme, themeStyles } from "../../lib/theme";

type Props = {
    visible: boolean,
    onDismiss?: () => void,
    onSubmit?: (comunidadeNome: string) => void,
};

export function CriarComunidadeModal(props: Props) {
    const [comunidadeNome, setComunidadeNome] = useState('');

    const handleSubmit = () => {
        if (props.onSubmit == null) return;
        if (comunidadeNome.trim().length === 0) return;

        props.onSubmit(comunidadeNome.trim());
    };

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <TextInput 
                label="Qual o nome da sua comunidade?"
                value={comunidadeNome}
                onChangeText={setComunidadeNome}
                style={styles.textInput}
            />

            <Button
                mode="contained"
                textColor="white"
                onPress={handleSubmit}
            >
                Criar comunidade
            </Button>
        </Modal>
    );
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',

        height: 200,
        width: '90%',
        maxWidth: 370,
        borderRadius: 10,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInput: {
        width: '80%',
        backgroundColor: paperTheme.colors.background,
    },
});