import React from "react";
import { StyleSheet } from 'react-native';
import { Button, IconButton, Text } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ScreenLayout } from "../../components/Layout";
import { paperTheme } from "../../lib/theme";
import { RootStackParamList } from "../../navigation/navigationTypes";

type Props = NativeStackScreenProps<RootStackParamList, 'CriarContaSucessoScreen'>;

export function CriarContaSucessoScreen({ navigation }: Props) {
    const irParaHome = () => {
        navigation.navigate('LoginScreen');
    };

    return (
        <ScreenLayout
            contentContainerStyle={styles.container}
            esconderNavBar
            esconderRodaPe
        >
            <IconButton 
                icon="check-circle-outline"
                iconColor={paperTheme.colors.primary}
                size={150}
                style={styles.icon}
            />

            <Text style={styles.texto}>
                Conta criada com sucesso!
            </Text>

            <Button
                onPress={irParaHome}
                textColor="white"
                mode="contained"
                style={styles.button}
            >
                Avançar
            </Button>
        </ScreenLayout>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        paddingBottom: 0,
    },

    icon: {
        padding: 0,
        margin: 0,
    },

    texto: {
        fontSize: 25,
        fontWeight: '900',
    },

    button: {
        marginTop: 40,
    },
});