import { AxiosInstance } from "axios";
import { z } from "zod";

export const ComunidadeSchema = z.object({
    id: z.number(),
    usuario: z.string(),
    foto: z.string(),
    url: z.string(),
    nome: z.string(),
    curtidas: z.number(),
    comentarios: z.number(),
    compartilhamento: z.number(),
});

export const ComunidadePostSchema = z.object({
    id: z.number(),
    usuario: z.string(),
    user_id: z.string().optional(),
    foto: z.string(),
    url: z.string(),
    post: z.string(),
    curtidas: z.number(),
    curtido: z.union([z.string(), z.number()]).optional(),
    comentarios: z.number(),
    compartilhamento: z.number()
})

export type Comunidade = z.infer<typeof ComunidadeSchema>;
export type ComunidadePost = z.infer<typeof ComunidadePostSchema>;

export const ListarComunidadesSchema = z.object({
    Status: z.boolean(),
    Comunidade: z.array(ComunidadeSchema),
});

export async function listarComunidades(axios: AxiosInstance) {
    const res = await axios.post('/api/comunidade/listar');
    const data = ListarComunidadesSchema.parse(res.data);
    return data;
}

export const ListarPostsDaComunidadeSchema = z.object({
    Status: z.boolean(),
    Posts: z.array(ComunidadePostSchema),
});

export async function listarPostsDaComunidade(axios: AxiosInstance, comunidadeId: number) {
    const res = await axios.get(`/api/comunidade/allpost/${comunidadeId}`);
    const data = ListarPostsDaComunidadeSchema.parse(res.data);
    return data;
}

export async function buscarPostDaComunidade(
    axios: AxiosInstance, 
    comunidadeId: number, 
    postId: number,
) {
    const res = await axios.get(`/api/comunidade/post/${postId}`);
    const data = ListarPostsDaComunidadeSchema.parse(res.data);
    return data;
}

export const CriarComunidadeSchema = z.object({
    Status: z.boolean(),
    Mensagem: z.string(),
    Comunidade: z.string()
});

export async function criarComunidade(axios: AxiosInstance, comunidadeNome: string) {
    const res = await axios.post(`/api/comunidade/criar/`, {
        nome: comunidadeNome,
    });
    const data = CriarComunidadeSchema.parse(res.data);
    return data;
}

export const PublicarPostSchema = z.object({
    Status: z.boolean(),
    Mensagem: z.string(),
    Post: z.string()
});

export async function publicarPostDaComunidade(
    axios: AxiosInstance,
    comunidadeId: number,
    post: string,
) {
    const res = await axios.post('/api/comunidade/post', {
        comunidade: comunidadeId,
        post,
    });
    const data = PublicarPostSchema.parse(res.data);
    return data;
}

export async function buscarComentariosDoPostComunidade(
    axios: AxiosInstance,
    postId: number,
) {
    const res = await axios.get(`/api/comentario/comunidade/post/${postId}`)
    const data = ListarPostsDaComunidadeSchema.parse(res.data);
    return data;
}

export const ComentarPostComunidadeSchema = z.object({
    Status: z.boolean(),
    Mensagem: z.string(),
    Post: z.string()
});

export async function publicarComentarioDoPostComunidade(
    axios: AxiosInstance, 
    postId: number,
    texto: string,
) {
    const res = await axios.post(`/api/comentario/comunidade/post/${postId}`, {
        post: texto,
    });
    const data = ComentarPostComunidadeSchema.parse(res.data);
    return data;
}

export async function compartilharPostDaComunidade(axios: AxiosInstance, postId: number) {
    const res = await axios.post(`/api/compartilhar/comunidade/post/${postId}`);
    return res.data;
}

export async function curtirPostDaComunidade(axios: AxiosInstance, postId: number) {
    const res = await axios.post(`/api/curtir/comunidade/post/${postId}`);
    return res.data;
}

const ChecarCurtidaPostSchema = z.object({ 
    Status: z.boolean(), 
    Curtiu: z.boolean(), 
});

/** Checa se um post foi curtido pelo usuário logado */
export async function checarCurtidaPostComunidade(axios: AxiosInstance, postId: number) {
    const res = await axios.post(`/api/curtido/comunidade/post/${postId}`);
    const data = ChecarCurtidaPostSchema.parse(res.data);
    return data;
}

export async function excluirPostComunidade(axios: AxiosInstance, id: number) {
    const res = await axios.post(`/api/remover/comunidade/post/${id}`);
    return res.data;
}