/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from './navigationTypes';

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [Linking.createURL('/')],
    config: {
        screens: {
            LoginScreen: 'Login',
            CriarContaScreen: 'CriarConta',
            CriarContaSucessoScreen: 'CriarConta/Sucesso',
            Root: {
                screens: {
                    DrawerStackScreen: {
                        screens: {
                            HomeScreen: 'Home',
                            MyProfileScreen: 'MeuPerfil',
                            ProfileScreen: '/Usuario/:userId',
                            EditarPerfilScreen: 'EditarPerfil',
                            ComunidadesLista: 'Comunidades',
                            ComunidadeScreen: 'Comunidade/:comunidadeId',
                            ComunidadePostScreen: 'Comunidade/:comunidadeId/Post/:postId',
                            CredLoversAvaliar: 'Connections/Avaliar',
                            PostScreen: 'Post/:postId',
                            MessengerListaDeContatos: 'Messenger',
                            MessengerChat: '/Messenger/Chat',
                            QuemAvaliouScreen: '/Usuario/QuemAvaliou/',
                            PesquisarUsuariosScreen: '/PesquisarUsuarios/',
                        },
                    },
                },
            },
            Modal: 'modal',
            NotFound: '*',
        },
    },
};

export default linking;
