import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Searchbar, Text } from "react-native-paper";
import { StyleSheet, TextInput as BaseTextInput, View } from 'react-native';
import { ScreenLayout } from "../../components/Layout";
import { DrawerStackParamList } from "../../navigation/navigationTypes";
import { paperTheme } from "../../lib/theme";
import { useFocusEffect } from "@react-navigation/native";
import { pesquisarUsuario, UserInfos } from "../../lib/services/user";
import { AxiosContext } from "../../stores/AxiosContext";
import { PesquisarUsuariosItem } from "./PesquisarUsuariosItem";

type Props = NativeStackScreenProps<DrawerStackParamList, 'PesquisarUsuariosScreen'>;

export function PesquisarUsuariosScreen({ navigation }: Props) {
    const [searchQuery, setSearchQuery] = useState('');
    const [usuarios, setUsuarios] = useState<UserInfos[]>([]);
    const searchbarRef = useRef<BaseTextInput>(null);
    const [estaCarregando, setEstaCarregando] = useState(false);
    const [teveErro, setTeveErro] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [estaDigitando, setEstaDigitando] = useState(false);
    const [fezPesquisa, setFezPesquisa] = useState(false);
    const { authAxios } = useContext(AxiosContext);

    const isSearchQueryVazio = searchQuery.trim().length === 0;

    const handleUsuarioPress = (usuario: UserInfos) => {
        navigation.navigate('ProfileScreen', {
            userId: usuario.id.toString(),
        });
    };

    const handleSearchQueryChange = (value: string) => {
        setSearchQuery(value)

        if (timer != null) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            setEstaDigitando(false);
        }, 500);

        setTimer(newTimer);
        setEstaDigitando(true);
    }

    useFocusEffect(useCallback(() => {
        setFezPesquisa(false);
        setSearchQuery('');
        searchbarRef.current?.focus();
    }, []));

    useEffect(() => {
        // quando o search query mudar

        setTeveErro(false);
        const query = searchQuery.trim();

        // esperar a pessoa parar de digitar
        if (estaDigitando) return;

        if (query.length > 100) return;

        if (query.length === 0) {
            setUsuarios([]);
            return;
        }

        setEstaCarregando(true);
        setFezPesquisa(true);
        pesquisarUsuario(authAxios, query)
            .then(data => setUsuarios(data))
            .catch(err => {
                console.error(err);

                setTeveErro(true);
            })
            .finally(() => {
                setEstaCarregando(false)
            });
    }, [searchQuery, estaDigitando]);

    return (
        <ScreenLayout>
            <View style={styles.content}>
                <Searchbar
                    placeholder="Buscar usuários"
                    onChangeText={handleSearchQueryChange}
                    value={searchQuery}
                    style={styles.searchbar}
                    ref={searchbarRef}
                />

                {(estaCarregando) ? (
                    <ActivityIndicator 
                        size={60}
                    />
                ) : (
                    <>
                        {usuarios.map((usuario, i) => (
                            <PesquisarUsuariosItem
                                key={i}
                                usuario={usuario}
                                onPress={() => handleUsuarioPress(usuario)}
                            />
                        ))}

                        {(fezPesquisa && usuarios.length === 0 && !isSearchQueryVazio) && (
                            <Text style={styles.textoResultadoVazio}>
                                Nenhum usuário encontrado.
                            </Text>
                        )}
                    </>
                )}

            </View>
        </ScreenLayout>
    );
}

const styles = StyleSheet.create({
    content: {
        alignItems: 'center',
        paddingTop: 30,
    },

    searchbar: {
        backgroundColor: paperTheme.colors.surface,
        width: '90%',
        maxWidth: 400,
        marginBottom: 40,
    },

    textoResultadoVazio: {
        fontSize: 20,
        fontWeight: '600',
    },
});