import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Alert,
    Image,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    TextInput as RNTextInput,
    Platform,
    Linking,
} from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Button, TextInput, ActivityIndicator, IconButton, } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import axios, { AxiosError } from "axios";
import useKeyboard from "../../hooks/useKeyboard";
import logo from '../../assets/images/logotipo.png';
import { paperTheme, themeStyles } from "../../lib/theme";
import { RootStackParamList } from "../../navigation/navigationTypes";
import { LinearGradient } from "expo-linear-gradient";
import { useUserStore } from "../../stores/userStore";
import { requestLogin } from "../../lib/services/user";
import { showAlert } from "../../lib/utils/platform";
import { LoadingScreen } from "../../components/LoadingScreen";

const iconSize = 25;

type Props = NativeStackScreenProps<RootStackParamList, 'LoginScreen'>;

export default function LoginScreen({ navigation }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAppState, setIsLoadingAppState] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const emailTextInput = useRef<RNTextInput | null>(null);
    const passwordTextInput = useRef<RNTextInput | null>(null);

    const userStore = useUserStore();
    const isKeyboardVisible = useKeyboard();

    const handleLogin = async () => {
        const userEmail = email.trim();
        const userPassword = password.trim();
        if (userEmail.length === 0 || userPassword.length === 0) {
            Alert.alert('Erro', 'Por favor, preencha todos os campos');
            return;
        }

        setIsLoading(true);
        try {
            const loginData = await requestLogin(userEmail, userPassword);
            if (loginData.Status !== true) {
                showAlert('Erro:', 'Não foi possível realizar o login, tente novamente.');
                return;
            }

            userStore.setToken(loginData.token);
            navigation.navigate('Root');
        } catch (err) {
            console.error(err);

            if (err instanceof AxiosError) {
                let message = 'Erro ao se conectar com o servidor, tente novamente.';
                if (typeof err.response?.data?.Mensagem === 'string') {
                    message = err.response?.data?.Mensagem;
                }

                showAlert('Erro:', message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleEsqueciSenhaPress = () => {
        Linking.openURL('https://seocredito.norwaybank.com.br/forgot-password');
    };

    useEffect(() => {
        // impedir que o usuário aperte o botão de voltar nessa tela
        navigation.addListener('beforeRemove', (e) => {
            e.preventDefault();
        });
    }, [navigation]);

    useEffect(() => {
        if (userStore.token != null) {
            // usuário está logado, ir para home
            navigation.navigate('Root');
            setIsLoadingAppState(false);
        } else {
            // usuário não está logado
            setIsLoadingAppState(false);
        }
    }, []);

    if (isLoadingAppState) {
        return <LoadingScreen />
    }

    const barraBaixo = (
        <View>
            <Button
                textColor="white"
                mode="outlined"
                style={{ marginBottom: 10 }}
                labelStyle={styles.buttonText}
                onPress={() => navigation.navigate('CriarContaScreen')}
            >
                Criar conta
            </Button>
        </View>
    );

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <View style={[styles.gradient, themeStyles.background]}>
                {(isLoading) ? (
                    <>
                        <View style={[styles.surface, themeStyles.surface]}>
                            <View style={styles.logoWrapper}>
                                <Image
                                    source={logo}
                                    resizeMode="stretch"
                                    resizeMethod="resize"
                                    style={[themeStyles.surfaceImage, styles.logo]}
                                />
                            </View>

                            <LinearGradient
                                colors={['#ff0c5e', '#000000']}
                                locations={[0.0, 1.0]}
                                start={[0, 1]}
                                end={[1, 0]}
                                style={styles.gradientLine}
                            />
                        </View>

                        <View style={[styles.loadingArea, themeStyles.background]}>
                            <View style={styles.activityIndicatorWrapper}>
                                <ActivityIndicator
                                    color={paperTheme.colors.activityIndicator}
                                    size={50}
                                />
                            </View>
                        </View>
                    </>
                ) : (
                    <ScrollView
                        style={{ flex: 1, width: '100%', }}
                        contentContainerStyle={styles.scrollBarContent}
                        keyboardShouldPersistTaps='handled'
                    >
                        <View style={[styles.surface, themeStyles.surface]}>
                            <View style={styles.logoWrapper}>
                                <Image
                                    source={logo}
                                    resizeMode="cover"
                                    style={[themeStyles.surfaceImage, styles.logo]}
                                />
                            </View>

                            <LinearGradient
                                colors={['#ff0c5e', '#000000']}
                                locations={[0.0, 1.0]}
                                start={[0, 1]}
                                end={[1, 0]}
                                style={styles.gradientLine}
                            />
                        </View>

                        <View style={styles.content}>
                            <Text style={[styles.label, styles.title]}>
                                Acesse sua conta
                            </Text>

                            <View style={styles.form}>
                                <Text style={styles.label}>
                                    E-mail:
                                </Text>
                                <TextInput
                                    value={email}
                                    ref={emailTextInput}
                                    onChangeText={(newValue) => setEmail(newValue)}
                                    onSubmitEditing={() => passwordTextInput.current?.focus()}
                                    cursorColor={paperTheme.colors.primary}
                                    activeUnderlineColor={paperTheme.colors.primary}
                                    textColor={paperTheme.colors.inverseOnSurface}
                                    style={[styles.textInput, themeStyles.whiteBackground]}
                                    underlineStyle={{ borderRadius: 8 }}
                                    keyboardType="email-address"
                                    returnKeyType="next"
                                    blurOnSubmit={false}
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    right={
                                        <TextInput.Icon
                                            icon="account"
                                            iconColor={paperTheme.colors.primary}
                                            size={iconSize}
                                            style={{ padding: 0, margin: 0 }}
                                            focusable={false}
                                        />
                                    }
                                />

                                <Text style={styles.label}>
                                    Senha:
                                </Text>
                                <TextInput
                                    secureTextEntry
                                    value={password}
                                    ref={passwordTextInput}
                                    onChangeText={(newValue) => setPassword(newValue)}
                                    onSubmitEditing={handleLogin}
                                    cursorColor={paperTheme.colors.primary}
                                    activeUnderlineColor={paperTheme.colors.primary}
                                    textColor={paperTheme.colors.inverseOnSurface}
                                    style={[styles.textInput, themeStyles.whiteBackground]}
                                    underlineStyle={{ borderRadius: 8 }}
                                    right={
                                        <TextInput.Icon
                                            icon="lock"
                                            iconColor={paperTheme.colors.primary}
                                            size={iconSize}
                                            style={{ padding: 0, margin: 0 }}
                                            focusable={false}
                                        />
                                    }
                                />

                                <Button
                                    textColor="white"
                                    mode="contained"
                                    style={[styles.btnEntrar, themeStyles.primaryColorSurface]}
                                    labelStyle={styles.buttonText}
                                    contentStyle={{ paddingHorizontal: 12, paddingVertical: 2 }}
                                    onPress={handleLogin}
                                >
                                    Entrar
                                </Button>

                                <Button
                                    textColor="white"
                                    mode="text"
                                    style={styles.forgotPasswordButton}
                                    labelStyle={styles.buttonText}
                                    onPress={handleEsqueciSenhaPress}
                                >
                                    Esqueci minha senha
                                </Button>
                            </View>

                            {(isKeyboardVisible) && (
                                <>
                                    {barraBaixo}
                                </>
                            )}
                        </View>
                    </ScrollView>
                )}

                {(!isLoading && !isKeyboardVisible) && (
                    <>
                        {barraBaixo}
                    </>
                )}
            </View>
        </SafeAreaView>
    );
}


const styles = StyleSheet.create({
    loadingArea: {
        flex: 1,
    },

    content: {
        alignItems: 'center',

        width: '100%',
        maxWidth: 400,
    },

    surface: {
        width: '100%',
        marginBottom: 20,
    },

    activityIndicatorWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',

        marginBottom: 70,
    },

    gradient: {
        flex: 1,
        alignItems: 'center',
    },

    gradientLine: {
        height: 5,
        width: '100%',
    },

    title: {
        alignItems: 'center',

        fontSize: 20,
        fontWeight: '700',

        marginBottom: 10,
    },

    icon: {
        margin: 0,
        padding: 0,
    },

    logoWrapper: {
        marginTop: 30,
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },

    logo: {
        resizeMode: 'stretch',
        height: 40,
        width: 310,
    },

    form: {
        width: '100%',
        maxWidth: '90%',
    },

    textInput: {
        //borderRadius: 8,
        marginBottom: 14,
        //maxHeight: 60,
        //color: '#056065',
    },

    label: {
        color: 'white',
        fontSize: 18,
        fontWeight: '500',

        marginLeft: 8,
        marginBottom: 8,
    },

    btnEntrar: {
        borderRadius: 8,
        marginTop: 10,
    },

    forgotPasswordButton: {
        marginTop: 20,
    },

    buttonText: {
        fontSize: 16,
    },

    bottonSectionContainer: {
        //position: 'absolute',
        //bottom: 0,
        marginTop: 'auto',
        //marginBottom: 30,

        height: 100,
        width: '100%',

        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },

    tab: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
    },

    tabSeparator: {
        borderRightColor: 'white',
        borderRightWidth: 1,

        height: 50,
        width: 0,
    },

    tabText: {
        color: 'white',
        fontSize: 17,
        marginTop: 5,
    },

    scrollBarContent: {
        alignItems: 'center',
    },
});