import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Modal, Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Rating } from 'react-native-ratings-movilizame';
import { paperTheme, themeStyles } from '../lib/theme';
import { AvaliacaoUsuario } from '../lib/services/user';
import { Avaliacao } from './Avaliacao';

export type Review = {
    notaConfiavel: number,
    notaPrestativo: number,
    notaAtraente: number,
};

export type Props = {
    visible: boolean,
    avaliacaoInicial: AvaliacaoUsuario | null,
    onDismiss?: () => void,
    onSubmit?: (review: Review) => void,
    onDenunciar?: () => void,
};

export function RatingModal(props: Props) {
    const [notaConfiavel, setNotaConfiavel] = useState(0);
    const [notaPrestativo, setNotaPrestativo] = useState(0);
    const [notaAtraente, setNotaAtraente] = useState(0);

    const handleSubmit = () => {
        if (props.onSubmit == null) return;

        const review = {
            notaConfiavel,
            notaPrestativo,
            notaAtraente,
        };
        props.onSubmit(review);
    };

    useEffect(() => {
        if (props.avaliacaoInicial == null) return;

        const { confiavel, prestativo, atraente } = props.avaliacaoInicial;
        setNotaConfiavel(confiavel);
        setNotaPrestativo(prestativo);
        setNotaAtraente(atraente);
    }, [props.avaliacaoInicial]);

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <View style={styles.linha}>
                <Text style={styles.texto}>
                    Confiável
                </Text>

                {/* <Rating
                    startingValue={notaConfiavel}
                    onFinishRating={setNotaConfiavel}
                    ratingBackgroundColor={paperTheme.colors.background}
                    tintColor={paperTheme.colors.surface}
                    ratingColor={paperTheme.colors.primary}
                    ratingTextColor={paperTheme.colors.primary}
                    style={[{ paddingHorizontal: 10, margin: 0 }]}
                    imageSize={30}
                    type="custom"
                /> */}

                <Avaliacao
                    nota={notaConfiavel}
                    onNotaInicialChange={setNotaConfiavel}
                    corIcone={paperTheme.colors.primary}
                    style={[{ paddingHorizontal: 10, margin: 0, width: '60%' }]}
                    tamanho={30}
                />
            </View>

            <View style={styles.linha}>
                <Text style={styles.texto}>
                    Prestativo
                </Text>

                {/* <Rating
                    startingValue={notaPrestativo}
                    onFinishRating={setNotaPrestativo}
                    ratingBackgroundColor={paperTheme.colors.background}
                    tintColor={paperTheme.colors.surface}
                    ratingColor={paperTheme.colors.primary}
                    ratingTextColor={paperTheme.colors.primary}
                    style={[{ paddingHorizontal: 10, margin: 0 }]}
                    imageSize={30}
                    type="custom"
                /> */}

                <Avaliacao
                    nota={notaPrestativo}
                    onNotaInicialChange={setNotaPrestativo}
                    corIcone={paperTheme.colors.primary}
                    style={[{ paddingHorizontal: 10, margin: 0, width: '60%' }]}
                    tamanho={30}
                />
            </View>

            <View style={styles.linha}>
                <Text style={styles.texto}>
                    Atraente
                </Text>

                {/* <Rating
                    startingValue={notaAtraente}
                    onFinishRating={setNotaAtraente}
                    ratingBackgroundColor={paperTheme.colors.background}
                    tintColor={paperTheme.colors.surface}
                    ratingColor={paperTheme.colors.primary}
                    ratingTextColor={paperTheme.colors.primary}
                    style={[{ paddingHorizontal: 10, margin: 0 }]}
                    imageSize={30}
                    type="custom"
                /> */}

                <Avaliacao
                    nota={notaAtraente}
                    onNotaInicialChange={setNotaAtraente}
                    corIcone={paperTheme.colors.primary}
                    style={[{ paddingHorizontal: 10, margin: 0, width: '60%' }]}
                    tamanho={30}
                />
            </View>

            <View style={{ flexDirection: 'row', marginTop: 20 }}>
                <Button
                    mode="contained"
                    textColor="white"
                    buttonColor={paperTheme.colors.background}
                    onPress={props.onDenunciar}
                >
                    Denunciar
                </Button>

                <Button
                    mode="contained"
                    textColor="white"
                    onPress={handleSubmit}
                    style={{ marginLeft: 10, }}
                >
                    Avaliar
                </Button>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',

        height: 200,
        width: '90%',
        maxWidth: 350,
        borderRadius: 10,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },
});