import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import { View, StyleSheet, TextInput, Alert, Platform, ViewStyle } from "react-native";
import { Button, Text } from 'react-native-paper';
import { paperTheme, themeStyles } from "../lib/theme";
import { useUserStore } from "../stores/userStore";

export type Props = {
    nome: string,
    onSubmit?: (texto: string) => void,
    style?: ViewStyle,
};

export function PostTextArea(props: Props) {
    const [texto, setTexto] = useState('');

    const nome = useUserStore(state => state.infos?.nome);
    const nomeSocial = useUserStore(state => state.infos?.nome_social);
    const nomeExibido =
        (nomeSocial != null && nomeSocial.length > 0)
            ? nomeSocial
            : nome;

    let nomeStyle: ViewStyle = { opacity: 1 };
    if (nomeExibido == null || nomeExibido.trim().length === 0) {
        nomeStyle = { opacity: 0 };
    }

    const handleSubmit = (e?: any) => {
        if (Platform.OS === 'web') {
            e.preventDefault();
        }

        const conteudo = texto.trim();

        if (conteudo.length === 0) {
            const msg =  'O conteúdo do post não pode estar vazio.';
            if (Platform.OS === 'web') {
                window.alert(msg);
            } else {
                Alert.alert('Atenção', msg);
            }
            return;
        }
        
        if (props.onSubmit != null) {
            props.onSubmit(conteudo);
            setTexto('');
        }
    };

    return (
        <View
            //@ts-ignore
            accessibilityRole={(Platform.OS === 'web') ? "form" : undefined} 
            style={[styles.container, props.style]} 
        >
            <View style={styles.nomeWrapper}>
                <Text style={[styles.nome, nomeStyle]}>
                    Olá, {nomeExibido}
                </Text>

                <LinearGradient
                    colors={['#ff0c5e', 'transparent']}
                    locations={[0.0, 1.0]}
                    start={[0, 1]}
                    end={[1, 0]}
                    style={styles.gradientLine}
                />
            </View>
            
            <TextInput 
                style={styles.textInput}
                cursorColor={paperTheme.colors.primary}
                placeholderTextColor={paperTheme.colors.surface}
                placeholder={"Olá CredLover, escreva algo bem legal."}
                value={texto}
                onChangeText={setTexto}
                onSubmitEditing={handleSubmit}
                blurOnSubmit
                multiline
            />

            <View style={{ alignItems: 'flex-end', paddingRight: 10 }}>
                <Button
                    mode="contained"
                    textColor="white"
                    style={styles.publicarBotao}
                    onPress={handleSubmit}
                >
                    PUBLICAR
                </Button>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        width: '90%',
        maxWidth: 400,
        marginHorizontal: 20,
        marginVertical: 30,
        borderRadius: 10,
        borderBottomLeftRadius: 40,
    },

    nomeWrapper: {
        marginTop: 10,
        marginHorizontal: 20,
        // width: '100%',
    },

    nome: {
        color: 'black',
        fontSize: 17,
        fontWeight: '800',
    },

    gradientLine: {
        width: '100%',
        height: 2,
        marginTop: 5,
    },

    textInput: {
        flex: 1,
        minHeight: 80,
        paddingVertical: 20,
        marginHorizontal: 20,
        fontSize: 17,
        outlineWidth: 0,
    },

    publicarBotao: {
        marginVertical: 10,
    },
});