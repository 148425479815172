import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Divider, IconButton, Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FlashList } from '@shopify/flash-list';
import { paperTheme, themeStyles } from '../../lib/theme';
import SetaBaixoIcon from '../../assets/icons/seta-baixo.svg';
import { CONTATOS_MOCKADOS } from './contatosMockados';
import { ConversaCard } from './ConversaCard';
import { DrawerStackParamList } from '../../navigation/navigationTypes';
import { goBack } from '../../lib/utils/platform';
import { isEnvDev } from '../../lib/env';

type Props = NativeStackScreenProps<DrawerStackParamList, 'MessengerListaDeContatos'>;

const contatosIniciais = isEnvDev() ? CONTATOS_MOCKADOS : [];

export function MessengerListaDeContatos({ navigation }: Props) {
    const abrirChat = () => {
        navigation.navigate('MessengerChat', {});
    };

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.navbarWrapper}>
                <View style={styles.navbar}>
                    <IconButton
                        iconColor="white"
                        size={navbarSvgSize}
                        style={{ transform: [{ rotate: '90deg' }] }}
                        onPress={() => goBack(navigation)}
                        icon={() => (
                            <SetaBaixoIcon
                                width={navbarSvgSize}
                                height={navbarSvgSize}
                                fill="white"
                            />
                        )}
                    />

                    <Text style={styles.screenTitle}>
                        Conversas
                    </Text>
                </View>
            </View>

            <View style={themeStyles.contentContainer}>
                <FlashList
                    data={contatosIniciais}
                    keyExtractor={(_item, index) => index.toString()}
                    estimatedItemSize={110}
                    renderItem={({ item }) => (
                        <ConversaCard 
                            {...item}
                            onPress={() => abrirChat()}
                        />
                    )}
                    ItemSeparatorComponent={Divider}
                    ListEmptyComponent={(
                        <View style={{ alignItems: 'center', marginTop: 20 }}>
                            <Text style={{ fontSize: 20 }}>
                                Você ainda não iniciou nenhuma conversa.
                            </Text>
                        </View>
                    )}
                />
            </View>
        </SafeAreaView>
    );
}

const navbarSvgSize = 25;

const styles = StyleSheet.create({
    container: {
        backgroundColor: paperTheme.colors.background,
        flex: 1,
    },

    navbarWrapper: {
        flexDirection: 'column',

        backgroundColor: paperTheme.colors.darkBackground,
        borderBottomColor: '#ff0058',
        borderBottomWidth: 2,

        position: 'absolute',
        top: 0,
        zIndex: 30,
        width: '100%',
    },

    navbar: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',

        height: 60,
    },

    screenTitle: {
        fontSize: 20,

        marginLeft: 10,
    },
});