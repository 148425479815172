import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Alert, Platform } from "react-native";

/** Função de mostrar um alerta que funciona no mobile nativo e no web */
export function showAlert(title: string, message: string) {
    if (Platform.OS === 'web') {
        window.alert(`${title}\n${message}`);
    } else {
        Alert.alert(title, message);
    }
}

/** Função de mostrar um alerta de confirmação que funciona no mobile nativo e no web */
export function showConfirm(title: string, message: string, callback?: () => void) {
    if (Platform.OS === 'web') {
        return new Promise((resolve, reject) => {
            if (window.confirm(title + '\n' + message)) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    } else {
        return new Promise((resolve, reject) => {
            Alert.alert(
                title,
                message,
                [
                    // The "Yes" button
                    {
                        text: "Sim",
                        onPress: () => resolve(true),
                    },
                    // The "No" button
                    // Does nothing but dismiss the dialog when tapped
                    {
                        text: "Não",
                        onPress: () => resolve(false),
                    },
                ],
                {
                    onDismiss: () => resolve(false),
                }
            );
        });
    };
}

export function suportaCompartilhamento(url?: string) {
    // consideramos que android e IOS suportam compartilhamento
    if (Platform.OS !== 'web') return true;

    // essa parte só deve rodar na web

    if (!navigator.canShare) return false;

    // testar se o navegador suporta compartilhar
    return navigator.canShare({
        url,
        title: 'teste',
        text: url,
    });
}

/** 
 * O navigation.goBack funciona de um jeito estranho na web quando o usuário não entra pela home. Essa função serve para tentar corrigir isso
*/
export function goBack<ParamListT extends ParamListBase, T extends NavigationProp<ParamListT>>(navigation: T) {
    if (Platform.OS === 'web') {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            try {
                navigation.navigate('HomeScreen' as any);
            } catch (err) {
                console.warn(err);

                // voltar pelo history mesmo
                history.back();
            }
        }
    } else {
        navigation.goBack();
    }
}