import jwtdecode from 'jwt-decode';
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { Alert } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useUserStore } from "./userStore";

type AxiosContextType = {
    authAxios: AxiosInstance,
};

export const baseURL = `https://comunidade.seocredito.com.br/`;

export const websiteUrl = 'https://seo-credito-social.vercel.app/';

export const AxiosContext = createContext<AxiosContextType>(null!);
const { Provider } = AxiosContext;

export function AxiosProvider(props: PropsWithChildren) {
    const userStore = useUserStore();
    const navigation = useNavigation();

    const authAxios = axios.create({
        baseURL: baseURL,
    });

    const clearSession = () => {
        // auth.clearTokens();
        // userStore.reset();

        //removeLocalStore('tk-user');
        //removeLocalStore('tk-project');

        //@ts-ignore
        navigation.navigate('LoginScreen');
    };

    const handleRequest = (config: AxiosRequestConfig) => {
        if (userStore.token != null && isTokenExpired(userStore.token)) {
            Alert.alert('Sessão expirada', 'Por favor, faça o login novamente.');
            clearSession();
        }

        if (userStore.token != null) {
            if (config.headers != null) {
                config.headers['TOKEN'] = userStore.token;
            }
        }

        return config;
    };

    authAxios.interceptors.request.use(
        handleRequest,
        error => Promise.reject(error),
    );

    return (
        <Provider value={{ authAxios }}>
            {props.children}
        </Provider>
    );
}

/** Retorna true se o token expirou */
export function isTokenExpired(token: string) {
    let decodedToken: any = jwtdecode(token);
    let currentDate = new Date();

    return (Math.floor(currentDate.getTime() / 1000)) >= decodedToken.exp;
}