import React, { useEffect, useState } from "react";
import { StyleSheet } from 'react-native';
import { Button, Modal, TextInput, Text } from "react-native-paper";
import { paperTheme, themeStyles } from "../../lib/theme";

type Props = {
    visible: boolean,
    initialState: { superPoder: string },
    onDismiss?: () => void,
    onSubmit?: (superPoder: string) => void,
};

export function SuperPoderModal(props: Props) {
    const [superPoder, setSuperPoder] = useState('');

    const handleSubmit = () => {
        if (props.onSubmit == null) return;

        props.onSubmit(superPoder);
    };

    useEffect(() => {
        setSuperPoder(props.initialState.superPoder);
    }, [props.initialState]);

    return (
        <Modal
            visible={props.visible}
            onDismiss={props.onDismiss}
            contentContainerStyle={[styles.container, themeStyles.surface]}
            style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <Text style={{ fontSize: 18, textAlign: 'center' }}>
                Comente um super poder que você tem: (ex já vendi 1000 contas em um dia)
            </Text>

            <TextInput 
                label="Qual o seu super poder?"
                value={superPoder}
                onChangeText={setSuperPoder}
                onSubmitEditing={handleSubmit}
                style={styles.textInput}
                multiline
                blurOnSubmit
            />

            <Button
                mode="contained"
                textColor="white"
                onPress={handleSubmit}
            >
                Salvar
            </Button>
        </Modal>
    );
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',

        height: 220,
        width: '95%',
        maxWidth: 400,
        borderRadius: 10,
        paddingHorizontal: 10,
    },

    linha: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
    },

    texto: {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'center',
    },

    textInput: {
        width: '90%',
        height: 80,
        backgroundColor: paperTheme.colors.background,
    },
});